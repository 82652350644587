import { useTranslation } from 'react-i18next';

import { useApplicationNotCompletedModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';

const ApplicationNotCompletedModal = () => {
  const applicationNotCompleted = useApplicationNotCompletedModal();
  const { t } = useTranslation();

  return (
    <Dialog open={applicationNotCompleted.isOpen} onOpenChange={applicationNotCompleted.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(ClientStrings.modals.submit.title)}</DialogTitle>
          <DialogDescription>{t(ClientStrings.modals.submit.note_message)}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            data-testid={`modal-${ClientStrings.modals.submit.continue || 'primary'}`}
            className="w-44 min-w-fit"
            onClick={() => {
              applicationNotCompleted?.approveCallback?.();
              applicationNotCompleted.onClose();
            }}
          >
            {t(ClientStrings.modals.submit.continue)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ApplicationNotCompletedModal;
