import { useTranslation } from 'react-i18next';

import { useSessionExpiredModal, useSessionTimerModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';
import { useTimer } from '../../hooks';

const SessionTimerModal = () => {
  const sessionTimerModal = useSessionTimerModal();
  const sessionExpiredModal = useSessionExpiredModal();
  const { t } = useTranslation();

  const {
    minutes,
    seconds,
    isActive: isTimerActive,
  } = useTimer({
    duration: sessionTimerModal.duration ?? 60,
    startTimer: true,
  });

  return (
    <Dialog
      open={sessionTimerModal.isOpen}
      onOpenChange={() => {
        !isTimerActive && sessionExpiredModal.setPreventShowingAgain(true);
        sessionTimerModal.onClose();
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {isTimerActive
              ? t(ClientStrings.modals.session_soon_to_expire.title)
              : t(ClientStrings.modals.session_expired.title)}
          </DialogTitle>
          <DialogDescription>
            {isTimerActive &&
              t(ClientStrings.modals.session_soon_to_expire.description, {
                time_left: `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`,
              })}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            className="w-44 min-w-fit"
            onClick={() => {
              !isTimerActive && window.location.reload();
              !isTimerActive && sessionExpiredModal.setPreventShowingAgain(true);
              sessionTimerModal.onClose();
            }}
          >
            {isTimerActive
              ? t(ClientStrings.modals.session_soon_to_expire.yes_btn)
              : t(ClientStrings.modals.session_expired.yes_btn)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SessionTimerModal;
