import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface GlobalState {
  isPreventSaving: boolean;
  allowShowingAppAfterSubmitted: boolean;
  isRecaptchVerified: boolean;
}

const initialState: GlobalState = {
  isPreventSaving: false,
  allowShowingAppAfterSubmitted: false,
  isRecaptchVerified: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsPreventSaving: (state, action: PayloadAction<boolean>) => {
      state.isPreventSaving = action.payload;
    },
    setAllowShowingAppAfterSubmitted: (state, action: PayloadAction<boolean>) => {
      state.allowShowingAppAfterSubmitted = action.payload;
    },
    setIsRecaptchVerified: (state, action: PayloadAction<boolean>) => {
      state.isRecaptchVerified = action.payload;
    },
  },
});

export const { setIsPreventSaving, setAllowShowingAppAfterSubmitted, setIsRecaptchVerified } =
  globalSlice.actions;

export default globalSlice.reducer;
