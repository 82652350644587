import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface TriggersState {
  triggers: Trigger[];
}

const initialState: TriggersState = {
  triggers: [],
};

export type Trigger = {
  field_token: string;
  is_shown: boolean;
  is_required: boolean;
  name?: string;
  description?: string;
  list_of_values?: Array<{ id: string | number; value: string }>;
  is_read_only?: boolean;
  value?: any;
};

export const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    upsertTrigger: (state, action: PayloadAction<Trigger>) => {
      const index = state.triggers.findIndex(
        trigger => trigger.field_token === action.payload.field_token,
      );
      if (index !== -1) {
        state.triggers[index] = action.payload;
      } else {
        state.triggers.push(action.payload);
      }
    },
  },
});

export const { upsertTrigger } = triggersSlice.actions;

export default triggersSlice.reducer;
