import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';

import { CreateApplicationSVG } from '../../assets';
import { Button, CustomIcon, Typography } from '../core';
import {
  useCreateApplication,
  useCreateCountryAuthApplication,
  useGetApplicationByContext,
  useGetCountryAuthLink,
  useGetHostedApplicationByContext,
} from '../../services';
import { ClientStrings } from '../../translations/ClientStrings';
import { SingpassLogo } from '../../assets';
import { useImageLoaded, usePathParams } from '../../hooks';
import { returnFirstSectionToken } from '../../utils/helpers';
import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';
import { RoutePaths } from '../../data/enums';

const ProviderPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const [imageLoaded] = useImageLoaded(SingpassLogo);
  const { data: hostedAppData } = useGetHostedApplicationByContext();
  const { data: applicationData } = useGetApplicationByContext();
  const { happToken } = usePathParams();
  const history = useHistory();
  const { data: countryAuthLink } = useGetCountryAuthLink({
    countryCode: hostedAppData?.data?.country!,
    hostedAppToken: happToken!,
  });
  const { mutateAsync: mutateCreateApplication } = useCreateApplication();
  const { mutateAsync: mutateCreateCountryAuthApplication } = useCreateCountryAuthApplication();
  const hasBeenCalledRef = useRef(false);

  useEffect(() => {
    if (applicationData?.data?.token) {
      const firstSectionToken = returnFirstSectionToken(applicationData.data);
      history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
    }
  }, [applicationData, history, happToken]);

  useEffect(() => {
    const channel = new BroadcastChannel('callback-data');
    const handleCountryAuth = async (code: string, countryCode: string) => {
      if (hasBeenCalledRef.current) {
        return;
      }
      hasBeenCalledRef.current = true;
      const application = await mutateCreateCountryAuthApplication({
        code: code,
        happToken: happToken!,
        country: countryCode,
      });
      const firstSectionToken = returnFirstSectionToken(application.data);
      history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
    };

    channel.addEventListener('message', event => {
      if (event.data.token === happToken) {
        handleCountryAuth(event.data.code, event.data.country);
      }
    });

    return () => {
      channel.removeEventListener('message', () => {});
    };
  }, [happToken, hostedAppData?.data?.country, mutateCreateCountryAuthApplication, history]);

  const handleFillManually = async () => {
    if (hostedAppData?.data?.application && applicationData) {
      const firstSectionToken = returnFirstSectionToken(applicationData.data);
      history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
    } else {
      const application_type = hostedAppData?.data?.application_type?.application_type;
      const country = hostedAppData?.data?.country;
      const business_industry = hostedAppData?.data?.business_industry;
      const sell_type = hostedAppData?.data?.sell_type;
      const application = await mutateCreateApplication({
        application_type: application_type!,
        country: country!,
        business_industry: business_industry!,
        sell_type: sell_type!,
        rapyd_entity_token: hostedAppData!.data.rapyd_entity_token,
        authorized_user_email: hostedAppData!.data.authorized_user_email,
      });
      const firstSectionToken = returnFirstSectionToken(application.data);
      history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
    }
  };

  const handleRetrieveWithProvider = () => {
    if (countryAuthLink && countryAuthLink?.data) {
      const newWindow = window.open(countryAuthLink?.data, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };

  return (
    <>
      {!imageLoaded ? null : (
        <div className="mx-auto flex w-[500px] flex-col items-center justify-center gap-y-5 py-20">
          <CustomIcon icon={<CreateApplicationSVG />} />
          <Typography.H4>{t(ClientStrings.create_application.title)}</Typography.H4>
          <Typography.BODY1>{t(ClientStrings.create_application.description)}</Typography.BODY1>
          <button
            data-testid="use_country_auth"
            onClick={handleRetrieveWithProvider}
            className="flex items-center justify-center gap-x-2 rounded-md border-2 px-4 py-2 text-lg font-semibold transition hover:border-gray-400"
          >
            {t(ClientStrings.country_auth.SG)}
            <img src={SingpassLogo} alt="signpass logo" className="h-full w-24 pt-1" />
          </button>
          <Button
            data-testid="fill_manually"
            onClick={handleFillManually}
            variant={ButtonVariants.TEXT}
          >
            {t(ClientStrings.country_auth.fill_manually)}
          </Button>
        </div>
      )}
    </>
  );
};

export default ProviderPage;
