import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dropdown as RapydDropdown } from '@rapyd/react-ui-library';
import { DropdownComponentProps } from '@rapyd/react-ui-library/dist/components/Form/Dropdown';
import { FieldBoxVariant } from '@rapyd/react-ui-library/dist/components/Form/FieldBox';
import { SECTION_IGNORED_ERROR_MESSAGE } from '../../../data/constants';
import { ClientStrings } from '../../../translations/ClientStrings';
import { BaseFieldProps } from '../../../types/field';
import { transformListOfValues } from '../../../utils/helpers';
import { requiredFieldError } from '../../../data/validations';
import { FieldInputTypesEnum } from '../../../data/enums';

interface DropdownFieldProps extends BaseFieldProps, Omit<DropdownComponentProps, 'options'> {
  label?: string;
  shouldMaskCharacters?: boolean;
  isInvalidField?: boolean;
  isDirty?: boolean;
}

const DropdownField: React.FC<DropdownFieldProps> = ({
  fieldData,
  fieldRegisterValue,
  isDisabledByStatus,
  control,
  showCountryFlag,
  isMultiSelect,
  fieldTriggers,
  sectionData,
  fieldErrorMessage,
  isInvalidField,
  isDirty,
}) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();

  const listOfValues = useMemo(() => {
    const lov = transformListOfValues(fieldTriggers?.list_of_values || fieldData.list_of_values);
    return lov.map(item => {
      return {
        ...item,
        label: t(item.label),
      };
    });
  }, [fieldData.list_of_values, fieldTriggers?.list_of_values, t]);

  const handleOnChange = (val: any, fieldOnChange: any) => {
    fieldOnChange(val);
  };

  const showErrorMessage = useCallback(() => {
    if (sectionData?.error_message === SECTION_IGNORED_ERROR_MESSAGE || isDisabledByStatus) {
      return false;
    } else {
      return true;
    }
  }, [sectionData?.error_message, isDisabledByStatus]);
  const shouldShowErrorMessage =
    showErrorMessage() && (isInvalidField || fieldErrorMessage || fieldData?.error_message);
  const errorMessageText =
    fieldData?.field_type === FieldInputTypesEnum.phone_country_code
      ? fieldErrorMessage ||
        (isInvalidField && (fieldData?.error_message || requiredFieldError(fieldData.name)))
      : fieldErrorMessage || (isInvalidField && fieldData?.error_message);
  const getErrorMessage = () =>
    shouldShowErrorMessage
      ? errorMessageText || (!isDirty && fieldData?.error_message ? fieldData?.error_message : '')
      : '';

  useEffect(() => {
    if (!fieldRegisterValue) return;
    const fieldValue = getValues(fieldRegisterValue);
    if (!fieldValue) return;

    const isListOfValuesArray = Array.isArray(listOfValues) && listOfValues.length > 0;
    const isFieldValueArray = Array.isArray(fieldValue) && fieldValue.length > 0;

    if (isFieldValueArray && isListOfValuesArray) {
      const allFieldValuesExistInListOfValues = fieldValue.every(item =>
        listOfValues.some(lovItem => lovItem?.value === item?.value),
      );

      if (!allFieldValuesExistInListOfValues) {
        setValue(fieldRegisterValue!, null);
      }
    } else if (
      !isFieldValueArray &&
      isListOfValuesArray &&
      !listOfValues.some(item => item?.value === fieldValue?.value)
    ) {
      setValue(fieldRegisterValue!, null);
    }
  }, [fieldRegisterValue, getValues, listOfValues, setValue]);

  return (
    <Controller
      control={control}
      defaultValue={fieldData.value}
      name={fieldRegisterValue!}
      render={({ field }) => {
        const originalValue = listOfValues?.find(item => item.value === field?.value?.value);
        const transformedFieldValue =
          field?.value && !Array.isArray(field?.value)
            ? { value: field?.value?.value, label: t(originalValue?.label || '') }
            : field?.value;
        return (
          <div data-testid={fieldData?.token || fieldData?.object_type}>
            <RapydDropdown
              menuPlacement="auto"
              menuShouldScrollIntoView={true}
              isMulti={isMultiSelect || false}
              disabled={
                fieldTriggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus
              }
              options={listOfValues}
              value={transformedFieldValue}
              onChange={(val: any) => {
                if (isMultiSelect && val && fieldData.limit && val.length > fieldData.limit) {
                  return;
                } else {
                  handleOnChange(val, field.onChange);
                }
              }}
              isFlag={showCountryFlag || false}
              name={fieldData?.token || fieldData?.object_type}
              fieldBoxProps={{
                description:
                  isMultiSelect && fieldData?.limit
                    ? `Please select up to ${fieldData.limit} values`
                    : '',
                maxLength: fieldData?.limit,
                required: fieldTriggers?.is_required || fieldData?.is_required,
                label: t(fieldTriggers?.name || fieldData.name),
                variant: FieldBoxVariant.Outer,
                isClearable: !(
                  fieldTriggers?.is_read_only ||
                  fieldData?.is_read_only ||
                  isDisabledByStatus
                ),
                error: getErrorMessage(),
                name: fieldData?.token || fieldData?.name,
              }}
              placeholder={t(ClientStrings.components.dropdown_empty_placeholder)}
            />
          </div>
        );
      }}
    />
  );
};

export default DropdownField;
