import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { usePathParams } from '../../hooks';
import { getHostedApplication } from '../hostedApplication/getHostedApplication';
import { GenericApiResponse } from '../../types/services';

interface SendOTKParams {
  otk_token: string;
}

export interface SendOTKResponse {
  otk_token: string;
  org_id: string;
}

async function sendOTK({ otk_token }: SendOTKParams): Promise<GenericApiResponse<SendOTKResponse>> {
  const response = await api.post('/verify/applications/hosted/useotk', {
    otk_token,
  });
  return response.data;
}

export const useSendOTK = () => {
  const { happToken, otk } = usePathParams();
  const queryClient = useQueryClient();

  return useMutation(() => sendOTK({ otk_token: otk! }), {
    mutationKey: MutationKeysEnum.sendOTK,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.otk, data);
      if (happToken) {
        localStorage.setItem(happToken, data.data.otk_token);
      }

      // Invalidate the hosted application data
      queryClient.invalidateQueries(QueryKeysEnum.hosted);

      // Explicitly fetch the hosted application data if it hasn't been fetched before
      if (!queryClient.getQueryData(QueryKeysEnum.hosted)) {
        queryClient.fetchQuery(QueryKeysEnum.hosted, () => getHostedApplication(happToken!));
      }
    },
  });
};
