import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Badge, CustomLink, Icon } from '../core';
import { RootState } from '../../store/store';
import { isImageURL, unixToDateTimeString } from '../../utils/helpers';
import { useGetHostedApplicationByContext } from '../../services';
import LanguageButton from '../LanguageButton';
import { ClientStrings } from '../../translations/ClientStrings';
import { isInIframe } from '../../lib/iframe';
import { COUNTRY_CODES } from '../../data/constants';
import { IconName } from '@rapyd/react-ui-library/dist/components/Icon';
import { useEffect, useState } from 'react';
import { SessionTimer } from '../';

const Header: React.FC = () => {
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const { t } = useTranslation();
  const { data: hostedApp } = useGetHostedApplicationByContext();
  const inIFrame = isInIframe();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const {
    organization_details: orgDetails,
    application,
    country,
    cancel_url,
  } = hostedApp?.data || {};
  const merchantLogo = orgDetails?.merchant_logo;
  const isMerchantLogoValid = merchantLogo && isImageURL(merchantLogo);
  const lastUpdate = application?.updated_at
    ? unixToDateTimeString(application.updated_at)
    : 'loading...';

  useEffect(() => {
    if (merchantLogo && isImageURL(merchantLogo)) {
      const image = new Image();
      image.onload = () => setIsImageLoaded(true);
      image.onerror = () => setIsImageLoaded(false);
      image.src = merchantLogo;
    }
  }, [merchantLogo]);

  return (
    <header
      data-testid="form_fixed_bar"
      className="fixed top-0 z-[65] flex h-[70px] w-full items-center justify-center border-b border-gray-100 bg-white shadow-[0_35px_30px_rgba(0,0,0,0.03)]"
    >
      <div className="flex w-[80%] items-center justify-between">
        <div className="flex h-full items-center justify-center gap-x-4">
          {isMerchantLogoValid && isImageLoaded && (
            <img data-testid="logo" src={merchantLogo} alt="logo" className="h-8" />
          )}
          {application && (
            <>
              <Badge data-testid="app_status" status={application.status || 'loading...'} />
              <p data-testid="last_update" className="text-xs text-gray-400">
                {t(ClientStrings.menu.last_updated)} {lastUpdate}
              </p>
            </>
          )}
        </div>
        <div className="mx-auto">
          <SessionTimer />
        </div>
        {cancel_url && (
          <CustomLink href={cancel_url} isDisabled={isPreventSaving} variant={'outlined-colored'}>
            <Icon name={IconName.CHEVRON_C_LEFT} className="mr-2 text-lg" />
            {t(ClientStrings.header.back_to_website)}
          </CustomLink>
        )}
      </div>
    </header>
  );
};

export default Header;
