import { useState, useEffect } from 'react';

type UseImageLoadedReturnType = [boolean, () => void];

const useImageLoaded = (src: string): UseImageLoadedReturnType => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.addEventListener('load', handleLoad);
    return () => {
      img.removeEventListener('load', handleLoad);
    };
  }, [src]);

  return [loaded, handleLoad];
};

export default useImageLoaded;
