import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface TrackingState {
  isFullStoryInitialized: boolean;
  trackingSessionId: string;
  userEmail?: string;
}

const initialState: TrackingState = {
  isFullStoryInitialized: false,
  trackingSessionId: '',
  userEmail: '',
};

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setIsFullStoryInitialized: (state, action: PayloadAction<boolean>) => {
      state.isFullStoryInitialized = action.payload;
    },
    setTrackingSessionId: (state, action: PayloadAction<string>) => {
      state.trackingSessionId = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
  },
});

export const { setIsFullStoryInitialized, setTrackingSessionId, setUserEmail } =
  trackingSlice.actions;

export default trackingSlice.reducer;
