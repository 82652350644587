import {
  DROPDOWN_TYPE_CHANGE_FIELDS,
  SECTION_ICON_MAP,
  ERROR_WITH_CHILD_SECTIONS_MESSAGE,
  SECTION_IGNORED_ERROR_MESSAGE,
  MULTISELECT_DROPDOWN_FIELDS,
  EXTRACT_SECTION_TO_ROOT_TYPES,
} from '../data/constants';
import { Trigger } from '../store/slices/triggersSlice';
import { IApplication, IApplicationSection, SectionTypes } from '../types/application';
import { IField, InputTypes } from '../types/field';
import { ISection } from '../types/section';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { SectionFormData } from '../types/form';
import { FieldInputTypesEnum, SectionTypesEnum, ShareHolderExistsEnum } from '../data/enums';
import { ReactNode } from 'react';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { config } from '../lib/config';

export const getPathParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const happToken = searchParams.get('token');
  const sectionToken = searchParams.get('section');
  const otk = searchParams.get('otk');
  return { happToken, sectionToken, otk };
};

export const changeSectionInPath = (sectionToken: string) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('section', sectionToken);
  return currentUrl.toString().slice(window.location.origin.length);
};

/**
 * Transforms the field LOV into a format that the dropdown component can understand - { value: string, label: string }
 * @param listOfValues - the list of values to transform
 * @returns the transformed list of values in the format { value: string, label: string }[]
 */
export const transformListOfValues = (listOfValues: any): { value: string; label: string }[] => {
  let transformedValues: any = [];

  if (Array.isArray(listOfValues)) {
    transformedValues = listOfValues.map(item => ({ value: item.id, label: item.value }));
  } else {
    Object.keys(listOfValues).forEach(key => {
      transformedValues.push(
        ...listOfValues[key].map((item: any) => ({ value: item.id.toString(), label: item.value })),
      );
    });
  }

  return transformedValues;
};

export const orderedObjectEntries = (obj: any) => {
  return (
    Object.entries(obj)
      .sort((a, b) => a[0].localeCompare(b[0]))
      // @ts-ignore
      .map(([key, value]) => value[0])
  );
};

export const turnApplicationSectionsIntoArray = (sections: any) => {
  let sectionsArray: any = [];
  // remove the meta section
  delete sections?.meta;
  Object.keys(sections).forEach(key => {
    sectionsArray.push(...sections[key]);
  });
  return sectionsArray;
};

interface Sortable {
  sort_order?: number;
}

export const sortItemsBySortOrder = <T extends Sortable>(items: T[]): T[] => {
  return items?.sort((a, b) => (a?.sort_order || 0) - (b?.sort_order || 0));
};

interface GetNavigationSectionDataReturn {
  sectionPercentage: number;
  displayName: string;
  navToken: string;
  shareHolderExistField: IField | undefined;
  sectionIcon: ReactNode;
  sectionPercentageText: string;
  isCurrentSection: boolean;
  showChildSections: boolean;
}

/**
 * Return data for the section navigation component
 * @param section - the section to get the data for
 * @param application - the application object
 * @returns the data for the section navigation component
 */
export const getNavigationSectionData = (
  section: ISection,
  application: IApplication,
  sectionTokenFromParams: string,
): GetNavigationSectionDataReturn => {
  let sectionPercentage = 0;
  let displayName = section?.name;
  let navToken = section?.token;
  let shareHolderExistField: IField | undefined = undefined;

  if (isSectionWithChildSections(section)) {
    if (section.section_type === SectionTypesEnum.shareholders_control) {
      const sectionFields = getSectionFieldObjects(section);
      shareHolderExistField = sectionFields?.find(
        (field: IField) => field.field_type === 'shareholder_exists',
      );
      if (shareHolderExistField?.value === ShareHolderExistsEnum.yes) {
        const shareholderSection = returnSectionBySectionType(
          application,
          SectionTypesEnum.shareholder,
        );
        section.navToken = shareholderSection?.token;
        navToken = shareholderSection?.token || section?.token;
        sectionPercentage = calculateSectionPercentage(section);
      } else if (shareHolderExistField?.value === ShareHolderExistsEnum.no) {
        const shareholderControl = returnSectionBySectionType(
          application,
          SectionTypesEnum.shareholders_control,
        );
        section.navToken = shareholderControl?.token;
        navToken = shareholderControl?.token || section?.token;
        displayName = shareholderControl?.name || section?.name;
        sectionPercentage = calculateSectionPercentage(shareholderControl!);
      }
    } else {
      navToken = section?.child_sections?.[0]?.token || section?.token;
      sectionPercentage = calculateSectionPercentage(section);
    }
  } else {
    sectionPercentage = calculateSectionPercentage(section);
  }

  const sectionIcon = getSectionIcon(section.section_type);
  const sectionPercentageText = `${sectionPercentage}%`;

  let isChildSelected = false;
  if (isSectionWithChildSections(section)) {
    isChildSelected = isChildSectionSelected(application, section.token, sectionTokenFromParams!, [
      SectionTypesEnum.officer,
      SectionTypesEnum.ceo,
    ]);
  }

  const isCurrentSection =
    sectionTokenFromParams === section.token ||
    navToken === sectionTokenFromParams ||
    isChildSelected;

  const showChildSections =
    (section.section_type === SectionTypesEnum.shareholders_control &&
      shareHolderExistField?.value === ShareHolderExistsEnum.yes) ||
    (section.section_type !== SectionTypesEnum.shareholders_control &&
      section?.child_sections &&
      section?.child_sections?.length > 0) ||
    false;

  return {
    sectionPercentage,
    displayName,
    navToken,
    shareHolderExistField,
    sectionIcon,
    sectionPercentageText,
    isCurrentSection,
    showChildSections,
  };
};

export const popChildSectionToRoot = (sections: ISection[]) => {
  const sectionsCopy = [...sections];
  const extractedSections: ISection[] = [];

  sectionsCopy.forEach((section, index) => {
    if (section.child_sections) {
      const childSections = [...section.child_sections];
      childSections.forEach((childSection, childIndex) => {
        if (EXTRACT_SECTION_TO_ROOT_TYPES.includes(childSection.section_type)) {
          extractedSections.push(childSection);
        }
      });
      sectionsCopy[index].child_sections = childSections;
    }
  });

  return [...sectionsCopy, ...extractedSections];
};

export const parseNavSections = (
  sections: IApplicationSection,
  applicationType: string,
): ISection[] => {
  const sectionsArray = turnApplicationSectionsIntoArray(sections);
  const extractedSections = popChildSectionToRoot(sectionsArray);
  const sortedSections = sortItemsBySortOrder(extractedSections);

  return sortedSections;
};

export const calculateSectionPercentage = (section: ISection) => {
  let totalFields = 0;
  let filledFields = 0;

  if (isSectionWithChildSections(section)) {
    if (section.section_type === 'shareholders_control') {
      const shareholderExistsField = getSectionFieldsByFieldType(section, ['shareholder_exists']);
      if (shareholderExistsField[0]?.value === ShareHolderExistsEnum.no) return 100;
    }
    const relevantSections = section!.child_sections!.filter(
      (childSection: ISection) =>
        (childSection.section_type === SectionTypesEnum.shareholder ||
          childSection.section_type === SectionTypesEnum.director) &&
        childSection.error_message !== 'Section Ignored',
    );
    relevantSections!.forEach((childSection: ISection) => {
      childSection?.data_elements?.forEach(dataElement => {
        dataElement?.fields?.forEach(field => {
          if (field?.is_required || field?.error_message) {
            totalFields++;
            if (field.value && !field?.error_message) filledFields++;
          }
        });
      });
      if (childSection?.child_sections && childSection?.child_sections.length > 0) {
        childSection.child_sections.forEach((subChildSection: ISection) => {
          subChildSection?.data_elements?.forEach(dataElement => {
            dataElement?.fields?.forEach(field => {
              if (field.is_required || field?.error_message) {
                totalFields++;
                if (field.value && !field?.error_message) filledFields++;
              }
            });
          });
        });
      }
    });
    if (totalFields === 0) return 0;
    return Math.round((filledFields / totalFields) * 100);
  }

  if (section.is_valid) return 100;

  section?.data_elements?.forEach(dataElement => {
    dataElement?.fields?.forEach(field => {
      if (field.is_required || field?.error_message) {
        totalFields++;
        if (field.value && !field?.error_message) filledFields++;
      }
    });
  });

  if (totalFields === 0) return 0;
  return Math.round((filledFields / totalFields) * 100);
};

export const flattenSectionFieldValues = (section: ISection) => {
  let flattenedValues: any = {};

  if (section?.child_sections) {
    section.child_sections.forEach(childSection => {
      flattenedValues = { ...flattenedValues, ...flattenSectionFieldValues(childSection) };
    });
  }

  section?.data_elements.forEach(dataElement => {
    dataElement?.fields.forEach(field => {
      if (
        field.input_type === FieldInputTypesEnum.dropdown &&
        (typeof field.value === 'string' || typeof field.value === 'number')
      ) {
        const fieldLov = transformListOfValues(field.list_of_values);
        const lovObject = fieldLov.find(lov => lov?.value?.toString() === field?.value?.toString());
        const parsedLovObject = lovObject ?? null;
        flattenedValues[`${section.token}.${dataElement.element_type}.${field.field_type}`] =
          parsedLovObject;
      } else if (
        field.input_type === FieldInputTypesEnum.dropdown_check_box ||
        field.input_type === FieldInputTypesEnum.multiselect_dropdown
      ) {
        const fieldLov = transformListOfValues(field.list_of_values);
        const fieldNewValue =
          field?.value &&
          Array.isArray(field?.value) &&
          field.value.map((val: string) => fieldLov.find(lov => lov?.value?.toString() === val));
        flattenedValues[`${section.token}.${dataElement.element_type}.${field.field_type}`] =
          fieldNewValue || field.value;
      } else if (field.input_type === FieldInputTypesEnum.file) {
        flattenedValues[`${section.token}.${dataElement.element_type}.${field.field_type}`] =
          field.files;
      } else {
        flattenedValues[`${section.token}.${dataElement.element_type}.${field.field_type}`] =
          field.value;
      }
    });
  });
  return flattenedValues;
};

export const flattenApplicationFieldValues = (application: IApplication) => {
  let flattenedValues: any = {};
  Object.values(application?.sections)?.forEach(section => {
    flattenedValues = { ...flattenedValues, ...flattenSectionFieldValues(section?.[0]) };
  });

  return flattenedValues;
};

export const getSectionFieldObjects = (section: ISection) => {
  let flattenedValues: IField[] = [];

  section?.data_elements?.forEach(dataElement => {
    dataElement?.fields?.forEach(field => {
      const fieldTriggersValue = `${section.token}.${dataElement.element_type}.${field.field_type}`;
      flattenedValues.push({ ...field, field_triggers_value: fieldTriggersValue });
    });
  });
  return flattenedValues;
};

export const getSectionIcon = (sectionName: SectionTypes) => {
  const icon = SECTION_ICON_MAP[sectionName];
  return icon;
};

export const getSectionFieldsByFieldType = (section: ISection, fieldType: string[]) => {
  let fields: IField[] = [];

  const sectionFieldsObj = getSectionFieldObjects(section);
  Object.values(sectionFieldsObj).forEach(field => {
    if (fieldType.includes(field.field_type)) {
      fields.push(field);
    }
  });

  return fields;
};

export const appendTriggerToField = (field: IField, trigger: Trigger) => {
  const lov = trigger.list_of_values ?? field.list_of_values;
  const isReadOnly = trigger.is_read_only ?? field.is_read_only;
  const description = trigger.description ?? field.description;
  const name = trigger.name ?? field.name;
  const isRequired = trigger.is_required ?? field.is_required;
  const isShown = trigger.is_shown;
  const value = trigger?.value;

  const triggers =
    {
      ...trigger,
      description: description,
      list_of_values: lov,
      is_read_only: isReadOnly,
      name: name,
      is_required: isRequired,
      is_shown: isShown,
      value: value,
    } ?? field.triggers;
  field.triggers = triggers;
  return field;
};

export const appendTriggerToFieldForValidation = (field: IField, trigger: Trigger): IField => {
  const isReadOnly = trigger.is_read_only ?? field.is_read_only;
  const description = trigger.description ?? field.description;
  const isRequired = trigger.is_required ?? field.is_required;
  const isShown = trigger.is_shown;
  const updatedField: IField = {
    ...field,
    is_read_only: isReadOnly,
    description,
    is_required: isRequired,
    is_shown: isShown,
  };
  field.triggers = updatedField;
  return updatedField;
};

export const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key);
  return item;
};

export const getLocaleDeviceTimeZone = () => {
  try {
    const date = new Date();
    return date.toISOString().replace('Z', '');
  } catch (e) {
    return null;
  }
};

export const getDeviceOperatingSystem = () => {
  try {
    let os;
    const clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Chrome OS', r: /CrOS/ },
      { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    clientStrings.forEach(cs => {
      if (cs.r.test(navigator.userAgent)) {
        os = cs.s;
      }
    });

    return os;
  } catch (e) {
    return null;
  }
};

export const getSearchParam = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const getDeviceFingerPrint = async () => {
  try {
    const fp = await FingerprintJS.load();
    if (fp) {
      const { visitorId } = await fp.get();
      return visitorId;
    }
  } catch (e) {
    return null;
  }
};

export const returnFirstSectionToken = (application: IApplication) => {
  const flattenedSections = flattenApplicationSections(application?.sections);
  const nonParentSections =
    flattenedSections?.filter(
      section => section.section_type !== 'shareholder' && section.section_type !== 'director',
    ) ?? [];
  const sections = sortItemsBySortOrder(nonParentSections || flattenedSections || []);
  return sections[0]?.token;
};

export const mergeElementsFromFormWithSectionGeneric = (
  currentSectionObject: ISection | undefined,
  sectionFormData: SectionFormData,
) => {
  const mergedDataElements = currentSectionObject?.data_elements.map(dataElement => {
    const sectionDataElement = sectionFormData[dataElement.element_type];
    if (sectionDataElement) {
      const mergedFields = dataElement.fields.map(field => {
        const sectionField = sectionDataElement[field.field_type];
        if (Object.hasOwn(sectionDataElement, field.field_type)) {
          if (field.input_type === 'dropdown') {
            field.value = sectionField?.value ?? null;
          } else if (MULTISELECT_DROPDOWN_FIELDS.includes(field?.input_type)) {
            const fieldValueWithOnlyValues =
              sectionField &&
              Array.isArray(sectionField) &&
              sectionField?.map((value: any) => value.value);
            field.value = fieldValueWithOnlyValues ?? null;
          } else {
            field.value = sectionField || '';
          }
          return field;
        }
        return field;
      });
      dataElement.fields = mergedFields;
      return dataElement;
    }
    return dataElement;
  });

  return mergedDataElements;
};

/**
 * Merges the section form data with the application object
 * @param application - the application object
 * @param sectionFormData - the section form data from react-hook-form
 * @param sectionToken - the section token
 * @returns the updated application object
 */
export const mergeSectionFormDataWithApplicationGeneric = (
  application: IApplication,
  sectionFormData: SectionFormData,
  sectionToken: string,
) => {
  const updatedApplication = { ...application };
  const { sections } = updatedApplication;

  const currentSectionObject = returnApplicationSectionFromSectionToken(application, sectionToken);

  if (sectionFormData) {
    const mergedDataElements = mergeElementsFromFormWithSectionGeneric(
      currentSectionObject,
      sectionFormData,
    );

    const updatedSection = {
      ...currentSectionObject,
      data_elements: mergedDataElements,
    };

    const updatedSections = Object.entries(sections).map(section => {
      if (section[1][0].token === sectionToken) {
        return [section[0], [updatedSection]];
      } else if (section[1][0].child_sections?.length) {
        const updatedChildSections = section[1][0].child_sections.map(childSection => {
          if (childSection.token === sectionToken) {
            return updatedSection;
          }
          return childSection;
        });
        return [section[0], [{ ...section[1][0], child_sections: updatedChildSections }]];
      }
      return section;
    });
    updatedApplication.sections = Object.fromEntries(updatedSections);
  }

  return updatedApplication;
};

export const shouldHideEmptySection = (section: ISection) => {
  const { data_elements, child_sections } = section;
  if (data_elements?.length === 0 && child_sections?.length === 0) return true;
  return false;
};

export const clearSectionFormData = (
  sectionFormData: SectionFormData,
  setValue: any,
  sectionToken: string,
  sectionData: ISection,
  ignoredFieldInputTypes: InputTypes[],
) => {
  const { data_elements } = sectionData;
  const nonReadOnlyDataElements = data_elements.filter(dataElement =>
    dataElement.fields.some(field => !field.is_read_only),
  );
  const nonReadOnlyFields = nonReadOnlyDataElements
    .map(dataElement =>
      dataElement.fields.filter(
        field =>
          (field?.is_read_only === false || field.is_read_only === undefined) &&
          !ignoredFieldInputTypes.includes(field.input_type),
      ),
    )
    .flat();
  sectionFormData &&
    Object.entries(sectionFormData).forEach(([elementType, fieldType]) => {
      if (
        fieldType?.shareholder_type?.value === 'lv_oty00001' &&
        isSectionWithChildSections(sectionData)
      ) {
        return;
      }
      Object.entries(fieldType).forEach(([fieldType, fieldValue]) => {
        if (nonReadOnlyFields.map(field => field.field_type).includes(fieldType) && fieldValue) {
          setValue(`${sectionToken}.${elementType}.${fieldType}`, null, { shouldDirty: true });
        }
      });
    });
};

export const returnFieldFilesFromSection = (section: ISection, nonEmptyOnly: boolean = false) => {
  const { data_elements } = section;
  const nonReadOnlyDataElements = data_elements.filter(dataElement =>
    dataElement.fields.map(field => field.is_read_only).includes(false),
  );

  // Iterate over each dataElement, and within each, iterate over the fields
  // add field_triggers_value to each field
  const nonReadOnlyFields = nonReadOnlyDataElements
    .map(dataElement => {
      return dataElement.fields.filter(field => {
        if (!field.is_read_only) {
          field.field_triggers_value = `${section.token}.${dataElement.element_type}.${field.field_type}`;
          return true;
        }
        return false;
      });
    })
    .flat();

  let fields = nonReadOnlyFields.filter(
    field => field.input_type === 'file' && field?.files?.filter(file => file.is_deleted === false),
  );

  // If nonEmptyOnly is true, then only return fields that have non-empty files
  if (nonEmptyOnly) {
    fields = fields.filter(field => field.files!.length > 0);
  }

  return fields;
};

export const returnApplicationSectionFromSectionToken = (
  application: IApplication,
  sectionToken: string,
) => {
  const { sections } = application;
  // @ts-ignore
  const section = flattenApplicationSections(sections).find(
    section => section.token === sectionToken,
  );
  return section;
};

/**
 * Flatten the application sections object into an array of sections, including child sections
 * @param sections
 * @returns
 */
export const flattenApplicationSections = (sections: IApplicationSection) => {
  const flattenedSections: ISection[] = [];
  Object.entries(sections).forEach(([sectionType, sectionData]) => {
    sectionData.forEach(section => {
      flattenedSections.push(section);
      if (section.child_sections?.length) {
        section.child_sections.forEach(childSection => {
          flattenedSections.push(childSection);
          if (childSection.child_sections?.length) {
            childSection.child_sections.forEach((grandChildSection: ISection) => {
              flattenedSections.push(grandChildSection);
            });
          }
        });
      }
    });
  });
  return flattenedSections;
};

/**
 * Return section by section_type
 */
export const returnSectionBySectionType = (
  application: IApplication,
  sectionType: SectionTypes,
) => {
  const { sections } = application;
  const flattenedSections = flattenApplicationSections(sections);
  const section = flattenedSections.find(section => section.section_type === sectionType);
  return section;
};

/**
 * Format date to US format with trailing zeros for month and day
 * @param date
 * @returns
 */
export const formatDateToUS = (date: any | undefined) => {
  if (!date) return '';
  const dateObject = new Date(date);
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();
  return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
};

export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result?.toString();
      if (base64data) {
        resolve(base64data);
      } else {
        reject(new Error('Failed to convert Blob to base64'));
      }
    };
  });
};

/**
 * Converts a unix timestamp to a date time string
 * @param unix
 * @returns
 */
export const unixToDateTimeString = (unix: number) => {
  const date = new Date(unix);
  return date.toLocaleString();
};

/**
 * Converts string to title case
 */
export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * Add meta object to application sections
 */
export const addMetaToApplicationSections = (application: IApplication, formAction: string) => {
  const applicationCopy = { ...application };
  const sections = applicationCopy.sections;
  // @ts-ignore
  sections['meta'] = { formAction: formAction };
  applicationCopy.sections = sections;
  return applicationCopy;
};

export const isFieldWithChangeAppTypeButton = (field: IField) => {
  return DROPDOWN_TYPE_CHANGE_FIELDS.includes(field.object_type);
};

export const isChildSectionSelected = (
  applicationData: IApplication,
  parentSectionToken: string,
  childSectionToken: string,
  excludeSectionTypes?: SectionTypes[],
): boolean => {
  const parentSection = returnApplicationSectionFromSectionToken(
    applicationData,
    parentSectionToken,
  );
  const isChildSectionInPath = parentSection?.child_sections?.find(
    (childSection: ISection) =>
      childSection.token === childSectionToken &&
      !excludeSectionTypes?.includes(childSection.section_type),
  );

  if (isChildSectionInPath) {
    return true;
  }

  const childSectionsWithChildren = parentSection?.child_sections?.filter(
    (childSection: ISection) => childSection.child_sections?.length,
  );

  if (childSectionsWithChildren?.length) {
    const isGrandChildSectionInPath = childSectionsWithChildren.some(
      (grandChildSection: ISection) => {
        return grandChildSection?.child_sections?.some((grandChildSection: ISection) => {
          return (
            grandChildSection.token === childSectionToken &&
            !excludeSectionTypes?.includes(grandChildSection.section_type)
          );
        });
      },
    );

    if (isGrandChildSectionInPath) {
      return true;
    }
  }
  return false;
};

export const findInvalidSectionInApp = (applicationData: IApplication): string => {
  const allSections = flattenApplicationSections(applicationData.sections);
  const topLevelSections = allSections.filter(
    section => section.section_type !== 'shareholder' && section.section_type !== 'director',
  );
  let sortedSections = sortItemsBySortOrder(topLevelSections);
  sortedSections.forEach(section => {
    if (section.section_type === SectionTypesEnum.shareholders_control) {
      const shareholderExistsField = getSectionFieldsByFieldType(section, [
        'shareholder_exists',
      ])?.[0]?.value;
      if (shareholderExistsField === ShareHolderExistsEnum.no) {
        sortedSections = sortedSections.filter(
          section =>
            section.section_type !== SectionTypesEnum.ceo &&
            section.section_type !== SectionTypesEnum.shareholder,
        );
      }
    }
  });
  const res = findInvalidSectionInList(sortedSections);
  return res;
};

const findInvalidSectionInList = (sectionsList: ISection[]): string => {
  let res = '';
  for (let sec of sectionsList) {
    if (sec.error_message) {
      const shouldIterateChildSections =
        sec.error_message === ERROR_WITH_CHILD_SECTIONS_MESSAGE &&
        sec.child_sections &&
        sec.child_sections?.length > 0;
      if (shouldIterateChildSections) {
        let childSections = sec.child_sections || [];
        if (sec.section_type === SectionTypesEnum.shareholders_control) {
          const shareholderExistsField = getSectionFieldsByFieldType(sec, [
            'shareholder_exists',
          ])?.[0]?.value;
          childSections =
            shareholderExistsField === ShareHolderExistsEnum.no
              ? childSections.filter(
                  childSection =>
                    childSection.section_type !== SectionTypesEnum.shareholder &&
                    childSection.section_type !== SectionTypesEnum.ceo,
                )
              : childSections;
        }
        res = findInvalidSectionInList(childSections);
        break;
      } else if (sec.error_message !== SECTION_IGNORED_ERROR_MESSAGE) {
        res = sec.token;
        break;
      }
    }
  }
  return res;
};

export const isSectionWithChildSections = (section: ISection) => {
  return Array.isArray(section?.child_sections) && section?.child_sections?.length > 0;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isImageURL = (str: string) => {
  if (!isURL(str)) return false;

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const url = new URL(str);
  const extension = url.pathname.split('.').pop()?.toLowerCase();

  return extension ? imageExtensions.includes(extension) : false;
};

export const devConsoleLog = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }
};

export const isValidUUID = (uuid: string) => {
  const regex = new RegExp(
    '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}',
  );
  return regex.test(uuid);
};

/**
 * Returns the total size of the files that can be uploaded based on the field configuration in bytes
 * @param field
 * @param maxSizePerFileInMB
 * @param multiplier 1000 or 1024
 * @returns the total size in bytes
 */
export const getFieldTotalFilesSize = (
  field: IField,
  maxSizePerFileInMB: number = 10,
  multiplier: 1000 | 1024 = 1000,
): number => {
  const fieldMaxFiles = field?.max_files || 1;
  const sizeInMB = fieldMaxFiles * maxSizePerFileInMB;
  return calculateSizeInBytes(sizeInMB, multiplier);
};

/**
 * Returns the total size calculated based on the size in MB and the multiplier
 * @param sizeInMB the size in MB
 * @param multiplier the multiplier to use (1000 or 1024)
 * @returns the total size in Bytes
 */
export const calculateSizeInBytes = (sizeInMB: number, multiplier: 1000 | 1024 = 1000): number => {
  return sizeInMB * multiplier * multiplier;
};

/**
 * Formats bytes into a human readable format
 * @param bytes the bytes to format
 * @param decimals the number of decimals to show
 * @param multiplier the multiplier to use (1000 or 1024)
 * @returns the formatted bytes string
 */
export const formatBytes = (
  bytes: number,
  decimals = 2,
  multiplier: 1000 | 1024 = 1000,
): string => {
  if (!+bytes) return '0 Bytes';

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(multiplier));

  return `${parseFloat((bytes / Math.pow(multiplier, i)).toFixed(dm))}${sizes[i]}`;
};

export const getBackendBaseURL = () => {
  const isPassiveLine = window.location.port === '9443';
  const isPreview = window.location.hostname.includes('preview');
  const apiVersion = 'v1';

  if (isPreview || isPassiveLine) {
    return `${config?.backend_base_url_passive_line}/${apiVersion}`;
  }
  return `${config?.backend_base_url_active_line}/${apiVersion}`;
};
