import { Typography as RapydTypography } from '@rapyd/react-ui-library';
import { TypographyProps } from '@rapyd/react-ui-library/dist/components/Typography';
import { Typographies } from '@rapyd/react-ui-library/dist/utils/getThemeTypography';

export default function Typography(props: TypographyProps) {
  return <RapydTypography {...props} />;
}

type TypographyCompoundProps = Omit<TypographyProps, 'type'>;

Typography.H1 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.H1_HEADING} />
);
Typography.H2 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.H2_HEADING} />
);
Typography.H3 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.H3_HEADING} />
);
Typography.H4 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.H4_HEADING} />
);
Typography.H5 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.H5_HEADING} />
);
Typography.H6 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.H6_HEADING} />
);
Typography.BODY1 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.BODY1} />
);
Typography.BODY2 = (props: TypographyCompoundProps) => (
  <RapydTypography {...props} type={Typographies.BODY2} />
);
