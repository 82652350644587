import { useTranslation } from 'react-i18next';

import { useCloseWindowModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';

const CloseWindowModal = () => {
  const closeWindow = useCloseWindowModal();
  const { t } = useTranslation();

  return (
    <Dialog open={closeWindow.isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(ClientStrings.modals.callback_page.title)}</DialogTitle>
          <DialogDescription>{t(ClientStrings.modals.callback_page.description)}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            data-testid={`modal-${ClientStrings.modals.callback_page.yes_btn || 'primary'}`}
            className="w-44 min-w-fit"
            onClick={() => {
              closeWindow?.approveCallback?.();
            }}
          >
            {t(ClientStrings.modals.callback_page.yes_btn)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CloseWindowModal;
