import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import WizardForm from '../wizard/WizardForm';
import { CreateApplicationSVG } from '../../assets';
import { ClientStrings } from '../../translations/ClientStrings';
import { CustomIcon, Typography } from '../core';

interface WizardPageProps extends RouteComponentProps {}

const WizardPage: React.FC<WizardPageProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex max-w-lg flex-col items-center justify-center gap-y-5 py-4 2xl:py-20">
      <CustomIcon icon={<CreateApplicationSVG />} />
      <Typography.H4 className="text-center">
        {t(ClientStrings.create_application.title)}
      </Typography.H4>
      <Typography.BODY1 className="pb-6 text-center">
        {t(ClientStrings.create_application.description)}
      </Typography.BODY1>
      <WizardForm />
    </div>
  );
};

export default WizardPage;
