import { RouteComponentProps, useHistory } from 'react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useGetApplicationByContext, useGetHostedApplicationByContext } from '../../services';
import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';
import { returnFirstSectionToken } from '../../utils/helpers';
import { Button, Badge, Typography } from '../core';
import { ClientStrings } from '../../translations/ClientStrings';
import { usePathParams } from '../../hooks';
import { SubmitApplication } from '../../assets';
import { isInIframe } from '../../lib/iframe';
import { RootState } from '../../store/store';
import { setAllowShowingAppAfterSubmitted } from '../../store/slices/globalSlice';
import { RoutePaths } from '../../data/enums';

type AppStatus = 'submitted' | 'approved' | 'not_approved' | 'pending_information';

const ThankYouPage: React.FC<RouteComponentProps> = () => {
  const { data: hostedApp } = useGetHostedApplicationByContext();
  const { data: applicationData, isLoading: isGetApplicationLoading } =
    useGetApplicationByContext();
  const { happToken } = usePathParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inIFrame = isInIframe();
  const allowShowAppAfterSubmitted = useSelector(
    (state: RootState) => state.global.allowShowingAppAfterSubmitted,
  );

  const { complete_url } = hostedApp?.data || {};
  const { status: AppStatus } = applicationData?.data || {};

  const handleViewApplication = () => {
    if (!allowShowAppAfterSubmitted) {
      dispatch(setAllowShowingAppAfterSubmitted(true));
    }
    const firstSectionToken = returnFirstSectionToken(applicationData!.data);
    history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
  };

  useEffect(() => {
    if (applicationData && AppStatus === 'new') {
      const firstSectionToken = returnFirstSectionToken(applicationData.data);
      history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
    } else if (hostedApp && !hostedApp?.data?.application_token) {
      history.push(`${RoutePaths.VERIFY}?token=${happToken}`);
    }
  }, [applicationData, happToken, history, hostedApp, AppStatus]);

  return (
    <div
      data-testid="thank_you_page"
      className="flex flex-col items-center justify-center gap-y-10 pt-20"
    >
      <SubmitApplication />
      {hostedApp?.data?.application && (
        <>
          <Typography.H4 data-testid="thank_you_title">
            {t(ClientStrings.thankyou.title[hostedApp.data.application.status as AppStatus])}
          </Typography.H4>
          <Typography.BODY1>
            {t(ClientStrings.thankyou.description[hostedApp.data.application.status as AppStatus])}
          </Typography.BODY1>
          {hostedApp.data.application.status === 'new' && (
            <p>{t(ClientStrings.modals.submit.title)}</p>
          )}
          <div className="flex items-center gap-x-2">
            <Typography.BODY1>{t(ClientStrings.status)} </Typography.BODY1>
            <Badge status={hostedApp.data.application.status} />
          </div>
        </>
      )}
      <div className="flex gap-x-2">
        {!inIFrame && (
          <a
            data-testid="continue"
            className="primary-button w-44"
            href={complete_url || 'https://www.rapyd.net'}
          >
            {t(ClientStrings.thankyou.continue)}
          </a>
        )}
        <Button
          disabled={isGetApplicationLoading || !applicationData?.data}
          data-testid="view_form"
          className="w-44"
          variant={ButtonVariants.OUTLINED}
          onClick={handleViewApplication}
        >
          {t(ClientStrings.thankyou.view_form)}
        </Button>
      </div>
    </div>
  );
};

export default ThankYouPage;
