import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { ClientStrings } from '../../translations/ClientStrings';
import { CustomIcon } from '../core';
import { ErrorExclamationMarkIcon } from '../../assets';
import { useGetHostedApplicationByContext } from '../../services';

interface SessionErrorPageProps extends RouteComponentProps, React.HTMLAttributes<HTMLDivElement> {}

const SessionErrorPage: React.FC<SessionErrorPageProps> = () => {
  const { data: hostedAppData } = useGetHostedApplicationByContext();
  const { t } = useTranslation();

  return (
    <div className="flex items-start justify-center gap-x-10 pt-20">
      <div className="flex w-full flex-col items-center justify-center gap-y-10">
        <CustomIcon icon={<ErrorExclamationMarkIcon />} className="h-20 w-20 fill-primary-base" />
        <div className="space-y-2 text-center">
          <p>{t(ClientStrings.errorPage.ErrorSession.error_msg_line1)}</p>
          <p>{t(ClientStrings.errorPage.ErrorSession.error_msg_line2)}</p>
        </div>
        <a
          className="primary-button button-base rounded-md px-10 py-3"
          href={hostedAppData?.data?.cancel_url || 'https://www.rapyd.net'}
        >
          {t(ClientStrings.errorPage.ErrorSession.close_btn)}
        </a>
      </div>
    </div>
  );
};

export default SessionErrorPage;
