import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import { Icon } from './core';
import { IconName } from '@rapyd/react-ui-library/dist/components/Icon';
import { cn } from '../utils/helpers';
import axios from 'axios';
import { FQ_QUESTIONNAIRE_URL_PREFIX } from '../data/constants';
import { useGetConfig } from '../services';

interface UploadedFileProps extends React.HTMLAttributes<HTMLDivElement> {
  isDisabled?: boolean;
  index: number;
  file: any;
  isLoading?: boolean;
  handleDeleteFile?: (index: number) => void;
  isRemovableFile?: boolean;
}

const UploadedFile: React.FC<UploadedFileProps> = ({
  isDisabled,
  file,
  isLoading,
  handleDeleteFile,
  isRemovableFile = true,
}) => {
  const { data } = useGetConfig();
  const getPdfFile = async () => {
    try {
      const fqQuestionnaireURLPrefix =
        data?.fq_questionnaire_url_prefix || FQ_QUESTIONNAIRE_URL_PREFIX;
      const urlFileName = file.url;
      await axios
        .get(`${fqQuestionnaireURLPrefix}/${urlFileName}`, {
          responseType: 'blob',
        })
        .then(({ data }) => handleDatafile(data, urlFileName));
    } catch (error) {
      return false;
    }
  };
  const handleDatafile = (data: any, urlFileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${urlFileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <BorderedDiv
      data-testid="uploaded_file"
      className={cn(
        'relative mt-2 flex h-[3.2rem] w-full items-center justify-center rounded-lg border-4 border-gray-300 transition-colors duration-300 focus:border-blue-500 focus:outline-none disabled:bg-gray-400',
        {
          'pointer-events-none bg-[#d7d8db]/50 text-gray-600': isDisabled,
          'cursor-pointer': !isRemovableFile,
        },
      )}
      onClick={() => !isRemovableFile && getPdfFile()}
    >
      <Icon
        name={isRemovableFile ? IconName.IMAGE : IconName.DOC}
        className={`pl-2 pr-1 text-2xl text-gray-600 transition-opacity duration-300 ${isDisabled ? 'opacity-50' : ''}`}
      />
      <p
        className={`mr-11 flex h-full flex-grow items-center overflow-hidden truncate text-ellipsis ${isDisabled ? 'opacity-70' : ''}`}
      >
        {file.origin_name}
      </p>
      {isLoading ? (
        <div className="absolute bottom-0 right-3 top-0 flex w-6 items-center justify-center">
          <Oval
            height={20}
            width={20}
            color="#4400ff"
            wrapperStyle={{}}
            wrapperClass=""
            ariaLabel="oval-loading"
            secondaryColor="#724da9"
            strokeWidth={3}
            strokeWidthSecondary={3}
            visible={true}
          />
        </div>
      ) : isRemovableFile ? (
        <button
          disabled={isDisabled}
          type="button"
          className={`absolute bottom-0 right-3 top-0 flex h-full w-6 cursor-pointer items-center justify-center border-none bg-transparent ${isDisabled ? 'pointer-events-none opacity-60' : ''}`}
          onClick={() => handleDeleteFile?.(file)}
        >
          <Icon
            name={IconName.DELETE}
            className={`text-2xl hover:bg-gray-300 ${isDisabled ? 'opacity-80' : ''}`}
          />
        </button>
      ) : (
        <Icon
          name={IconName.DOWNLOAD}
          className={`absolute bottom-2 right-3 text-3xl hover:bg-gray-300 `}
        />
      )}
    </BorderedDiv>
  );
};

const BorderedDiv = styled.div`
  border: 1px solid #dedede;
`;

export default UploadedFile;
