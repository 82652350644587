import { isInIframe } from './iframe';

declare global {
  interface Window {
    FS: any;
  }
}

interface UserDataType {
  email?: string;
  trackingSessionId?: string;
}

interface FullStoryUserDetailsType {
  isEmbedded: boolean;
  email: string;
  trackingSessionId?: string;
}

export const initFullstory = (userData: UserDataType): void => {
  if (userData.email) {
    const fullStoryUserDetails: FullStoryUserDetailsType = {
      isEmbedded: isInIframe(),
      email: userData.email,
    };
    if (userData.trackingSessionId) {
      fullStoryUserDetails.trackingSessionId = userData.trackingSessionId;
    }

    window.FS.identify(userData.email, fullStoryUserDetails);
  }
};

export const createUserVarFullStory = (userVars: object): void => {
  if (userVars) {
    window.FS.setUserVars(userVars);
  }
};
