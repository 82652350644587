import React from 'react';
import { Oval } from 'react-loader-spinner';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../utils/helpers';

const buttonVariants = cva('', {
  variants: {
    variant: {
      primary: 'primary-button',
      outlined: 'outlined-button',
      link: 'link-button',
      'outlined-colored': 'outlined-colored-button',
      'no-style': '',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  className?: string;
  isLoading?: boolean;
}

const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ children, className, isLoading, variant = 'primary', ...props }, ref) => {
    return (
      <button ref={ref} {...props} className={cn(buttonVariants({ variant, className }))}>
        {isLoading ? (
          <div className="flex h-5 items-center justify-center">
            <Oval
              height={20}
              width={20}
              color="#e2d7ff"
              wrapperStyle={{}}
              wrapperClass=""
              ariaLabel="oval-loading"
              secondaryColor="#362f3f"
              strokeWidth={3}
              strokeWidthSecondary={3}
              visible={isLoading}
            />
          </div>
        ) : (
          children
        )}
      </button>
    );
  },
);

CustomButton.displayName = 'CustomButton';

export default CustomButton;
