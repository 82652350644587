export enum RoutePaths {
  ROOT = '/',
  VERIFY = '/verify',
  FORM = '/verify/form',
  CREATE_OTP = '/verify/create-otp',
  OTP = '/verify/otp',
  THANK_YOU = '/verify/thank-you',
  WIZARD = '/verify/wizard',
  PROVIDER = '/verify/provider',
  SESSION_ERROR = '/verify/session-error',
  MYINFO_CALLBACK = '/verify/verificationSGcallback',
  FALLBACK = '*',
}

export enum SectionTypesEnum {
  company = 'company',
  business = 'business',
  AU = 'AU',
  shareholders_control = 'shareholders_control',
  FQ = 'FQ',
  owner = 'owner',
  officer = 'officer',
  shareholder = 'shareholder',
  ceo = 'ceo',
  your_business = 'your_business',
  company_info = 'company_info',
  directors_control = 'directors_control',
  director = 'director',
}

export enum FormActionsEnum {
  SAVE_and_SWITCH = 'save_and_switch',
  CHANGE_OWNER = 'change_owner',
  REMOVE_SECTION = 'remove_section',
  SUBMIT = 'submit',
}

export enum SessionStatusEnum {
  APPROVE = 'approve',
  PENDING = 'pending',
  REJECT = 'reject',
}

export enum OTPErrorMessageEnum {
  ERROR_SMS_CODE_NOT_MATCH = 'ERROR_SMS_CODE_NOT_MATCH',
  ERROR_MAX_ATTEMPT_NUMBER = 'ERROR_MAX_ATTEMPT_NUMBER',
}

export enum ErrorMessagesEnum {
  ERROR_OTP_SESSION_NOT_FOUND = 'ERROR_OTP_SESSION_NOT_FOUND',
  UNAUTHORIZED_API_CALL = 'UNAUTHORIZED_API_CALL',
}

export enum FieldInputTypesEnum {
  text = 'text',
  number = 'number',
  select = 'select',
  dropdown = 'dropdown',
  file = 'file',
  input = 'input',
  textarea = 'textarea',
  checkbox = 'checkbox',
  radio_button = 'radio_button',
  date = 'date',
  phone_number = 'phone_number',
  check_box = 'check_box',
  email = 'email',
  multiselect_dropdown = 'multiselect_dropdown',
  dropdown_check_box = 'dropdown_check_box',
  phone_country_code = 'phone_country_code',
}

export enum ShareHolderExistsEnum {
  yes = 'lv_loa00001',
  no = 'lv_loa00002',
  undefined = 'lv_loa00003',
}

export type ShareHolderExistsType =
  | ShareHolderExistsEnum.yes
  | ShareHolderExistsEnum.no
  | ShareHolderExistsEnum.undefined;

export enum ApplicationStatusEnum {
  new = 'new',
  submitted = 'submitted',
  pending_information = 'pending information',
  not_approved = 'not approved',
  approved = 'approved',
  submitted_to_3rd_party = 'submitted to 3rd party',
}

export enum QueryKeysEnum {
  config = 'config',
  application = 'application',
  hosted = 'hosted',
  otp = 'otp',
  otk = 'otk',
  appTypes = 'appTypes',
  countryList = 'countryList',
  sellTypeMCC = 'sellTypeMCC',
  countryAuth = 'countryAuth',
}

export enum MutationKeysEnum {
  saveApplication = 'saveApplication',
  submitApplication = 'submitApplication',
  sendOTP = 'sendOTP',
  resendOTP = 'resendOTP',
  saveApplicationSection = 'saveApplicationSection',
  saveAndDeleteSection = 'saveAndDeleteSection',
  saveSection = 'saveSection',
  saveAndAddSection = 'saveAndAddSection',
  copySection = 'copySection',
  clearSection = 'clearSection',
  uploadDocuments = 'uploadDocuments',
  uploadDocumentInParallel = 'uploadDocumentInParallel',
  saveApplicationWithParallelFileUploads = 'saveApplicationWithParallelFileUploads',
  deleteDocuments = 'deleteDocuments',
  switchApplicationType = 'switchApplicationType',
  changeApplicationType = 'changeApplicationType',
  createApplication = 'createApplication',
  updateHostedApplication = 'updateHostedApplication',
  createCountryAuthApplication = 'createCountryAuthApplication',
  validateRecaptcha = 'validateRecaptcha',
  sendOTK = 'sendOTK',
}
