import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { IApplication } from '../types/application';
import { appendTriggerToFieldForValidation } from '../utils/helpers';
import { buildZodSchema, compareTriggerValues } from '../utils/validations';
import { IField } from '../types/field';
import { Trigger } from '../store/slices/triggersSlice';

const useSchemaValidation = (
  application: IApplication,
  enabled_frontend_validation: boolean = false,
) => {
  const triggersState = useSelector((state: RootState) => state.triggers.triggers);
  const [updatedApplication, setUpdatedApplication] = useState(application);
  useEffect(() => {
    let newApp = { ...updatedApplication };
    triggersState.forEach((trigger: Trigger) => {
      const [sectionToken, elementType, fieldType] = trigger.field_token.split('.');
      const foundSection = Object.values(newApp.sections)?.find(
        section => section[0].token === sectionToken,
      );
      if (!!foundSection) {
        const foundElement = foundSection?.[0]?.data_elements?.find(
          element => element.element_type === elementType,
        );
        if (!!foundElement) {
          let foundField = foundElement?.fields?.find(
            (field: IField) => field.field_type === fieldType,
          );

          if (foundField && compareTriggerValues(trigger, foundField)) {
            foundField = { ...appendTriggerToFieldForValidation(foundField, trigger) };

            newApp = {
              ...newApp,
              sections: {
                ...newApp.sections,
                [foundSection[0].section_type]: [
                  {
                    ...foundSection[0],
                    data_elements: foundSection[0].data_elements.map(element => {
                      if (element.element_type === elementType) {
                        return {
                          ...element,
                          fields: element.fields.map(field => {
                            if (field.field_type === fieldType) {
                              return foundField;
                            }
                            return field;
                          }),
                        };
                      }
                      return element;
                    }),
                  },
                ],
              },
            };
          }
        }
      }
    });
    setUpdatedApplication(newApp);
  }, [triggersState]);

  return {
    schema: buildZodSchema(updatedApplication, enabled_frontend_validation),
  };
};

export default useSchemaValidation;
