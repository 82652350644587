import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { ClientStrings } from '../../translations/ClientStrings';
import { Error500 } from '../../assets';

type ErrorCode = 404 | 500 | 'otk';
const allowedErrorCodes: (ErrorCode | string)[] = [404, 500, 'otk'];

function isNumericErrorCode(code: ErrorCode): code is 404 | 500 {
  return typeof code === 'number';
}

const ErrorPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let currentErrorCode: ErrorCode = 404;

  const pathName = history.location.pathname.split('/')[1];
  const numberPathName = Number(pathName);

  if (!isNaN(numberPathName) && allowedErrorCodes.includes(numberPathName as ErrorCode)) {
    currentErrorCode = numberPathName as ErrorCode;
  } else if (pathName === 'otk-error') {
    currentErrorCode = 'otk';
  }

  return (
    <div className="flex items-start justify-center gap-x-10 pt-20">
      <Error500 />
      <div className="flex w-[512px] flex-col">
        {isNumericErrorCode(currentErrorCode) && <p>{t(ClientStrings.errorPage.error_title)}</p>}
        {isNumericErrorCode(currentErrorCode) &&
          ClientStrings.errorPage[currentErrorCode]?.error_code && (
            <p className="text-5xl font-semibold text-primary-base">
              {t(ClientStrings.errorPage[currentErrorCode]?.error_code)}
            </p>
          )}
        {isNumericErrorCode(currentErrorCode) && (
          <h4>{t(ClientStrings.errorPage.error_msg_title)}</h4>
        )}
        <p className={`py-4 text-lg ${currentErrorCode === 'otk' ? 'text-xl font-semibold' : ''}`}>
          {t(ClientStrings.errorPage[currentErrorCode].error_msg_line1)}
        </p>
        {currentErrorCode === 'otk' &&
          ClientStrings.errorPage[currentErrorCode]?.error_msg_line2 && (
            <p className="py-4 text-lg">
              {t(ClientStrings.errorPage[currentErrorCode]?.error_msg_line2)}
            </p>
          )}
        <p className="py-4 text-lg">
          {t(ClientStrings.errorPage[currentErrorCode].error_cust_support)}
        </p>
        {isNumericErrorCode(currentErrorCode) &&
          ClientStrings.errorPage[currentErrorCode]?.back_btn && (
            <a href="https://www.rapyd.net" className="link-button w-fit">
              {t(ClientStrings.errorPage[currentErrorCode]?.back_btn)}
            </a>
          )}
      </div>
    </div>
  );
};

export default ErrorPage;
