import { useEffect } from 'react';

import { COUNTRY_CODES } from '../../data/constants';
import { useCloseWindowModal } from '../../hooks/modals';

const CallbackPage: React.FC = () => {
  const closeWindowModal = useCloseWindowModal();

  useEffect(() => {
    const channel = new BroadcastChannel('callback-data');
    const country = COUNTRY_CODES.SG;
    if (country) {
      const urlParams = new URLSearchParams(window.location.search);
      const state = urlParams.get('state');
      const code = urlParams.get('code');
      channel.postMessage({ country, token: state, code });
      window.close();
      closeWindowModal.onOpen(() => {});
    }
  }, [closeWindowModal]);

  return <></>;
};

export default CallbackPage;
