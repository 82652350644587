import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../store/store';
import { CustomIcon } from '../core';
import { GoBackIcon } from '../../assets';
import { ClientStrings } from '../../translations/ClientStrings';
import { APP_TYPES_WITH_NO_WIZARD } from '../../data/constants';
import { useObserveQuery } from '../../hooks';
import { QueryKeysEnum } from '../../data/enums';
import LanguageButton from '../LanguageButton';
import { useGetHostedApplicationByContext } from '../../services';

interface NavGoBackButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabledByStatus: boolean;
  onClick?: () => void;
}

const NavGoBackButton: React.FC<NavGoBackButtonProps> = ({ isDisabledByStatus, onClick }) => {
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const { t } = useTranslation();
  const { data: hostedAppData } = useObserveQuery(QueryKeysEnum.hosted);
  const { application_type } = hostedAppData?.data?.application_type || {};
  const { data: hostedApp } = useGetHostedApplicationByContext();

  const { country } = hostedApp?.data || {};

  const disableButton = useCallback(() => {
    if (
      (application_type && APP_TYPES_WITH_NO_WIZARD.includes(application_type)) ||
      isDisabledByStatus ||
      isPreventSaving
    ) {
      return true;
    }
    return false;
  }, [application_type, isDisabledByStatus, isPreventSaving]);

  return (
    <div className=" flex  items-center justify-between space-x-28 text-sm text-zinc-400 disabled:pointer-events-none disabled:text-gray-300">
      <button
        disabled={disableButton()}
        onClick={onClick}
        className="group flex items-center justify-center text-sm text-zinc-400 disabled:pointer-events-none disabled:text-gray-300"
      >
        <CustomIcon
          icon={
            <GoBackIcon
              className={`${disableButton() ? 'opacity-30' : ''} stroke-zinc-400  group-hover:stroke-primary-hover`}
            />
          }
        />
        <span className="group-hover:text-primary-hover">{t(ClientStrings.menu.go_back)}</span>
      </button>

      <div className="group mr-8 flex ">{<LanguageButton country={country} />}</div>
    </div>
  );
};

export default NavGoBackButton;
