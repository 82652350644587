import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import App from '../App';
import Layout from '../Components/layout/Layout';
import {
  CallbackPage,
  CreateOTPPage,
  ErrorPage,
  FormPage,
  OTPPasscodePage,
  ProviderPage,
  SessionErrorPage,
  ThankYouPage,
  WelcomePage,
  WizardPage,
} from '../Components/pages';
import { RootState } from '../store/store';
import { initFullstory } from './fullstory';
import { MESSAGES_TYPES } from '../data/constants';
import { setIsFullStoryInitialized, setTrackingSessionId } from '../store/slices/trackingSlice';
import Protected from '../Components/layout/Protected';
import { RoutePaths } from '../data/enums';
import { useGetConfig } from '../services';

const RouterComponent = () => {
  // eslint-disable-next-line no-empty-pattern
  const {} = useGetConfig();

  const { isFullStoryInitialized, trackingSessionId, userEmail } = useSelector(
    (state: RootState) => state.tracking,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.onmessage = e => {
      e.preventDefault();
      if (e.data?.type === MESSAGES_TYPES.TRACKING_SESSION_ID && e.data?.value) {
        dispatch(setTrackingSessionId(e.data.value));
      }
    };
  }, [dispatch]);

  useEffect(() => {
    // if not in iframe init fullstory normally
    if (userEmail && !isFullStoryInitialized) {
      try {
        initFullstory({ email: userEmail, trackingSessionId: trackingSessionId });
        dispatch(setIsFullStoryInitialized(true));
      } catch (error) {}
    }

    // if in iFrame and have trackingSessionId init fullstory with trackingSessionId

    // if in iFrame and don't have trackingSessionId call CP to get trackingSessionId and init fullstory with trackingSessionId
  }, [dispatch, isFullStoryInitialized, trackingSessionId, userEmail]);

  return (
    <Router>
      <Switch>
        <Route exact path={RoutePaths.VERIFY} component={App} />
        <Route exact path={RoutePaths.ROOT} component={WelcomePage} />
        <Route
          exact
          path={RoutePaths.FORM}
          render={props => (
            <Protected>
              <Layout>
                <FormPage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route
          exact
          path={RoutePaths.CREATE_OTP}
          render={props => (
            <Protected>
              <Layout>
                <CreateOTPPage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route
          exact
          path={RoutePaths.OTP}
          render={props => (
            <Protected>
              <Layout>
                <OTPPasscodePage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route
          exact
          path={RoutePaths.THANK_YOU}
          render={props => (
            <Protected>
              <Layout>
                <ThankYouPage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route
          exact
          path={RoutePaths.WIZARD}
          render={props => (
            <Protected>
              <Layout>
                <WizardPage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route
          exact
          path={RoutePaths.PROVIDER}
          render={props => (
            <Protected>
              <Layout>
                <ProviderPage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route
          exact
          path={RoutePaths.SESSION_ERROR}
          render={props => (
            <Protected>
              <Layout>
                <SessionErrorPage {...props} />
              </Layout>
            </Protected>
          )}
        />
        <Route exact path={RoutePaths.MYINFO_CALLBACK} component={CallbackPage} />
        <Route exact path={RoutePaths.FALLBACK} render={props => <ErrorPage {...props} />} />
      </Switch>
    </Router>
  );
};

export default RouterComponent;
