import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { IApplication } from '../../types/application';

interface CreateCountryAuthApplicationParams {
  country: string;
  code: string;
  happToken: string;
}

async function createCountryAuthApplication({
  code,
  country,
  happToken,
}: CreateCountryAuthApplicationParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(
    '/verify/applications/hosted/country_auth/create_country_auth_app',
    {
      country: country,
      code: code,
      state: happToken,
    },
  );
  return response.data;
}

export const useCreateCountryAuthApplication = () => {
  const queryClient = useQueryClient();
  return useMutation(createCountryAuthApplication, {
    mutationKey: MutationKeysEnum.createCountryAuthApplication,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      queryClient.invalidateQueries(QueryKeysEnum.hosted);
    },
  });
};
