import { useEffect, useState } from 'react';

type useTimerProps = {
  duration: number;
  onTimerEnd?: () => void;
  startTimer?: boolean; // This prop determines whether the timer should be active
};

const useTimer = ({ duration, onTimerEnd, startTimer = false }: useTimerProps) => {
  if (duration < 0) {
    duration = 0;
  }

  const [seconds, setSeconds] = useState(duration % 60);
  const [minutes, setMinutes] = useState(Math.floor(duration / 60));
  const [isActive, setIsActive] = useState(false); // Start with false to not start the timer automatically

  // This effect syncs the isActive state with the startTimer prop
  useEffect(() => {
    setIsActive(startTimer);
    setSeconds(duration % 60);
    setMinutes(Math.floor(duration / 60));
  }, [duration, startTimer]);

  const reset = () => {
    setSeconds(duration % 60);
    setMinutes(Math.floor(duration / 60));
    setIsActive(false); // Change to false to stop the timer on reset
  };

  const toggle = () => setIsActive(!isActive);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isActive) {
      interval = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          onTimerEnd?.();
          setIsActive(false);
          if (interval) clearInterval(interval);
        } else if (seconds === 0) {
          setMinutes(minutes => minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds => seconds - 1);
        }
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, seconds, minutes, onTimerEnd]);

  return { seconds, minutes, isActive, toggle, reset };
};

export default useTimer;
