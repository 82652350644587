import { useTranslation } from 'react-i18next';

import { useSessionExpiredModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';

const SessionExpiredModal = () => {
  const sessionExpiredModal = useSessionExpiredModal();
  const { t } = useTranslation();

  return (
    <Dialog open={sessionExpiredModal.isOpen} onOpenChange={sessionExpiredModal.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(ClientStrings.modals.session_expired.title)}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            className="w-44 min-w-fit"
            onClick={() => {
              window.location.reload();
              sessionExpiredModal.onClose();
            }}
          >
            {t(ClientStrings.modals.session_expired.yes_btn)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SessionExpiredModal;
