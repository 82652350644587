import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import * as FullStory from '@fullstory/browser';

import i18n, { initTranslations } from './lib/i18n';
import './index.css';
import { queryClient } from './lib/api';
import { store } from './store/store';
import RouterComponent from './lib/router';
import { RapydTheme, GlobalStyle } from '@rapyd/react-ui-library';
import '@rapyd/react-ui-library/dist/public/icons/css/rapyd-icons.css';
import '@rapyd/react-ui-library/dist/public/fonts/css/inter.css';
import Spinner from './Components/Spinner';
import LocalThemeProvider from './Components/LocalThemeProvider';
import { ModalProvider } from './providers/ModalProvider';

initTranslations();

FullStory.init({ orgId: '1616VV', recordCrossDomainIFrames: true });

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <RapydTheme>
            <LocalThemeProvider>
              <ModalProvider />
              <Spinner />
              <RouterComponent />
              <ReactQueryDevtools />
              <GlobalStyle />
            </LocalThemeProvider>
          </RapydTheme>
        </I18nextProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
