import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/store';
import { Trigger, upsertTrigger } from '../store/slices/triggersSlice';
import { ISection } from '../types/section';
import { ApplicationTriggers } from '../types/application';

interface UseTriggersProps {
  sectionTriggers: ApplicationTriggers;
  sectionData: ISection;
}

const useTriggers = ({ sectionTriggers, sectionData }: UseTriggersProps) => {
  const triggersState = useSelector((state: RootState) => state.triggers.triggers);
  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  const watchSectionTriggers = useWatch({ name: sectionTriggers?.watched_fields });

  useEffect(() => {
    if (!sectionTriggers) {
      return;
    }
    // Maps watched fields to their current trigger conditions and values
    const watchedSectionTriggers = watchSectionTriggers?.map((value: any, index: number) => {
      const fieldValue = Array.isArray(value)
        ? value.map(x => x.value || x)
        : value?.value
          ? value.value
          : value;
      const triggerWithValue = {
        triggers: sectionTriggers.rules[sectionTriggers.watched_fields[index]],
        value: fieldValue,
      };
      return {
        [sectionTriggers.watched_fields[index]]: triggerWithValue,
      };
    });

    // Creates an object with all watched fields and their current values
    const sectionTriggersWithValuesObject = Object.assign({}, ...watchedSectionTriggers);

    // Creates an object with all affected fields and their current values
    const affectedFields = sectionTriggers?.watched_fields?.reduce((acc: any, key: any) => {
      acc[key] = sectionTriggers.map[key];
      return acc;
    }, {});

    // Creates an array with all affected fields
    const affectedFieldsArray = Object.keys(affectedFields).reduce((acc: any, key: any) => {
      acc.push(...affectedFields[key]);
      return acc;
    }, []);

    affectedFieldsArray.forEach((field: any) => {
      // Retrieves the current trigger conditions and default values for the affected field
      const fieldTrigger = sectionTriggers.rules[field];
      const fieldTriggerConditions = fieldTrigger?.conditions;
      const fieldTriggerDefault = fieldTrigger?.default;

      // Checks if the current field trigger conditions are met
      const fieldTriggerConditionsMatched = fieldTriggerConditions.find((condition: any) => {
        if (condition.operator === '$eq') {
          return Object.keys(condition.values).every((key: any) => {
            const affectingFieldValue = sectionTriggersWithValuesObject[key]?.value;
            if (Array.isArray(condition.values[key])) {
              if (Array.isArray(affectingFieldValue)) {
                const commonElements = findCommonElements(
                  condition.values[key],
                  affectingFieldValue as any,
                );
                return commonElements;
              }
              const value =
                typeof affectingFieldValue === 'number'
                  ? affectingFieldValue.toString()
                  : affectingFieldValue;
              return condition.values[key].includes(value);
            } else {
              // Check if value is an object containing operator and value
              if (
                typeof condition.values[key] === 'object' &&
                condition.values[key].hasOwnProperty('operator')
              ) {
                const operator = condition.values[key].operator;
                const value = parseFloat(condition.values[key].value); // Parse the trigger value to a number
                const fieldVal = parseFloat(sectionTriggersWithValuesObject[key]?.value); // Parse the field value to a number

                // Check the operator and apply the appropriate comparison
                if (operator === '$gte' && fieldVal >= value) {
                  return true;
                } else if (operator === '$lte' && fieldVal <= value) {
                  return true;
                } else if (operator === '$gt' && fieldVal > value) {
                  return true;
                } else if (operator === '$lt' && fieldVal < value) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return sectionTriggersWithValuesObject[key]?.value == condition.values[key];
              }
            }
          });
        }
      });
      // Retrieves the current field object and trigger
      const trigger = triggersState.find((item: any) => item.field_token === field);

      if (fieldTriggerConditionsMatched) {
        const fieldTriggerConditionsChanges = fieldTriggerConditionsMatched?.actions;
        if (compareTriggerValues(trigger!, fieldTriggerConditionsChanges)) {
          dispatch(
            upsertTrigger({
              field_token: field,
              description: fieldTriggerConditionsChanges?.description,
              is_required: fieldTriggerConditionsChanges?.is_required,
              is_shown: fieldTriggerConditionsChanges?.is_shown,
              name: fieldTriggerConditionsChanges?.name,
              list_of_values: fieldTriggerConditionsChanges?.list_of_values,
              is_read_only: fieldTriggerConditionsChanges?.is_read_only,
              value: fieldTriggerConditionsChanges?.value,
            }),
          );
        }
        return;
      } else {
        if (compareTriggerValues(trigger!, fieldTriggerDefault)) {
          dispatch(
            upsertTrigger({
              field_token: field,
              description: fieldTriggerDefault?.description,
              is_required: fieldTriggerDefault?.is_required,
              is_shown: fieldTriggerDefault?.is_shown,
              name: fieldTriggerDefault?.name,
              list_of_values: fieldTriggerDefault?.list_of_values,
              is_read_only: fieldTriggerDefault?.is_read_only,
            }),
          );
        }
        return;
      }
    });
  }, [
    sectionTriggers?.watched_fields,
    sectionTriggers?.rules,
    sectionTriggers?.map,
    dispatch,
    triggersState,
    watchSectionTriggers,
    sectionData,
    setValue,
    sectionTriggers,
  ]);

  if (!sectionTriggers) {
    return { triggersState: [] };
  }
  return { triggersState };
};

function findCommonElements(arr1: [], arr2: []) {
  return arr1.some(item => arr2?.includes(item));
}

const compareTriggerValues = (trigger: Trigger, comparableValue: any) => {
  const isDifferent =
    trigger?.is_required !== comparableValue?.is_required ||
    trigger?.is_shown !== comparableValue?.is_shown ||
    trigger?.name !== comparableValue?.name ||
    JSON.stringify(trigger?.list_of_values) !== JSON.stringify(comparableValue?.list_of_values) ||
    trigger?.is_read_only !== comparableValue?.is_read_only ||
    trigger?.description !== comparableValue?.description ||
    JSON.stringify(trigger?.value) !== JSON.stringify(comparableValue?.value);
  return isDifferent;
};

export default useTriggers;
