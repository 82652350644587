import { create } from 'zustand';

interface useSessionExpiredModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  preventShowingAgain: boolean;
  setPreventShowingAgain: (preventShowingAgain: boolean) => void;
  forceSaveApplication: boolean;
  preventForceSaveApplication: boolean;
  setForceSaveApplication: (forceSaveApplication: boolean) => void;
  setPreventForceSaveApplication: (preventForceSaveApplication: boolean) => void;
}

export const useSessionExpiredModal = create<useSessionExpiredModalStore>(set => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true, preventShowingAgain: true }),
  onClose: () => set({ isOpen: false, preventShowingAgain: true }),
  approveCallback: () => {},
  preventShowingAgain: false,
  setPreventShowingAgain: preventShowingAgain => set({ preventShowingAgain }),
  forceSaveApplication: false,
  preventForceSaveApplication: false,
  setForceSaveApplication: forceSaveApplication => set({ forceSaveApplication }),
  setPreventForceSaveApplication: preventForceSaveApplication =>
    set({ preventForceSaveApplication }),
}));
