import { useQuery } from 'react-query';

import { api } from '../../lib/api';
import { QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

interface GetApplicationTypesParams {
  countryIso: string;
  appLevel: 1 | 2;
  ewalletToken: string;
}

export interface GetApplicationTypesResponseData {
  application_type: string;
  country: string;
  entity_type: string;
}

async function getApplicationTypes({
  appLevel,
  countryIso,
  ewalletToken,
}: GetApplicationTypesParams): Promise<GenericApiResponse<GetApplicationTypesResponseData[]>> {
  const response = await api.get<GenericApiResponse<GetApplicationTypesResponseData[]>>(
    `verify/applications/types/country/${countryIso}/${appLevel}?ewallet=${ewalletToken}`,
  );
  return response.data;
}

export const useGetApplicationTypes = (hostedApplication: any) => {
  return useQuery(
    QueryKeysEnum.appTypes,
    () => {
      if (!hostedApplication) return;
      const { country, application_level, rapyd_entity_token } = hostedApplication;
      return getApplicationTypes({
        countryIso: country,
        appLevel: application_level,
        ewalletToken: rapyd_entity_token,
      });
    },
    {
      enabled: !!hostedApplication,
    },
  );
};
