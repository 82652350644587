import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BaseFieldProps } from '../../../types/field';
import { DatePicker as RapydDatePicker } from '@rapyd/react-ui-library';
import { DatePickerProps as RapydDatePickerProps } from '@rapyd/react-ui-library/dist/components/Form/DatePicker';
import { FieldBoxVariant } from '@rapyd/react-ui-library/dist/components/Form/FieldBox';
import { SECTION_IGNORED_ERROR_MESSAGE } from '../../../data/constants';

type Event = MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | ChangeEvent<HTMLInputElement>;

interface DatePickerProps extends BaseFieldProps, RapydDatePickerProps {
  isDirty?: boolean;
  isInvalidField?: boolean;
}

const DatePickerField: React.FC<DatePickerProps> = ({
  fieldData,
  fieldRegisterValue,
  fieldErrorMessage,
  isDisabledByStatus,
  control,
  fieldTriggers,
  sectionData,
  isDirty,
  isInvalidField,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const fieldValue = watch(fieldRegisterValue!);
  const [inputValue, setInputValue] = useState<string>((fieldData?.value as string) || '');

  const handleOnChange = (date: Date, event: Event, onChange: (...event: unknown[]) => void) => {
    if (event.type === 'click') {
      if (date && date instanceof Date && !isNaN(date.getTime())) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
        setInputValue(formattedDate);
        onChange(formattedDate);
      } else {
        setInputValue('');
        onChange('');
      }
    }
  };

  useEffect(() => {
    if (fieldData?.value && fieldData?.is_read_only && fieldValue !== inputValue) {
      setInputValue(fieldData?.value as string);
    }
  }, [fieldValue, inputValue, fieldData?.value, fieldData?.is_read_only]);

  useEffect(() => {
    if (!fieldValue && inputValue && inputValue?.length === 10) {
      setInputValue('');
    }
  }, [fieldValue, inputValue]);

  const showErrorMessage = useCallback(() => {
    if (sectionData?.error_message === SECTION_IGNORED_ERROR_MESSAGE || isDisabledByStatus) {
      return false;
    } else {
      return true;
    }
  }, [sectionData?.error_message, isDisabledByStatus]);

  const shouldShowErrorMessage =
    showErrorMessage() && (isInvalidField || fieldErrorMessage || fieldData?.error_message);
  const getErrorMessage = () =>
    shouldShowErrorMessage
      ? fieldErrorMessage || (!isDirty && fieldData?.error_message ? fieldData?.error_message : '')
      : '';

  return (
    <Controller
      control={control}
      name={fieldRegisterValue!}
      render={({ field }) => (
        <DateStyled
          disabled={
            fieldTriggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus || false
          }
          fieldBoxProps={{
            placeholder: 'DD/MM/YYYY',
            value: field.value,
            required: fieldTriggers?.is_required || fieldData?.is_required,
            variant: FieldBoxVariant.Outer,
            label: t(fieldTriggers?.name || fieldData.name),
            isClearable: !(
              fieldTriggers?.is_read_only ||
              fieldData?.is_read_only ||
              isDisabledByStatus
            ),
            error: getErrorMessage(),
            disabled:
              fieldData?.triggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus,
          }}
          name={fieldData?.token || fieldData?.object_type}
          // @ts-ignore
          onChange={(date, event) => {
            handleOnChange(date, event, field.onChange);
          }}
          onKeyDown={(event: { key: string }) => {
            // If key is either number or / then update input value with key and the current input value
            if (event.key.match(/^[0-9//]+$/)) {
              // Don't allow date string length to be more than xx/xx/xxxx
              let newValue = inputValue;
              if (inputValue.length === 2 || inputValue.length === 5) {
                if (event.key !== '/') {
                  newValue += '/';
                }
              }

              if (event.key === '/') {
                const parts = inputValue.split('/');
                const lastPart = parts[parts.length - 1];

                // If the last part is a single digit, add a leading zero
                if (lastPart.length === 1) {
                  parts[parts.length - 1] = '0' + lastPart;
                  newValue = parts.join('/');
                }
              }

              if (newValue.length < 10) {
                newValue += event.key;
              }

              setInputValue(newValue);

              // If the full date has been typed, also update the field value
              if (newValue.length === 10) {
                field.onChange(newValue);
              }
            }

            // If key is backspace then remove last character from input value
            if (event.key === 'Backspace') {
              setInputValue(inputValue.slice(0, -1));
              // if the value is empty then update field value
              if (inputValue.length === 0) {
                field.onChange(inputValue);
              }
            }

            // If key is enter then update field value
            if (event.key === 'Enter') {
              field.onChange(inputValue);
            }
          }}
          onBlur={() => {
            // If the input value is not empty then update field value
            if (inputValue.length > 0) {
              field.onChange(inputValue);
            }
          }}
          value={inputValue}
          minDate={new Date(1900, 0, 1)}
          maxDate={new Date(2100, 0, 1)}
          dateFormat="dd/MM/yyyy"
          isYearPicker={true}
          isClearable={true}
        />
      )}
    />
  );
};

const DateStyled = styled(RapydDatePicker)<{ disabled: boolean }>`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export default DatePickerField;
