import { useTranslation } from 'react-i18next';

import { useRenewApplicationModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';

const RenewApplicationModal = () => {
  const renewApplication = useRenewApplicationModal();
  const { t } = useTranslation();

  return (
    <Dialog open={renewApplication.isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(ClientStrings.modals.renew_application.title)}</DialogTitle>
          <DialogDescription>
            {t(ClientStrings.modals.renew_application.description)}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            data-testid={`modal-${ClientStrings.modals.renew_application.yes_btn || 'primary'}`}
            className="w-44 min-w-fit"
            onClick={() => {
              renewApplication?.approveCallback?.();
              renewApplication.onClose();
            }}
          >
            {t(ClientStrings.modals.renew_application.yes_btn)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RenewApplicationModal;
