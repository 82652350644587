import { t } from 'i18next';
import { InputTypes } from '../types/field';
import { FieldInputTypesEnum } from './enums';

export const FieldRanges = {
  MCC: {
    MIN: 0,
    MAX: 10000,
  },
  OWNERSHIP_PERCENTAGE: {
    MIN: 25,
    MAX: 100,
  },
};

export const defaultRequiredFieldError = t('This field is required.');

export const requiredFieldError = (fieldName: string) =>
  t(`Please fill in the ${fieldName} field.`);

export const maxLengthValidationError = (maxLength: number) =>
  t(`Field exceeds character limit of ${maxLength} characters.`);

export enum RejectReason {
  FILE_REQUIRED = 'Please upload a file.',
  INVALID_WEBSITE_PROTOCOL = "Please enter a valid website address. It should start with 'http://' or 'https://'.",
  MISSING_FILES = 'Please upload at least one file. Accepted file types: IMG, JPEG, JPG, PNG, BMP, or PDF. Maximum size: 10MB.',
  NOT_OVER_18 = 'You must be at least 18 years old.',
  NOT_AFTER_1900 = 'Invalid Date of Birth: Please enter a valid date of birth. The year must be between 1900 and the current year.',
  INVALID_DATE = 'Please enter a valid date in DD-MM-YYYY format.',
  INVALID_NUMBER = 'Please enter a valid number.',
  INVALID_PHONE_NUMBER = 'Please enter a valid format.',
  INVALID_POSTAL_CODE = 'Invalid postal code format. Postal code must be 3-10 characters, without special characters.',
  INVALID_EMAIL = 'Please enter a valid email address.',
  INVALID_WEBSITE_ADDRESS = 'Please enter a valid website address.',
  INVALID_REGISTRATION_NUMBER = 'Please enter a valid registration number.',
  INVALID_VALUE = 'Invalid field value. Please try again.',
  INVALID_RANGE_25_100 = 'Please enter a number between 25 and 100.',
  INVALID_RANGE_0_10000 = 'Please enter a number between 0 and 10,000.',
  INVALID_PERCENTAGE = 'The total percentage of shares must be 100%.',
  NOT_SUPPORTED = 'The type selected is not supported, please select a new type.',
  STATEMENT_DESCRIPTOR_ERROR_MESSAGE = 'The description must be less than 22 characters and not contain any symbols.',
  INVALID_RANGE_STRING = 'Please enter a string with a minimum length of $min and no more than $max characters. You have entered $count characters.',
}

export enum FieldValidationTypes {
  IN_LIST = 'in_list',
  RANGE_INT = 'range_int',
  EMAIL = 'email',
  REGEX = 'regex',
  ISO_COUNTRY = 'iso_country',
  IS_PHONE_NUMBER = 'is_phone_number',
  IS_NUMBER = 'is_number',
  IS_DATE = 'is_date',
  IS_OVER_18 = 'is_over_18',
  IS_VALID_DOB = 'is_valid_dob',
  CONDITIONALLY_REQUIRED = 'conditionally_required',
  IN_LIST_BY_RELATED_FIELD_VALUE = 'in_list_by_related_field_value',
  HAS_FILE = 'has_file',
  IS_URL = 'is_URL',
  IS_POSTAL_CODE = 'is_postal_code',
  IS_REGISTRATION_NUMBER = 'is_registration_number',
}

export const CountryPhoneCodes = [
  {
    id: 'AD',
    value: '+376',
  },
  {
    id: 'AE',
    value: '+971',
  },
  {
    id: 'AF',
    value: '+93',
  },
  {
    id: 'AG',
    value: '+1268',
  },
  {
    id: 'AI',
    value: '+1 264',
  },
  {
    id: 'AL',
    value: '+355',
  },
  {
    id: 'AM',
    value: '+374',
  },

  {
    id: 'AO',
    value: '+244',
  },
  {
    id: 'AQ',
    value: '+672',
  },
  {
    id: 'AR',
    value: '+54',
  },
  {
    id: 'AS',
    value: '+1 684',
  },
  {
    id: 'AT',
    value: '+43',
  },
  {
    id: 'AU',
    value: '+61',
  },
  {
    id: 'AW',
    value: '+297',
  },
  {
    id: 'AZ',
    value: '+994',
  },
  {
    id: 'BA',
    value: '+387',
  },
  {
    id: 'BB',
    value: '+1 246',
  },
  {
    id: 'BD',
    value: '+880',
  },
  {
    id: 'BE',
    value: '+32',
  },
  {
    id: 'BF',
    value: '+226',
  },
  {
    id: 'BG',
    value: '+359',
  },
  {
    id: 'BH',
    value: '+973',
  },
  {
    id: 'BI',
    value: '+257',
  },
  {
    id: 'BJ',
    value: '+229',
  },
  {
    id: 'BL',
    value: '+590',
  },
  {
    id: 'BM',
    value: '+1 441',
  },
  {
    id: 'BN',
    value: '+673',
  },
  {
    id: 'BO',
    value: '+591',
  },
  {
    id: 'BR',
    value: '+55',
  },
  {
    id: 'BS',
    value: '+1 242',
  },
  {
    id: 'BT',
    value: '+975',
  },
  {
    id: 'BV',
    value: '+55',
  },
  {
    id: 'BW',
    value: '+267',
  },
  {
    id: 'BY',
    value: '+375',
  },
  {
    id: 'BZ',
    value: '+501',
  },
  {
    id: 'CA',
    value: '+1',
  },
  {
    id: 'CC',
    value: '+61',
  },
  {
    id: 'CD',
    value: '+243',
  },
  {
    id: 'CF',
    value: '+236',
  },
  {
    id: 'CG',
    value: '+242',
  },
  {
    id: 'CH',
    value: '+41',
  },
  {
    id: 'CI',
    value: '+225',
  },
  {
    id: 'CK',
    value: '+682',
  },
  {
    id: 'CL',
    value: '+56',
  },
  {
    id: 'CM',
    value: '+237',
  },
  {
    id: 'CN',
    value: '+86',
  },
  {
    id: 'CO',
    value: '+57',
  },
  {
    id: 'CR',
    value: '+506',
  },
  {
    id: 'CU',
    value: '+53',
  },
  {
    id: 'CV',
    value: '+238',
  },
  {
    id: 'CX',
    value: '+61',
  },
  {
    id: 'CY',
    value: '+357',
  },
  {
    id: 'CZ',
    value: '+420',
  },
  {
    id: 'DE',
    value: '+49',
  },
  {
    id: 'DJ',
    value: '+253',
  },
  {
    id: 'DK',
    value: '+45',
  },
  {
    id: 'DM',
    value: '+1 767',
  },
  {
    id: 'DO',
    value: '+1 809',
  },
  {
    id: 'DZ',
    value: '+213',
  },
  {
    id: 'EC',
    value: '+593',
  },
  {
    id: 'EE',
    value: '+372',
  },
  {
    id: 'EG',
    value: '+20',
  },
  {
    id: 'EH',
    value: '+212',
  },
  {
    id: 'ER',
    value: '+291',
  },
  {
    id: 'ES',
    value: '+34',
  },
  {
    id: 'ET',
    value: '+251',
  },
  {
    id: 'FI',
    value: '+358',
  },
  {
    id: 'FJ',
    value: '+679',
  },
  {
    id: 'FK',
    value: '+500',
  },
  {
    id: 'FM',
    value: '+691',
  },
  {
    id: 'FO',
    value: '+298',
  },
  {
    id: 'FR',
    value: '+33',
  },
  {
    id: 'GA',
    value: '+241',
  },
  {
    id: 'GB',
    value: '+44',
  },
  {
    id: 'GD',
    value: '+1 473',
  },
  {
    id: 'GE',
    value: '+995',
  },
  {
    id: 'GF',
    value: '+594',
  },
  {
    id: 'GG',
    value: '+44 1481',
  },
  {
    id: 'GH',
    value: '+233',
  },
  {
    id: 'GI',
    value: '+350',
  },
  {
    id: 'GL',
    value: '+299',
  },
  {
    id: 'GM',
    value: '+220',
  },
  {
    id: 'GN',
    value: '+224',
  },
  {
    id: 'GP',
    value: '+590',
  },
  {
    id: 'GQ',
    value: '+240',
  },
  {
    id: 'GR',
    value: '+30',
  },
  {
    id: 'GS',
    value: '+500',
  },
  {
    id: 'GT',
    value: '+502',
  },
  {
    id: 'GU',
    value: '+1 671',
  },
  {
    id: 'GW',
    value: '+245',
  },
  {
    id: 'GY',
    value: '+595',
  },
  {
    id: 'HK',
    value: '+852',
  },
  {
    id: 'HM',
    value: '+672',
  },
  {
    id: 'HN',
    value: '+504',
  },
  {
    id: 'HR',
    value: '+385',
  },
  {
    id: 'HT',
    value: '+509',
  },
  {
    id: 'HU',
    value: '+36',
  },
  {
    id: 'ID',
    value: '+62',
  },
  {
    id: 'IE',
    value: '+353',
  },
  {
    id: 'IL',
    value: '+972',
  },
  {
    id: 'IM',
    value: '+44 1624',
  },
  {
    id: 'IN',
    value: '+91',
  },
  {
    id: 'IO',
    value: '+246',
  },
  {
    id: 'IQ',
    value: '+964',
  },
  {
    id: 'IR',
    value: '+98',
  },
  {
    id: 'IS',
    value: '+354',
  },
  {
    id: 'IT',
    value: '+39',
  },
  {
    id: 'JE',
    value: '+44 1534',
  },
  {
    id: 'JM',
    value: '+1 876',
  },
  {
    id: 'JO',
    value: '+962',
  },
  {
    id: 'JP',
    value: '+81',
  },
  {
    id: 'KE',
    value: '+254',
  },
  {
    id: 'KG',
    value: '+996',
  },
  {
    id: 'KH',
    value: '+855',
  },
  {
    id: 'KI',
    value: '+686',
  },
  {
    id: 'KM',
    value: '+269',
  },
  {
    id: 'KN',
    value: '+1 869',
  },
  {
    id: 'KP',
    value: '+850',
  },
  {
    id: 'KR',
    value: '+82',
  },
  {
    id: 'KW',
    value: '+965',
  },
  {
    id: 'KY',
    value: '+ 345',
  },
  {
    id: 'KZ',
    value: '+7',
  },
  {
    id: 'LA',
    value: '+856',
  },
  {
    id: 'LB',
    value: '+961',
  },
  {
    id: 'LC',
    value: '+1 758',
  },
  {
    id: 'LI',
    value: '+423',
  },
  {
    id: 'LK',
    value: '+94',
  },
  {
    id: 'LR',
    value: '+231',
  },
  {
    id: 'LS',
    value: '+266',
  },
  {
    id: 'LT',
    value: '+370',
  },
  {
    id: 'LU',
    value: '+352',
  },
  {
    id: 'LV',
    value: '+371',
  },
  {
    id: 'LY',
    value: '+218',
  },
  {
    id: 'MA',
    value: '+212',
  },
  {
    id: 'MC',
    value: '+377',
  },
  {
    id: 'MD',
    value: '+373',
  },
  {
    id: 'ME',
    value: '+382',
  },
  {
    id: 'MF',
    value: '+590',
  },
  {
    id: 'MG',
    value: '+261',
  },
  {
    id: 'MH',
    value: '+692',
  },
  {
    id: 'MK',
    value: '+389',
  },
  {
    id: 'ML',
    value: '+223',
  },
  {
    id: 'MM',
    value: '+95',
  },
  {
    id: 'MN',
    value: '+976',
  },
  {
    id: 'MO',
    value: '+853',
  },
  {
    id: 'MP',
    value: '+1 670',
  },
  {
    id: 'MQ',
    value: '+596',
  },
  {
    id: 'MR',
    value: '+222',
  },
  {
    id: 'MS',
    value: '+1664',
  },
  {
    id: 'MT',
    value: '+356',
  },
  {
    id: 'MU',
    value: '+230',
  },
  {
    id: 'MV',
    value: '+960',
  },
  {
    id: 'MW',
    value: '+265',
  },
  {
    id: 'MX',
    value: '+52',
  },
  {
    id: 'MY',
    value: '+60',
  },
  {
    id: 'MZ',
    value: '+258',
  },
  {
    id: 'NA',
    value: '+264',
  },
  {
    id: 'NC',
    value: '+687',
  },
  {
    id: 'NE',
    value: '+227',
  },
  {
    id: 'NF',
    value: '+672',
  },
  {
    id: 'NG',
    value: '+234',
  },
  {
    id: 'NI',
    value: '+505',
  },
  {
    id: 'NL',
    value: '+31',
  },
  {
    id: 'NO',
    value: '+47',
  },
  {
    id: 'NP',
    value: '+977',
  },
  {
    id: 'NR',
    value: '+674',
  },
  {
    id: 'NU',
    value: '+683',
  },
  {
    id: 'NZ',
    value: '+64',
  },
  {
    id: 'OM',
    value: '+968',
  },
  {
    id: 'PA',
    value: '+507',
  },
  {
    id: 'PE',
    value: '+51',
  },
  {
    id: 'PF',
    value: '+689',
  },
  {
    id: 'PG',
    value: '+675',
  },
  {
    id: 'PH',
    value: '+63',
  },
  {
    id: 'PK',
    value: '+92',
  },
  {
    id: 'PL',
    value: '+48',
  },
  {
    id: 'PM',
    value: '+508',
  },
  {
    id: 'PN',
    value: '+64',
  },
  {
    id: 'PR',
    value: '+1 787',
  },
  {
    id: 'PS',
    value: '+970',
  },
  {
    id: 'PT',
    value: '+351',
  },
  {
    id: 'PW',
    value: '+680',
  },
  {
    id: 'PY',
    value: '+595',
  },
  {
    id: 'QA',
    value: '+974',
  },
  {
    id: 'RE',
    value: '+262',
  },
  {
    id: 'RO',
    value: '+40',
  },
  {
    id: 'RS',
    value: '+381',
  },
  {
    id: 'RU',
    value: '+7',
  },
  {
    id: 'RW',
    value: '+250',
  },
  {
    id: 'SA',
    value: '+966',
  },
  {
    id: 'SB',
    value: '+677',
  },
  {
    id: 'SC',
    value: '+248',
  },
  {
    id: 'SD',
    value: '+249',
  },
  {
    id: 'SE',
    value: '+46',
  },
  {
    id: 'SG',
    value: '+65',
  },
  {
    id: 'SH',
    value: '+290',
  },
  {
    id: 'SI',
    value: '+386',
  },
  {
    id: 'SJ',
    value: '+47',
  },
  {
    id: 'SK',
    value: '+421',
  },
  {
    id: 'SL',
    value: '+232',
  },
  {
    id: 'SM',
    value: '+378',
  },
  {
    id: 'SN',
    value: '+221',
  },
  {
    id: 'SO',
    value: '+252',
  },
  {
    id: 'SR',
    value: '+597',
  },
  {
    id: 'ST',
    value: '+239',
  },
  {
    id: 'SV',
    value: '+503',
  },
  {
    id: 'SY',
    value: '+963',
  },
  {
    id: 'SZ',
    value: '+268',
  },
  {
    id: 'TC',
    value: '+1 649',
  },
  {
    id: 'TD',
    value: '+235',
  },
  {
    id: 'TF',
    value: '+262',
  },
  {
    id: 'TG',
    value: '+228',
  },
  {
    id: 'TH',
    value: '+66',
  },
  {
    id: 'TJ',
    value: '+992',
  },
  {
    id: 'TK',
    value: '+690',
  },
  {
    id: 'TL',
    value: '+670',
  },
  {
    id: 'TM',
    value: '+993',
  },
  {
    id: 'TN',
    value: '+216',
  },
  {
    id: 'TO',
    value: '+676',
  },
  {
    id: 'TR',
    value: '+90',
  },
  {
    id: 'TT',
    value: '+1 868',
  },
  {
    id: 'TV',
    value: '+688',
  },
  {
    id: 'TW',
    value: '+886',
  },
  {
    id: 'TZ',
    value: '+255',
  },
  {
    id: 'UA',
    value: '+380',
  },
  {
    id: 'UG',
    value: '+256',
  },
  {
    id: 'US',
    value: '+1',
  },
  {
    id: 'UY',
    value: '+598',
  },
  {
    id: 'UZ',
    value: '+998',
  },
  {
    id: 'VA',
    value: '+379',
  },
  {
    id: 'VC',
    value: '+1 784',
  },
  {
    id: 'VE',
    value: '+58',
  },
  {
    id: 'VG',
    value: '+1 284',
  },
  {
    id: 'VI',
    value: '+1 340',
  },
  {
    id: 'VN',
    value: '+84',
  },
  {
    id: 'VU',
    value: '+678',
  },
  {
    id: 'WF',
    value: '+681',
  },
  {
    id: 'WS',
    value: '+685',
  },
  {
    id: 'YE',
    value: '+967',
  },
  {
    id: 'YT',
    value: '+262',
  },
  {
    id: 'ZA',
    value: '+27',
  },
  {
    id: 'ZM',
    value: '+260',
  },
  {
    id: 'ZW',
    value: '+263',
  },
];

export const CountryPostalCodes: Record<string, string> = {
  GB: '^([a-zA-Z]{1,2}[a-zA-Z\\d]{1,2})\\s(\\d[a-zA-Z]{2})$',
  JE: '^JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$',
  GG: '^GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$',
  IM: '^IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$',
  US: '^\\d{5}([ \\-]\\d{4})?$',
  CA: '^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d$',
  DE: '^\\d{5}$',
  JP: '^\\d{3}-\\d{4}$',
  FR: '^\\d{2}[ ]?\\d{3}$',
  AU: '^\\d{4}$',
  IT: '^\\d{5}$',
  CH: '^\\d{4}$',
  AT: '^\\d{4}$',
  ES: '^\\d{5}$',
};

export const DefaultPostalCodeRegex = '^[0-9a-zA-Z- ]{3,10}$';

export const MAX_PHONE_NUMBER_LENGTH = 17;

export const MIN_PHONE_NUMBER_LENGTH = 4;

export const MAX_TEXT_LENGTH = 250;

export const InListValidationFields: InputTypes[] = [
  FieldInputTypesEnum.dropdown,
  FieldInputTypesEnum.multiselect_dropdown,
  FieldInputTypesEnum.radio_button,
  FieldInputTypesEnum.dropdown_check_box,
];
