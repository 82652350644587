import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ISection } from '../types/section';
import { clearSectionFormData, returnFieldFilesFromSection } from '../utils/helpers';
import { IApplication } from '../types/application';
import { setIsPreventSaving } from '../store/slices/globalSlice';
import { useDeleteDocuments } from '../services';

export default function useClearSection(sectionData: ISection, applicationData: IApplication) {
  const { getValues, setValue } = useFormContext();
  const { mutateAsync: mutateDeleteDocuments } = useDeleteDocuments();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const clearSection = async () => {
    dispatch(setIsPreventSaving(true));
    setLoading(true);
    const sectionFormData = getValues(sectionData.token);
    clearSectionFormData(sectionFormData, setValue, sectionData.token, sectionData, ['file']);
    const fields = returnFieldFilesFromSection(sectionData);
    for (const field of fields) {
      const fieldVal = getValues(field.field_triggers_value);
      if (!fieldVal) continue;
      const filesToDelete = fieldVal.filter((file: any) => file.is_deleted === false);
      if (!filesToDelete || !Array.isArray(filesToDelete) || filesToDelete.length === 0) continue;
      try {
        await mutateDeleteDocuments({
          applicationToken: applicationData.token,
          sectionToken: sectionData.token,
          fieldToken: field.token,
          files: filesToDelete,
        });
        setValue(field.field_triggers_value, [], { shouldDirty: true });
      } catch (error) {}
    }

    dispatch(setIsPreventSaving(false));
    setLoading(false);
  };

  return { clearSection, loading };
}
