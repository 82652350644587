import { useEffect, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { useObserveQuery, usePathParams } from '../../hooks';
import { getLocalStorageItem } from '../../utils/helpers';
import { isInIframe } from '../../lib/iframe';
import { ApplicationStatusEnum, QueryKeysEnum, RoutePaths } from '../../data/enums';

const Protected: React.FC<PropsWithChildren> = ({ children }) => {
  const { happToken } = usePathParams();
  const { data: hostedAppData } = useObserveQuery(QueryKeysEnum.hosted);
  const { status } = hostedAppData?.data?.application || {};
  const history = useHistory();
  const inIFrame = isInIframe();

  useEffect(() => {
    if (happToken && !inIFrame) {
      const session = getLocalStorageItem(happToken);
      if (!session) {
        history.push(`${RoutePaths.CREATE_OTP}?token=${happToken}`);
      }
    }
  }, [happToken, history, inIFrame]);

  useEffect(() => {
    const path = history?.location?.pathname;
    if (status && path && status !== ApplicationStatusEnum.new) {
      if (path === RoutePaths.WIZARD) {
        history.push(`${RoutePaths.THANK_YOU}?token=${happToken}`);
      }
    }
  }, [history.location.pathname, happToken, history, status]);

  return <>{children}</>;
};

export default Protected;
