import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePathParams, useTimer } from '../hooks';
import { useGetOTP } from '../services';
import { useSessionExpiredModal, useSessionTimerModal } from '../hooks/modals';
import {
  SESSION_EXPIRATION_MODAL_MINUTES,
  SESSION_EXPIRATION_SAVE_APPLICATION_SECONDS,
} from '../data/constants';
import { getLocalStorageItem, isValidUUID } from '../utils/helpers';
import { ClientStrings } from '../translations/ClientStrings';

const SessionTimer: React.FC = () => {
  const [fetchOTP, setFetchOTP] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [initialDuration, setInitialDuration] = useState<number | null>(null);

  const { happToken } = usePathParams();
  const { t } = useTranslation();
  const sessionToken = getLocalStorageItem(happToken!);
  const { data: otpData } = useGetOTP(happToken!, fetchOTP);
  const expiration_date = otpData?.data?.session_response?.expiration_date;

  const sessionTimerModal = useSessionTimerModal();
  const sessionExpiredModal = useSessionExpiredModal();

  useEffect(() => {
    if (expiration_date && !startTimer) {
      const currentTime = Math.floor(Date.now() / 1000);
      const duration = expiration_date - currentTime;
      setInitialDuration(duration);
      setStartTimer(true);
    }
  }, [expiration_date, startTimer]);

  useEffect(() => {
    if (sessionToken && !fetchOTP && isValidUUID(sessionToken)) {
      setFetchOTP(true);
    }
  }, [sessionToken, fetchOTP]);

  const {
    minutes,
    seconds,
    isActive: isTimerActive,
  } = useTimer({
    duration: initialDuration ?? 3600,
    startTimer,
  });

  useEffect(() => {
    if (
      minutes < SESSION_EXPIRATION_MODAL_MINUTES &&
      isTimerActive &&
      !sessionTimerModal.isOpen &&
      !sessionTimerModal.preventShowingAgain &&
      minutes >= 0
    ) {
      sessionTimerModal.onOpen(
        Math.min(initialDuration!, 60 * SESSION_EXPIRATION_MODAL_MINUTES - 1),
      );
      setShowTimer(true);
    }
  }, [minutes, isTimerActive, sessionTimerModal, initialDuration]);

  useEffect(() => {
    if (
      minutes === 0 &&
      seconds === 0 &&
      !sessionTimerModal.isOpen &&
      sessionTimerModal.preventShowingAgain &&
      !sessionExpiredModal.isOpen &&
      !sessionExpiredModal.preventShowingAgain
    ) {
      sessionExpiredModal.onOpen();
    }
  }, [minutes, seconds, initialDuration, sessionTimerModal, sessionExpiredModal]);

  useEffect(() => {
    const shouldSaveApplication =
      minutes === 0 &&
      seconds === SESSION_EXPIRATION_SAVE_APPLICATION_SECONDS &&
      !sessionExpiredModal.forceSaveApplication &&
      !sessionExpiredModal.preventForceSaveApplication;

    if (shouldSaveApplication && !document?.hidden) {
      sessionExpiredModal.setForceSaveApplication(true);
    }
  }, [minutes, seconds, sessionExpiredModal]);

  return (
    <div>
      {showTimer && (
        <div className="mr-2 flex w-[200px] text-sm font-medium text-gray-700">
          {isTimerActive ? (
            <p>
              {t(ClientStrings.header.time_remaining_in_session)}: {minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p>{t(ClientStrings.header.session_expired)}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SessionTimer;
