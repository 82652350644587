import { useMutation } from 'react-query';

import { api } from '../../lib/api';
import { blobToBase64 } from '../../utils/helpers';
import { MutationKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

export interface BrowserFile {
  path: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  webkitRelativePath: string;
}

interface UploadDocumentsProps {
  applicationToken: string;
  sectionToken: string;
  fieldToken: string;
  files: BrowserFile[];
}

export interface UploadDocumentsResponseData {
  base_64_file: string;
  created_at: number;
  is_deleted: boolean;
  origin_name: string;
  status: string;
  token: string;
  type: string;
  updated_at: number;
  uploaded_by: string;
  url: string;
  extra_data: {
    country: string;
    document_type: string;
    description: string;
  };
}

export interface UploadDocumentsFailedResponseData {
  error_code: string;
  status: string;
  message: string;
  response_code: string;
  operation_id: string;
  origin_name: string;
}

export type UploadDocumentsResponse =
  | UploadDocumentsResponseData
  | UploadDocumentsFailedResponseData;

async function uploadDocuments({
  applicationToken,
  fieldToken,
  files,
  sectionToken,
}: UploadDocumentsProps): Promise<GenericApiResponse<UploadDocumentsResponse[]>> {
  const filesArray = await Promise.all(
    files.map(async file => {
      const blob = await blobToBase64(file as any);
      return {
        fileName: file.path,
        base64src: blob,
      };
    }),
  );

  const response = await api.post<GenericApiResponse<UploadDocumentsResponse[]>>(
    `verify/applications/documents/${applicationToken}/${sectionToken}/${fieldToken}`,
    {
      file: filesArray,
    },
  );

  return response.data;
}

/**
 * Uploads documents to the backend, send them as base64
 * @param applicationToken
 * @param fieldToken
 * @param files
 * @param sectionToken
 * @returns UploadDocumentsResponse[]
 */
export const useUploadDocuments = () => {
  return useMutation(uploadDocuments, {
    mutationKey: MutationKeysEnum.uploadDocuments,
  });
};
