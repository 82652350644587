import { PropsWithChildren, ReactNode } from 'react';
import { Typography } from '../core';
import { useTranslation } from 'react-i18next';

interface OtpLayoutProps extends PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle: string;
}

const OtpLayout: React.FC<OtpLayoutProps> = ({ title, subTitle, children }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-[calc(100vh-70px)] w-screen items-center">
      <div className="flex h-full flex-col items-center justify-start text-center">
        <div className="flex h-full flex-col items-center justify-between px-4 sm:px-0 sm:pt-10 md:justify-start">
          <div className="flex h-full flex-col items-center gap-y-8 md:h-fit">
            <Typography.H3>{t(title)}</Typography.H3>
            <Typography.BODY1 className="text-primary-text max-w-[384px] text-lg leading-normal">
              {t(subTitle)}
            </Typography.BODY1>
            <div className="flex h-full flex-col items-center justify-between md:justify-start">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpLayout;
