import { useEffect, useState } from 'react';

import {
  useGetApplicationTypes,
  useGetCountryList,
  useGetSellTypeAndMCC,
  useGetHostedApplicationByContext,
} from '../services';
import { GetCountryListResponseData } from '../services/countryList/getCountryList';
import { GenericDropdownObjectValue } from '../types/services';

const useWizardForm = () => {
  const { data: hostedAppData, isLoading: isHostedAppLoading } = useGetHostedApplicationByContext();
  const { data: countryListData, isLoading: isCountryListLoading } = useGetCountryList();
  const { data: appTypesData, isLoading: isAppTypesLoading } = useGetApplicationTypes(
    hostedAppData?.data,
  );
  const { data: sellTypeMCCData, isLoading: isSellTypeMCCLoading } = useGetSellTypeAndMCC();

  const [countryList, setCountryList] = useState<GetCountryListResponseData[] | null>(null);
  const [appTypes, setAppTypes] = useState<GenericDropdownObjectValue[] | null>(null);
  const [mccs, setMCCs] = useState<any>(null);
  const [sellTypes, setSellTypes] = useState<GenericDropdownObjectValue[] | null>(null);

  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedAppType, setSelectedAppType] = useState<any>(null);
  const [selectedMCC, setSelectedMCC] = useState<any>(null);
  const [selectedSellType, setSelectedSellType] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      isCountryListLoading ||
      isAppTypesLoading ||
      isSellTypeMCCLoading ||
      isHostedAppLoading ||
      !countryList ||
      !appTypes ||
      !mccs ||
      !sellTypes
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isAppTypesLoading,
    isCountryListLoading,
    isLoading,
    isSellTypeMCCLoading,
    isHostedAppLoading,
    appTypes,
    countryList,
    mccs,
    sellTypes,
  ]);

  useEffect(() => {
    if (appTypesData && !appTypes) {
      const appTypesArray = appTypesData.data.map(appType => {
        return { id: appType.application_type, value: appType.entity_type };
      });
      setAppTypes(appTypesArray);
    }
  }, [appTypesData, appTypes]);

  useEffect(() => {
    if (countryListData && hostedAppData && hostedAppData?.data?.country && !countryList) {
      if (!countryList) {
        const countryListArray = countryListData.data.map(country => {
          return { id: country.id, value: country.value };
        });
        setCountryList(countryListArray);
      }
    }
  }, [countryList, countryListData, hostedAppData]);

  useEffect(() => {
    if (sellTypeMCCData && !sellTypes) {
      const sellTypesArray = sellTypeMCCData.data.sell_type.map(sellType => {
        return { id: sellType.id, value: sellType.value };
      });
      setSellTypes(sellTypesArray);
    }
  }, [sellTypeMCCData, sellTypes]);

  useEffect(() => {
    if (sellTypeMCCData && !mccs) {
      const mccsArray = Object.values(sellTypeMCCData.data.business_industry)
        .flat()
        .map(mcc => {
          return { id: mcc.id, value: mcc.value };
        });
      setMCCs(mccsArray);
    }
  }, [sellTypeMCCData, mccs]);

  useEffect(() => {
    if (hostedAppData && !selectedAppType && hostedAppData?.data?.application_type) {
      setSelectedAppType({
        value: hostedAppData?.data?.application_type?.application_type,
        label: hostedAppData?.data?.application_type?.entity_type,
      });
    }
  }, [hostedAppData, selectedAppType, appTypes]);

  useEffect(() => {
    if (
      hostedAppData &&
      mccs &&
      !selectedMCC &&
      sellTypeMCCData &&
      hostedAppData?.data?.business_industry
    ) {
      const mccObj = mccs.filter((mcc: any) => mcc.id === hostedAppData.data.business_industry)[0];
      const convertedMCC = { value: mccObj.id, label: mccObj.value };
      setSelectedMCC(convertedMCC);
    }
  }, [hostedAppData, mccs, selectedMCC, sellTypeMCCData]);

  useEffect(() => {
    if (
      hostedAppData &&
      sellTypes &&
      !selectedSellType &&
      sellTypeMCCData &&
      hostedAppData?.data?.sell_type
    ) {
      const sellTypeObj = sellTypes.filter(
        sellType => sellType.id === hostedAppData.data.sell_type,
      )[0];
      const convertedSellType = { value: sellTypeObj.id, label: sellTypeObj.value };
      setSelectedSellType(convertedSellType);
    }
  }, [hostedAppData, sellTypes, selectedSellType, sellTypeMCCData]);

  useEffect(() => {
    if (hostedAppData && countryList && !selectedCountry && hostedAppData?.data?.country) {
      const countryObj = countryList.filter(
        country => country.id === hostedAppData.data.country,
      )[0];
      const convertedCountry = { value: countryObj.id, label: countryObj.value };
      setSelectedCountry(convertedCountry);
    }
  }, [hostedAppData, countryList, selectedCountry]);

  return {
    hostedAppData,
    countryList,
    appTypes,
    mccs,
    sellTypes,
    selectedCountry,
    selectedAppType,
    selectedMCC,
    selectedSellType,
    isLoading,
  };
};

export default useWizardForm;
