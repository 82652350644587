import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RadioButtonProps } from '@rapyd/react-ui-library/dist/components/RadioButton';
import { BaseFieldProps } from '../../../types/field';
import { RadioButton as RapydRadioButton } from '@rapyd/react-ui-library';
import { ErrorMessage, Typography } from '..';
import { SECTION_IGNORED_ERROR_MESSAGE } from '../../../data/constants';

interface RadioButtonFieldProps extends BaseFieldProps, RadioButtonProps {
  label?: string;
  shouldMaskCharacters?: boolean;
  isInvalidField?: boolean;
  isDirty?: boolean;
}

const RadioButtonField: React.FC<RadioButtonFieldProps> = ({
  fieldData,
  fieldRegisterValue,
  control,
  isDisabledByStatus,
  fieldTriggers,
  sectionData,
  fieldErrorMessage,
  isInvalidField,
  isDirty,
}) => {
  const { t } = useTranslation();
  const lov = fieldTriggers?.list_of_values || fieldData.list_of_values;

  const showErrorMessage = useCallback(() => {
    if (sectionData?.error_message === SECTION_IGNORED_ERROR_MESSAGE || isDisabledByStatus) {
      return false;
    } else {
      return true;
    }
  }, [sectionData?.error_message, isDisabledByStatus]);

  const shouldShowErrorMessage =
    showErrorMessage() && (fieldErrorMessage || (!isDirty && fieldData?.error_message));
  const errorMessage = (fieldErrorMessage ||
    (isInvalidField && !isDirty && t(fieldData?.error_message!))) as string;
  const getErrorMessage = () => (shouldShowErrorMessage ? errorMessage : '');

  return (
    <Controller
      control={control}
      name={fieldRegisterValue!}
      render={({ field }) => (
        <div inner-value={field?.value} className="relative flex w-full flex-col gap-y-4">
          <div>
            {(fieldTriggers?.is_required || fieldData?.is_required) && (
              <span className="pr-1 align-middle text-red-500">*</span>
            )}
            <Typography.BODY1 className="text-[#676b73]">
              {t(fieldTriggers?.name || fieldData.name)}
            </Typography.BODY1>
          </div>
          <div data-testid={fieldData?.token} className="flex gap-x-8">
            {lov.map((item: any) => {
              return (
                <div key={item.id} className="flex">
                  <RapydRadioButton
                    data-testid={item.value}
                    disabled={
                      fieldTriggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus
                    }
                    checked={field.value === item.id}
                    onChange={val => {
                      field.onChange(item.id);
                    }}
                    name={fieldData?.token || fieldData?.object_type}
                  >
                    <Typography.BODY1 className="text-[#5e6164]">{t(item?.value)}</Typography.BODY1>
                  </RapydRadioButton>
                </div>
              );
            })}
          </div>
          <ErrorMessage message={getErrorMessage()} />
        </div>
      )}
    />
  );
};

export default RadioButtonField;
