import { useTranslation } from 'react-i18next';

import { useGenericModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';

const GenericModal = () => {
  const genericModal = useGenericModal();
  const { t } = useTranslation();

  return (
    <Dialog open={genericModal.isOpen} onOpenChange={genericModal.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(genericModal.title)}</DialogTitle>
          <DialogDescription>{t(genericModal.description)}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          {genericModal?.approveBtnText && (
            <Button
              data-testid={`modal-${genericModal.approveBtnText || 'primary'}`}
              className="w-44 min-w-fit"
              onClick={() => {
                genericModal?.approveCallback?.();
                genericModal.onClose();
              }}
            >
              {t(genericModal.approveBtnText)}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default GenericModal;
