import { IconName } from '@rapyd/react-ui-library/dist/components/Icon';
import {
  TextField,
  DropdownField,
  TextAreaField,
  FileUploadField,
  RadioButtonField,
  DatePickerField,
  CheckboxField,
  Icon,
} from '../Components/core';
import { SectionTypes } from '../types/application';
import { InputTypes } from '../types/field';
import { MutationKeysEnum } from './enums';

export const SECTION_ICON_MAP: { [key in SectionTypes]: React.ReactNode } = {
  business: <Icon className="text-lg" name={IconName.BUSINESS} />,
  company: <Icon className="text-lg" name={IconName.BUILDING} />,
  AU: <Icon className="text-lg" name={IconName.USER} />,
  FQ: <Icon className="text-lg" name={IconName.USER} />,
  shareholders_control: <Icon className="text-lg" name={IconName.USERS} />,
  owner: <Icon className="text-lg" name={IconName.USER} />,
  officer: <Icon className="text-lg" name={IconName.USER} />,
  shareholder: <Icon className="text-lg" name={IconName.USER} />,
  ceo: <Icon className="text-lg" name={IconName.USER} />,
  your_business: <Icon className="text-lg" name={IconName.BUSINESS} />,
  company_info: <Icon className="text-lg" name={IconName.BUILDING} />,
  directors_control: <Icon className="text-lg" name={IconName.USERS} />,
  director: <Icon className="text-lg" name={IconName.USER} />,
};

export const FIELD_COMPONENT_MAP: { [key in InputTypes]: React.FC<any> } = {
  dropdown: DropdownField,
  multiselect_dropdown: DropdownField,
  dropdown_check_box: DropdownField,
  select: DropdownField,
  input: TextField,
  email: TextField,
  phone_number: TextField,
  number: TextField,
  textarea: TextAreaField,
  text: TextAreaField,
  file: FileUploadField,
  radio_button: RadioButtonField,
  date: DatePickerField,
  checkbox: CheckboxField,
  check_box: CheckboxField,
  phone_country_code: DropdownField,
};

export const MULTISELECT_DROPDOWN_FIELDS = ['multiselect_dropdown', 'dropdown_check_box'];

export const NUMBER_FIELDS_INPUT_TYPES = ['number', 'phone_number'];

export const DOWNLOAD_FILE_TYPE = 'download_fq';

export const EN_US = 'en-US';
export const DE_DE = 'de-DE';
export const IS_IS = 'is-IS';
const ES_MX = 'es-MX';
const ZH_TW = 'zh-TW';
const ZH_CN = 'zh-CN';
const KO_KR = 'ko-KR';
const PT_BR = 'pt-BR';
const ID_ID = 'id-ID';
const TL_PH = 'tl-PH';
const TH_TH = 'th-TH';
const NL_NL = 'nl-NL';
const FR_FR = 'fr-FR';
const JA_JP = 'ja-JP';
const HI_IN = 'hi-IN';
const VI_VN = 'vi-VN';
const MS_MY = 'ms-MY';
const RU_RU = 'ru-RU';
const UK_UK = 'uk-UK';

export interface LangObject {
  lang: string;
  serverLang: string;
  friendlyName: string;
  code?: string;
}
interface Langs {
  [key: string]: LangObject;
}

export const LANGS: Langs = {
  [EN_US]: { lang: 'English', serverLang: 'en', friendlyName: '', code: EN_US },
  [ES_MX]: { lang: 'Español', serverLang: 'es', friendlyName: 'Spanish' },
  [ZH_TW]: { lang: '繁体中文', serverLang: 'zh-tw', friendlyName: 'Traditional Chinese' },
  [ZH_CN]: { lang: '简体中文', serverLang: 'zh-cn', friendlyName: 'Simplified Chinese' },
  [KO_KR]: { lang: '한국어', serverLang: 'ko', friendlyName: 'Korean' },
  [PT_BR]: { lang: 'Português', serverLang: 'pt', friendlyName: 'Portuguese' },
  [ID_ID]: { lang: 'Bahasa Indonesia', serverLang: 'id', friendlyName: 'Indonesian' },
  [TL_PH]: { lang: 'Tagalog', serverLang: 'tl', friendlyName: '' },
  [TH_TH]: { lang: 'ไทย', serverLang: 'th', friendlyName: 'Thai' },
  [DE_DE]: { lang: 'Deutsch', serverLang: 'de', friendlyName: 'German', code: DE_DE },
  [IS_IS]: { lang: 'Íslenska', serverLang: 'is', friendlyName: 'Icelandic', code: IS_IS },
  [NL_NL]: { lang: 'Nederlands', serverLang: 'nl', friendlyName: 'Dutch' },
  [FR_FR]: { lang: 'Français', serverLang: 'fr', friendlyName: 'French' },
  [JA_JP]: { lang: '日本語', serverLang: 'ja', friendlyName: 'Japanese' },
  [HI_IN]: { lang: 'हिन्दी', serverLang: 'hi', friendlyName: 'Hindi' },
  [VI_VN]: { lang: 'Tiếng Việt', serverLang: 'vi', friendlyName: 'Vietnamese' },
  [MS_MY]: { lang: 'Bahasa Malayu, بهاس ملايو', serverLang: 'ms', friendlyName: 'Malay' },
  [RU_RU]: { lang: 'Русский', serverLang: 'ru', friendlyName: 'Russian' },
  [UK_UK]: { lang: 'Українська', serverLang: 'uk', friendlyName: 'Ukranian' },
};

export const LANGSSIMPLE: Langs = {
  'EN-US': { lang: 'English', serverLang: 'en', friendlyName: '' },
  'ES-MX': { lang: 'Español', serverLang: 'es', friendlyName: 'Spanish' },
  'ZH-TW': { lang: '繁体中文', serverLang: 'zh-tw', friendlyName: 'Traditional Chinese' },
  'ZH-CN': { lang: '简体中文', serverLang: 'zh-cn', friendlyName: 'Simplified Chinese' },
  'KO-KR': { lang: '한국어', serverLang: 'ko', friendlyName: 'Korean' },
  'PT-BR': { lang: 'Português', serverLang: 'pt', friendlyName: 'Portuguese' },
  'ID-ID': { lang: 'Bahasa Indonesia', serverLang: 'id', friendlyName: 'Indonesian' },
  'TL-PH': { lang: 'Tagalog', serverLang: 'tl', friendlyName: '' },
  'TH-TH': { lang: 'ไทย', serverLang: 'th', friendlyName: 'Thai' },
  'DE-DE': { lang: 'Deutsch', serverLang: 'de', friendlyName: 'German' },
  'IS-IS': { lang: 'Íslenska', serverLang: 'is', friendlyName: 'Icelandic' },
  'NL-NL': { lang: 'Nederlands', serverLang: 'nl', friendlyName: 'Dutch' },
  'FR-FR': { lang: 'Français', serverLang: 'fr', friendlyName: 'French' },
  'JA-JP': { lang: '日本語', serverLang: 'ja', friendlyName: 'Japanese' },
  'HI-IN': { lang: 'हिन्दी', serverLang: 'hi', friendlyName: 'Hindi' },
  'VI-VN': { lang: 'Tiếng Việt', serverLang: 'vi', friendlyName: 'Vietnamese' },
  'MS-MY': { lang: 'Bahasa Malayu, بهاس ملايو', serverLang: 'ms', friendlyName: 'Malay' },
  'RU-RU': { lang: 'Русский', serverLang: 'ru', friendlyName: 'Russian' },
  'UK-UK': { lang: 'Українська', serverLang: 'uk', friendlyName: 'Ukranian' },
};

export const TRANSLATION_FILES = {
  COMMON: 'common',
  FIELDS: 'fields',
};

export const SUPPORTED_LANGUAGES_MAPPING: Record<string, Array<LangObject>> = {
  DE: [LANGS[EN_US], LANGS[DE_DE]],
  IS: [LANGS[EN_US], LANGS[IS_IS]],
};

export const COUNTRIES_SUPPORTED_TRANSLATION = ['DE', 'IS'];

export const FALLBACK_LANGUAGE = LANGS[EN_US];

export const DROPDOWN_TYPE_CHANGE_FIELDS = ['fld_up098e0010', 'fld_up098e0009', 'fld_up098e0023'];

export const MUTATION_KEYS_WITHOUT_SPINNER = [
  MutationKeysEnum.uploadDocuments,
  MutationKeysEnum.deleteDocuments,
  MutationKeysEnum.sendOTP,
  MutationKeysEnum.updateHostedApplication,
];

export const FIELDS_TYPES_WITH_COUNTRY_FLAG = [
  'country',
  'nationality',
  'phone_country_code',
  'principal_country',
];

export const ERROR_WITH_CHILD_SECTIONS_MESSAGE = 'ERROR_WITH_CHILD_SECTIONS';

export const SECTION_IGNORED_ERROR_MESSAGE = 'Section Ignored';

export const COUNTRY_CODES = {
  HK: 'HK',
  GB: 'GB',
  SG: 'SG',
  DE: 'DE',
};

export const COUNTRIES_WITH_PROVIDER = [COUNTRY_CODES.SG];

export type AllCountryCodes = keyof typeof COUNTRY_CODES;

export const EXTRACT_SECTION_TO_ROOT_TYPES = ['officer'];

export const MESSAGES_TYPES = {
  TRACKING_SESSION_ID: 'TRACKING_SESSION_ID',
};

export const PASSCODE_LENGTH = 6;

export const SPECIAL_APP_TYPES = {
  hk_incorporation: 'typ_aGtfaW5jb3Jwb3JhdGlvbg==',
  gb_incorporation: 'typ_Z2JfaW5jb3Jwb3JhdGlvbg==',
};

export const APP_TYPES_WITH_NO_WIZARD = [
  SPECIAL_APP_TYPES.hk_incorporation,
  SPECIAL_APP_TYPES.gb_incorporation,
];

export const SESSION_EXPIRATION_MODAL_MINUTES = 2;
export const SESSION_EXPIRATION_SAVE_APPLICATION_SECONDS = 20;

export const MAX_FILE_SIZE_IN_BYTES = 10 * 1000 * 1000;

export const TRANSLATION_URL_PREFIX =
  'https://cdn.rapyd.net/translations-automation/verify/production/';
export const FQ_QUESTIONNAIRE_URL_PREFIX = 'https://cdn.rapyd.net/verify_fq_files';
