import { useQuery } from 'react-query';

import { api } from '../../lib/api';
import { QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

type MCC = {
  id: number;
  value: string;
};

type BusinessIndustry = {
  id: number;
  value: string;
  sub_mcc: MCC[];
};

type SellType = {
  id: string;
  value: string;
};

export interface GetSellTypeAndMCCResponseData {
  business_industry: BusinessIndustry[];
  sell_type: SellType[];
}

async function getSellTypeAndMCC(): Promise<GenericApiResponse<GetSellTypeAndMCCResponseData>> {
  const response = await api.get<GenericApiResponse<GetSellTypeAndMCCResponseData>>(
    `verify/applications/list/field_lov?field_types[]=business_industry&field_types[]=sell_type`,
  );
  return response.data;
}

export const useGetSellTypeAndMCC = () => {
  return useQuery(QueryKeysEnum.sellTypeMCC, () => getSellTypeAndMCC());
};
