import { useTranslation } from 'react-i18next';

import { Badge as RapydBadge } from '@rapyd/react-ui-library';
import { BadgeProps as RapydBadgeProps } from '@rapyd/react-ui-library/dist/components/Badge';
import { ApplicationStatus } from '../../types/application';
import { ThemeFamilies } from '@rapyd/react-ui-library/dist/theme/families';
import { toTitleCase } from '../../utils/helpers';
import { ApplicationStatusEnum } from '../../data/enums';

interface BadgeProps extends RapydBadgeProps {
  status: ApplicationStatus;
}

const statusToThemeFamilyMap: Record<ApplicationStatus, ThemeFamilies> = {
  [ApplicationStatusEnum.new]: ThemeFamilies.INFO,
  [ApplicationStatusEnum.submitted]: ThemeFamilies.INFO,
  [ApplicationStatusEnum.pending_information]: ThemeFamilies.WARNING,
  [ApplicationStatusEnum.not_approved]: ThemeFamilies.ERROR,
  [ApplicationStatusEnum.approved]: ThemeFamilies.SUCCESS,
  [ApplicationStatusEnum.submitted_to_3rd_party]: ThemeFamilies.SUCCESS,
};

export default function Badge(props: BadgeProps) {
  const { t } = useTranslation();
  return (
    <RapydBadge themeFamily={statusToThemeFamilyMap[props.status]} {...props}>
      {toTitleCase(t(props.status))}
    </RapydBadge>
  );
}
