import { useMutation } from 'react-query';

import { api } from '../../lib/api';
import { blobToBase64 } from '../../utils/helpers';
import { MutationKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { BrowserFile, UploadDocumentsResponse } from './uploadDocuments';

interface UploadDocumentInParallelProps {
  applicationToken: string;
  sectionToken: string;
  fieldToken: string;
  file: BrowserFile;
}

async function uploadDocumentInParallel({
  applicationToken,
  fieldToken,
  file,
  sectionToken,
}: UploadDocumentInParallelProps): Promise<GenericApiResponse<UploadDocumentsResponse>> {
  const blob = await blobToBase64(file as any);

  const fileObj = {
    fileName: file.path,
    base64src: blob,
  };

  const response = await api.post<GenericApiResponse<UploadDocumentsResponse>>(
    `verify/applications/documents/${applicationToken}/${sectionToken}/${fieldToken}`,
    {
      file: fileObj,
      parallel: true,
    },
  );

  return response.data;
}

/**
 * Uploads a document to the backend in parallel, send it as base64
 * @param applicationToken
 * @param fieldToken
 * @param file
 * @param sectionToken
 * @returns UploadDocumentsResponse
 */
export const useUploadDocumentInParallel = () => {
  return useMutation(uploadDocumentInParallel, {
    mutationKey: MutationKeysEnum.uploadDocumentInParallel,
  });
};
