import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { IApplication } from '../../types/application';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { iframeMessages, sendParentMessage } from '../../lib/iframe';
import { GenericApiResponse } from '../../types/services';
import { useGenericModal } from '../../hooks/modals';

interface SubmitApplicationParams {
  application: IApplication;
}

async function submitApplication({
  application,
}: SubmitApplicationParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(
    `verify/applications/${application.token}/submit`,
    application,
  );
  return response.data;
}

export const useSubmitApplication = () => {
  const queryClient = useQueryClient();
  const genericModal = useGenericModal();

  return useMutation(submitApplication, {
    mutationKey: MutationKeysEnum.submitApplication,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      queryClient.invalidateQueries(QueryKeysEnum.hosted);
      sendParentMessage(iframeMessages.SUBMIT_FORM_SUCCESS);
    },
    onError: (error: any) => {
      const err =
        error?.response?.data?.status?.message || 'Something went wrong, please try again later.';
      genericModal.onOpen(() => {}, 'Oops...', err, 'Close');
    },
  });
};
