import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum, SessionStatusEnum } from '../../data/enums';
import { GetHostedAppResponseData } from '../hostedApplication/getHostedApplication';
import { GenericApiResponse } from '../../types/services';

interface ResendOTPParams {
  happToken: string;
}

interface ResendOTPResponseData {
  session_response: {
    session_token: string;
    session_status: SessionStatusEnum;
    round_number: number;
    attempt_number: number;
  };
  hosted_app: GetHostedAppResponseData | null;
}

async function resendOTP({
  happToken,
}: ResendOTPParams): Promise<GenericApiResponse<ResendOTPResponseData>> {
  const response = await api.post<GenericApiResponse<ResendOTPResponseData>>(
    '/verify/applications/hosted/otp',
    {
      happ_token: happToken,
      action: 'resend',
    },
  );
  return response.data;
}

export const useResendOTP = () => {
  const queryClient = useQueryClient();
  return useMutation(resendOTP, {
    mutationKey: MutationKeysEnum.resendOTP,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.otp, data);
    },
  });
};
