import { useWatch } from 'react-hook-form';
import { IField } from '../types/field';

interface UseWatchFieldsProps {
  fields: IField[];
}

const useWatchFields = ({ fields }: UseWatchFieldsProps) => {
  const fieldsRegisteredValues = fields.map(field => field.field_triggers_value);
  const watchedFields = useWatch({ name: fieldsRegisteredValues });

  const watchedFieldsWithToken = fields.map((field, index) => {
    return {
      [field.field_triggers_value]: watchedFields[index],
    };
  });

  const watchedFieldsWithFieldType = fields.map((field, index) => {
    return {
      [field.field_type]: watchedFields[index],
    };
  });

  const watchedFieldsWithTokenObject = watchedFieldsWithToken.reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    };
  }, {});

  const watchedFieldsWithFieldTypeObject = watchedFieldsWithFieldType.reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    };
  }, {});

  return {
    watchedFieldsArr: watchedFields,
    watchedFieldsObjByToken: watchedFieldsWithTokenObject,
    watchedFieldsObjByFieldType: watchedFieldsWithFieldTypeObject,
  };
};

export default useWatchFields;
