import { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalStorageItem } from '../../utils/helpers';
import { useGetHostedApplicationByContext } from '../../services';
import { Button, CustomLink, Typography } from '../core';
import { ClientStrings } from '../../translations/ClientStrings';
import { usePathParams } from '../../hooks';
import { RoutePaths } from '../../data/enums';
import OtpLayout from '../layout/OtpLayout';

const CreateOTPPage: React.FC<RouteComponentProps> = () => {
  const { happToken } = usePathParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { data: hostedApp, isError } = useGetHostedApplicationByContext();
  const localStorageToken = getLocalStorageItem(happToken!);
  const { phone_number, country, organization_details } = hostedApp?.data || {};
  const { merchant_customer_support: { email: merchant_customer_support_email } = { email: '' } } =
    organization_details || {};

  useEffect(() => {
    if (localStorageToken) {
      history.push(`${RoutePaths.OTP}?token=${happToken}`);
    }
  }, [localStorageToken, history, happToken]);

  const handleSendCode = () => {
    history.push(`${RoutePaths.OTP}?token=${happToken}`);
  };

  return (
    <OtpLayout
      title={ClientStrings.create_otp.title}
      subTitle={ClientStrings.create_otp.description}
    >
      <Typography.H5 className="text-primary-text my-10">
        {country} - *** *** **{phone_number?.slice(-4)}
      </Typography.H5>
      {merchant_customer_support_email && (
        <Typography.BODY2 className="text-primary-text mb-20 leading-normal">
          <div>{t(ClientStrings.create_otp.sub_text_question)}</div>
          <CustomLink
            className="text-primary-base"
            href={`mailto:${merchant_customer_support_email}`}
          >
            {t(ClientStrings.create_otp.sub_text_action_link)}
          </CustomLink>
        </Typography.BODY2>
      )}
      <div className="md:w-content w-screen">
        <Button className="mx-4 w-full md:w-fit" disabled={isError} onClick={handleSendCode}>
          {t(ClientStrings.create_otp.button_text)}
        </Button>
      </div>
    </OtpLayout>
  );
};

export default CreateOTPPage;
