import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Trashcan } from '../../assets';
import { useWatchFields, useClearSection, usePathParams } from '../../hooks';
import { ISection } from '../../types/section';
import {
  addMetaToApplicationSections,
  changeSectionInPath,
  getSectionFieldsByFieldType,
} from '../../utils/helpers';
import { ClientStrings } from '../../translations/ClientStrings';
import { useGetApplicationByContext, useSaveAndDeleteSection } from '../../services';
import { CustomIcon, Tooltip, Icon } from '../core';
import { IconName } from '@rapyd/react-ui-library/dist/components/Icon';
import { ErrorDotIcon } from '../../assets';
import { FormActionsEnum } from '../../data/enums';
import { useNoBeneficialOwnersModal } from '../../hooks/modals';

interface SingleChildSectionNavItemProps extends React.HTMLAttributes<HTMLDivElement> {
  sectionData: ISection;
  isCurrentSection: boolean;
  handleChangeSection: (section: string) => void;
  disabled?: boolean;
  isGrandChild?: boolean;
  isDisabledByStatus?: boolean;
  parentToken?: string;
}

const SingleChildSectionNavItem: React.FC<SingleChildSectionNavItemProps> = ({
  sectionData,
  isCurrentSection,
  handleChangeSection,
  disabled,
  isGrandChild,
  isDisabledByStatus,
  parentToken,
}) => {
  const watchingFields = getSectionFieldsByFieldType(sectionData, [
    'first_name',
    'last_name',
    'shareholder_type',
  ]);
  const { watchedFieldsObjByFieldType } = useWatchFields({ fields: watchingFields });
  const { mutateAsync: mutateSaveAndDeleteSection } = useSaveAndDeleteSection();
  const { sectionToken } = usePathParams();
  const { data: applicationData } = useGetApplicationByContext();
  const { clearSection } = useClearSection(sectionData, applicationData!.data);
  const history = useHistory();
  const { t } = useTranslation();
  const [title, setTitle] = useState(sectionData.name);

  const noBeneficialOwnersModal = useNoBeneficialOwnersModal();

  useLayoutEffect(() => {
    if (watchedFieldsObjByFieldType.first_name || watchedFieldsObjByFieldType.last_name) {
      if (watchedFieldsObjByFieldType.shareholder_type?.value === 'lv_oty00001') {
        setTitle(watchedFieldsObjByFieldType.first_name);
      } else {
        setTitle(
          `${watchedFieldsObjByFieldType?.first_name ?? ''} ${watchedFieldsObjByFieldType?.last_name ?? ''}`,
        );
      }
    } else {
      setTitle(sectionData.name);
    }
  }, [
    sectionData.name,
    watchedFieldsObjByFieldType.first_name,
    watchedFieldsObjByFieldType.last_name,
    watchedFieldsObjByFieldType.shareholder_type?.value,
  ]);

  const handleDeleteSection = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if ((sectionData?.child_sections && sectionData?.child_sections?.length > 0) || isGrandChild) {
      noBeneficialOwnersModal.onOpen(async () => {
        if (isGrandChild) {
          const appWithMeta = addMetaToApplicationSections(
            applicationData!.data,
            `${FormActionsEnum.REMOVE_SECTION}:${sectionData.token}`,
          );
          await mutateSaveAndDeleteSection({
            application: appWithMeta,
            section: sectionData,
          });
          const newPath = changeSectionInPath(parentToken!);
          history.push(newPath);
        } else if (sectionData?.child_sections && sectionData?.child_sections?.length > 0) {
          sectionData?.child_sections?.forEach(async childSection => {
            await mutateSaveAndDeleteSection({
              application: applicationData!.data,
              section: childSection,
            });
          });
          await clearSection();
          if (sectionToken !== sectionData?.token) {
            const newPath = changeSectionInPath(sectionData?.token!);
            history.push(newPath);
          }
        }
      });
    } else {
      await clearSection();
    }
  };

  const hideTrashCanButton = () => {
    const shouldHide = getSectionFieldsByFieldType(sectionData!, ['similar_to']);
    return !!shouldHide[0]?.value;
  };

  return (
    <StyledDiv
      data-testid={sectionData.token}
      isCurrentSection={isCurrentSection}
      key={sectionData.token}
      onClick={() => {
        handleChangeSection(sectionData.token);
      }}
      className={`my-5 flex h-12 w-full cursor-default items-center justify-center gap-x-1 rounded-lg border bg-white px-4 py-2 shadow-[0_0px_10px_2px_rgba(0,0,0,0.05)] hover:bg-violet-50 ${isCurrentSection && 'border-primary-base'} ${disabled ? 'pointer-events-none text-gray-300 opacity-60' : ''}`}
    >
      {(sectionData.error_message === 'Section Ignored' ||
        (!sectionData?.is_valid && !sectionData.error_message)) && (
        <button className="pointer-events-none">
          <Icon
            name={IconName.PLUS}
            className={`text-lg text-gray-400 hover:text-gray-800 ${disabled || isDisabledByStatus ? ' pointer-events-none text-gray-600' : ''}`}
          />
        </button>
      )}
      {sectionData.error_message && sectionData.error_message !== 'Section Ignored' && (
        <CustomIcon icon={<ErrorDotIcon className="h-5 w-5" />} />
      )}
      <p
        className={`h-5 flex-grow overflow-hidden text-ellipsis pr-2 text-start text-sm font-normal text-gray-800 ${disabled || isDisabledByStatus ? 'pointer-events-none text-gray-300' : ''}`}
      >
        {t(title)}
      </p>
      {sectionData.error_message === 'Section Ignored' && (
        <p
          className={`flex-grow text-end text-sm text-gray-500 ${disabled || isDisabledByStatus ? 'pointer-events-none text-gray-300' : ''}`}
        >
          {t(ClientStrings.sub_menu.optional)}
        </p>
      )}
      <div className="flex h-full items-center justify-center">
        {!hideTrashCanButton() &&
          !isDisabledByStatus &&
          isCurrentSection &&
          sectionData?.error_message !== 'Section Ignored' && (
            <button
              data-testid="trash"
              className="flex"
              onClick={e => {
                handleDeleteSection(e);
              }}
            >
              <Tooltip
                icon={
                  <Trashcan
                    className={`w-3 fill-gray-400 hover:fill-gray-800 ${disabled || isDisabledByStatus ? 'pointer-events-none text-gray-300' : ''} `}
                  />
                }
                side="right"
                text={ClientStrings.shareholder_control.mandatory_tooltip}
                delay={500}
              />
            </button>
          )}
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{ isCurrentSection: boolean }>`
  border: 1px solid transparent;
  border-color: ${({ isCurrentSection }) => (isCurrentSection ? '#8B5CF6' : 'transparent')};
`;

export default SingleChildSectionNavItem;
