import { create } from 'zustand';

interface useSessionTimerModalStore {
  isOpen: boolean;
  duration: number;
  onOpen: (duration: number) => void;
  onClose: () => void;
  preventShowingAgain: boolean;
}

export const useSessionTimerModal = create<useSessionTimerModalStore>(set => ({
  isOpen: false,
  onOpen: (duration: number) => set({ isOpen: true, preventShowingAgain: true, duration }),
  onClose: () => set({ isOpen: false, preventShowingAgain: true }),
  approveCallback: () => {},
  preventShowingAgain: false,
  duration: 0,
}));
