import { create } from 'zustand';

interface useGenericModalStore {
  isOpen: boolean;
  onOpen: (
    approveCallback: () => void,
    title: string,
    description: string,
    approveBtnText?: string,
  ) => void;
  onClose: () => void;
  approveCallback: () => void;
  title: string;
  description: string;
  approveBtnText?: string;
}

export const useGenericModal = create<useGenericModalStore>(set => ({
  isOpen: false,
  onOpen: (approveCallback, title, description, approveBtnText) =>
    set({ isOpen: true, approveCallback, title, description, approveBtnText }),
  onClose: () => set({ isOpen: false }),
  approveCallback: () => {},
  title: '',
  description: '',
  approveBtnText: '',
}));
