import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../store/store';
import { ClientStrings } from '../../translations/ClientStrings';
import { Button } from '../core';
import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';
import { unixToDateTimeString } from '../../utils/helpers';
import { isInIframe } from '../../lib/iframe';
import { useObserveQuery } from '../../hooks';
import { QueryKeysEnum } from '../../data/enums';

interface FooterProps extends React.HTMLAttributes<HTMLElement> {
  handleSubmit: (data: any) => void;
  handleSave: (any: any) => void;
  isDisabledByStatus: boolean;
}

const Footer: React.FC<FooterProps> = ({ handleSubmit, handleSave, isDisabledByStatus }) => {
  const { data: hostedAppData } = useObserveQuery(QueryKeysEnum.hosted);
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const { t } = useTranslation();
  const inIFrame = isInIframe();

  const { updated_at } = hostedAppData?.data?.application || {};
  const lastUpdate = updated_at ? unixToDateTimeString(updated_at) : 'loading...';
  const shouldDisplayButtons = !isDisabledByStatus || (isDisabledByStatus && !inIFrame);

  return (
    <footer
      data-testid="sticky_wrapper_content"
      className={`fixed bottom-0 left-0 right-0 z-[60] flex h-[82px] w-full items-center border-t border-gray-100 bg-white shadow-[0_35px_60px_22px_rgba(0,0,0,0.05)] 
      ${inIFrame && isDisabledByStatus ? 'justify-end px-20' : 'justify-center px-0'}`}
    >
      {inIFrame && (
        <p data-testid="last_update" className="text-xs text-gray-400">
          {t(ClientStrings.menu.last_updated)} {lastUpdate}
        </p>
      )}
      {shouldDisplayButtons && (
        <div className="flex w-[80%] justify-end gap-x-4">
          <Button
            className="w-36 min-w-fit"
            variant={ButtonVariants.OUTLINED}
            onClick={handleSave}
            disabled={isPreventSaving}
          >
            {t(ClientStrings.menu.save_changes)}
          </Button>
          <Button className="w-36 min-w-fit" onClick={handleSubmit} disabled={isPreventSaving}>
            {t(ClientStrings.menu.submit_form)}
          </Button>
        </div>
      )}
    </footer>
  );
};

export default Footer;
