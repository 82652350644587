import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { IApplication } from '../../types/application';
import { ISection } from '../../types/section';
import { MutationKeysEnum, QueryKeysEnum, SectionTypesEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { useGenericModal } from '../../hooks/modals';

interface SaveSectionParams {
  application: IApplication;
  section: ISection;
}

const SHAREHOLDER_SECTION_TYPES = [
  SectionTypesEnum.shareholder,
  SectionTypesEnum.ceo,
  SectionTypesEnum.officer,
];

const DIRECTOR_SECTION_TYPES = [SectionTypesEnum.director];

async function saveSection({
  application,
  section,
}: SaveSectionParams): Promise<GenericApiResponse<IApplication>> {
  let sectionType = section.section_type;
  if (SHAREHOLDER_SECTION_TYPES.includes(section.section_type as any)) {
    sectionType = SectionTypesEnum.shareholders_control;
  } else if (DIRECTOR_SECTION_TYPES.includes(section.section_type as any)) {
    sectionType = SectionTypesEnum.directors_control;
  }

  const response = await api.post<GenericApiResponse<IApplication>>(
    `verify/applications/${application.token}/save/section/${sectionType}`,
    application,
  );
  return response.data;
}

export const useSaveSection = () => {
  const queryClient = useQueryClient();
  const genericModal = useGenericModal();

  return useMutation(saveSection, {
    mutationKey: MutationKeysEnum.saveSection,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      const hostedAppData = queryClient.getQueryData(QueryKeysEnum.hosted) as any;
      if (hostedAppData) {
        const updatedHostedAppData = {
          ...hostedAppData,
          data: { ...hostedAppData.data, application: data.data },
        };
        queryClient.setQueryData(QueryKeysEnum.hosted, updatedHostedAppData);
      }
    },
    onError: (error: any) => {
      const err =
        error?.response?.data?.status?.message || 'Something went wrong, please try again later.';
      genericModal.onOpen(() => {}, 'Oops...', err, 'Close');
    },
  });
};
