import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { IApplication } from '../../types/application';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

interface UpdateHostedAppResponseData {
  token: string;
  application_token: string | null;
  country: string;
  rapyd_entity_token: string;
  client_reference_id: string;
  cancel_url: string;
  complete_url: string;
  phone_number: string;
  application_level: 1 | 2;
  sell_type: string;
  business_industry: string | null;
  application_type: ApplicationType;
  application?: IApplication;
}

interface ApplicationType {
  application_type: string;
  entity_type: string;
  country: string;
}

interface UpdateHostedApplicationParams {
  application_type: string;
  business_industry: string;
  country: string;
  sell_type: string;
  hosted_app: string;
}

async function updateHostedApplication({
  application_type,
  business_industry,
  country,
  hosted_app,
  sell_type,
}: UpdateHostedApplicationParams): Promise<GenericApiResponse<UpdateHostedAppResponseData>> {
  const response = await api.put<GenericApiResponse<UpdateHostedAppResponseData>>(
    `/verify/applications/hosted/update`,
    {
      application_type,
      business_industry,
      country,
      hosted_app,
      sell_type,
    },
  );
  return response.data;
}

export function useUpdateHostedApplication() {
  const queryClient = useQueryClient();
  return useMutation(updateHostedApplication, {
    mutationKey: MutationKeysEnum.updateHostedApplication,
    onSuccess: async data => {
      queryClient.setQueryData(QueryKeysEnum.hosted, data);
    },
  });
}
