import ReCaptcha from 'react-google-recaptcha';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetConfig, useValidateRecaptcha } from '../services';
import { ErrorMessage } from './core';
import { RootState } from '../store/store';
import { setIsRecaptchVerified } from '../store/slices/globalSlice';
import { config } from '../lib/config';

declare global {
  interface Window {
    recaptchaOptions: {
      useRecaptchaNet: boolean;
    };
    grecaptcha: any;
  }
}

interface ReCAPTCHAProps extends React.HTMLAttributes<HTMLDivElement> {}

const ReCAPTCHA: React.FC<ReCAPTCHAProps> = () => {
  const { data: configData } = useGetConfig();
  const { mutateAsync: validateRecaptcha } = useValidateRecaptcha();
  const isRecaptchaVerified = useSelector((state: RootState) => state.global.isRecaptchVerified);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [error, setError] = useState('');
  const [_, setToken] = useState<null | string>(null);

  const onRecaptchaChanged = async (token: string) => {
    try {
      setError('');
      const response = await validateRecaptcha({ token });
      if (response?.data === true) {
        setToken(token);
        if (!isRecaptchaVerified) {
          dispatch(setIsRecaptchVerified(true));
        }
      }
    } catch (err: any) {
      setError(err?.message || 'Something went wrong');
    }
  };

  const onRecaptchaExpired = () => {
    setToken(null);
    setError('');
  };

  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };

  const grecaptchaObject = window.grecaptcha;

  return (
    <div className="flex flex-col items-center justify-center pt-8">
      {!configData?.google_recaptcha_site_key ||
      configData?.flags?.enable_google_recaptcha === false ? null : (
        <>
          <ReCaptcha
            ref={ref}
            sitekey={configData?.google_recaptcha_site_key!}
            onChange={token => {
              onRecaptchaChanged(token!);
            }}
            onExpired={onRecaptchaExpired}
            grecaptcha={grecaptchaObject}
            hl={configData?.language || 'en'}
          />
          <ErrorMessage message={error} />
        </>
      )}
    </div>
  );
};

export default ReCAPTCHA;
