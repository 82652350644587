import React from 'react';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ISection } from '../../types/section';
import {
  mergeSectionFormDataWithApplicationGeneric,
  shouldHideEmptySection,
  parseNavSections,
  addMetaToApplicationSections,
  changeSectionInPath,
  getNavigationSectionData,
} from '../../utils/helpers';
import ChildSectionsNav from './ChildSectionsNav';
import { useSaveApplication, useSaveSection } from '../../services';
import { RootState } from '../../store/store';
import { useGetApplicationByContext } from '../../services';
import { useGetSection, usePathParams } from '../../hooks';
import {
  ApplicationStatusEnum,
  FormActionsEnum,
  RoutePaths,
  SectionTypesEnum,
} from '../../data/enums';
import NavGoBackButton from './NavGoBackButton';
import SingleSectionNavItem from './SingleSectionNavItem';

const SectionsNav: React.FC = () => {
  const { data: applicationData } = useGetApplicationByContext();
  const isDisabledByStatus = [
    ApplicationStatusEnum.approved,
    ApplicationStatusEnum.not_approved,
    ApplicationStatusEnum.submitted,
  ].includes(applicationData?.data?.status as ApplicationStatusEnum);
  const { mutateAsync: mutateSaveSection } = useSaveSection();
  const { mutateAsync: mutateSaveApplication } = useSaveApplication();
  const { getValues, getFieldState } = useFormContext();
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const history = useHistory();
  const { sectionToken, happToken } = usePathParams();
  const currentSection = useGetSection(sectionToken!);

  const sections = parseNavSections(
    applicationData?.data?.sections! || {},
    applicationData?.data?.application_type!,
  );

  const handleChangeSection = async (newSectionToken: string) => {
    if (newSectionToken === sectionToken) return;
    const { isDirty } = getFieldState(sectionToken!);
    if (isDirty) {
      const sectionValues = getValues(sectionToken!);
      const updatedApp = mergeSectionFormDataWithApplicationGeneric(
        applicationData!.data,
        sectionValues,
        sectionToken!,
      );
      await mutateSaveSection({
        application: updatedApp,
        section: currentSection!,
      });
    }
    const newPath = changeSectionInPath(newSectionToken);
    window.scrollTo(0, 0);
    history.push(newPath);
  };

  const handleNavigateToWizard = async () => {
    const sectionFieldValues = getValues(sectionToken!);
    const updatedApp = mergeSectionFormDataWithApplicationGeneric(
      applicationData!.data,
      sectionFieldValues,
      sectionToken!,
    );
    const updatedAppWithMeta = addMetaToApplicationSections(
      updatedApp,
      FormActionsEnum.SAVE_and_SWITCH,
    );
    await mutateSaveApplication({
      application: updatedAppWithMeta,
    });
    history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
  };

  return (
    <div className="flex flex-col pt-7">
      <NavGoBackButton
        data-testid="go_back"
        isDisabledByStatus={isDisabledByStatus}
        onClick={handleNavigateToWizard}
      />
      {sections?.map((section: ISection) => {
        if (shouldHideEmptySection(section)) return null;
        const {
          displayName,
          navToken,
          sectionPercentage,
          isCurrentSection,
          sectionIcon,
          sectionPercentageText,
          showChildSections,
        } = getNavigationSectionData(section, applicationData!.data, sectionToken!);
        return (
          <React.Fragment key={section?.token}>
            <SingleSectionNavItem
              sectionToken={section?.token}
              sectionPercentage={sectionPercentage}
              sectionPercentageText={sectionPercentageText}
              handleChangeSection={handleChangeSection}
              isCurrentSection={isCurrentSection}
              isDisabledByStatus={isDisabledByStatus}
              navToken={navToken}
              sectionDisplayName={displayName}
              sectionIcon={sectionIcon}
            />
            {isCurrentSection && showChildSections && (
              <ChildSectionsNav
                isDisabledByStatus={isDisabledByStatus}
                disabled={isPreventSaving}
                childSections={
                  section?.child_sections?.filter(
                    (childSection: any) =>
                      childSection.section_type === SectionTypesEnum.shareholder ||
                      childSection.section_type === SectionTypesEnum.director,
                  )!
                }
                handleChangeSection={handleChangeSection}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SectionsNav;
