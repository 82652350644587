import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { IApplication } from '../../types/application';

export interface SwitchApplicationTypeParams {
  application_token: string;
  app_type: string;
  business_industry: string;
  sell_type: string;
}

async function switchApplicationType({
  application_token,
  app_type,
  business_industry,
  sell_type,
}: SwitchApplicationTypeParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(
    `verify/applications/${application_token}/types/switch`,
    {
      app_type,
      business_industry,
      sell_type,
    },
  );
  return response.data;
}

export const useSwitchApplicationType = () => {
  const queryClient = useQueryClient();
  return useMutation(switchApplicationType, {
    mutationKey: MutationKeysEnum.switchApplicationType,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      queryClient.invalidateQueries(QueryKeysEnum.hosted);
      queryClient.invalidateQueries(QueryKeysEnum.application);
    },
  });
};
