import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { IApplication } from '../../types/application';

interface CopySectionParams {
  applicationToken: string;
  sectionToken: string;
  copy_from: string;
}

async function copySection({
  applicationToken,
  sectionToken,
  copy_from,
}: CopySectionParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.put<GenericApiResponse<IApplication>>(
    `verify/applications/${applicationToken}/copy/section/${sectionToken}`,
    {
      copy_from_section: copy_from,
    },
  );
  return response.data;
}

export const useCopySection = () => {
  const queryClient = useQueryClient();
  return useMutation(copySection, {
    mutationKey: MutationKeysEnum.copySection,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      const hostedAppData = queryClient.getQueryData(QueryKeysEnum.hosted) as any;
      if (hostedAppData) {
        const updatedHostedAppData = {
          ...hostedAppData,
          data: { ...hostedAppData.data, application: data.data },
        };
        queryClient.setQueryData(QueryKeysEnum.hosted, updatedHostedAppData);
      }
    },
  });
};
