import { useTranslation } from 'react-i18next';

import { cn } from '../../utils/helpers';

interface ErrorMessageProps extends React.HTMLAttributes<HTMLParagraphElement> {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, className }) => {
  const { t } = useTranslation();

  if (!message) return null;

  return <p className={cn('pt-1.5 text-[13px] text-[#F25050]', className)}>{t(message)}</p>;
};

export default ErrorMessage;
