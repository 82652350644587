import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { IApplication } from '../../types/application';

export interface CreateApplicationParams {
  application_type: string;
  authorized_user_email: string;
  business_industry: string;
  country: string;
  rapyd_entity_token: string;
  sell_type: string;
}

async function createApplication({
  application_type,
  authorized_user_email,
  business_industry,
  country,
  rapyd_entity_token,
  sell_type,
}: CreateApplicationParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(`verify/applications`, {
    application_type,
    authorized_user_email,
    business_industry,
    country,
    rapyd_entity_token,
    sell_type,
  });
  return response.data;
}

export const useCreateApplication = () => {
  const queryClient = useQueryClient();
  return useMutation(createApplication, {
    mutationKey: MutationKeysEnum.createApplication,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      queryClient.invalidateQueries(QueryKeysEnum.hosted);
    },
  });
};
