import axios, { AxiosInstance } from 'axios';
import { QueryClient } from 'react-query';

import {
  getDeviceFingerPrint,
  getDeviceOperatingSystem,
  getLocaleDeviceTimeZone,
  getSearchParam,
} from '../utils/helpers';
import { ErrorMessagesEnum } from '../data/enums';

const api: AxiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' && window?.location?.origin === 'http://localhost:3000'
      ? 'http://localhost:1342/v1'
      : '/v1',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    kyb_token: '',
    device_fingerprint: '',
    device_timezone: '',
    device_os: '',
    session_token: null,
  },
});

api.interceptors.request.use(config => {
  const { headers } = config;
  const localStorageToken = localStorage.getItem(getSearchParam('token')!);
  if (localStorageToken) {
    headers.session_token = localStorageToken;
  } else {
    headers.session_token = null;
  }
  return config;
});

api.interceptors.request.use(config => {
  const { headers } = config;
  if (!headers.kyb_token) {
    headers.kyb_token = getSearchParam('token');
  }
  if (!headers.device_timezone) {
    headers.device_timezone = getLocaleDeviceTimeZone();
  }
  if (!headers.device_os) {
    headers.device_os = getDeviceOperatingSystem();
  }
  return config;
});

async function setDeviceFingerPrint(config: any) {
  const { headers } = config;
  headers.device_fingerprint = await getDeviceFingerPrint();
  return config;
}

api.interceptors.request.use(setDeviceFingerPrint);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error?.response?.data?.status?.error_code === ErrorMessagesEnum.ERROR_OTP_SESSION_NOT_FOUND ||
      error?.response?.data?.status?.error_code === ErrorMessagesEnum.UNAUTHORIZED_API_CALL
    ) {
      window.location.href = 'otp?token=' + getSearchParam('token');
    } else if (
      error?.response?.data?.status?.error_code === ErrorMessagesEnum.UNAUTHORIZED_API_CALL
    ) {
      window.location.href = '/verify/session-error';
    }
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});

export { api, queryClient };
