import dayjs from 'dayjs';
import { z } from 'zod';
import { FieldInputTypesEnum } from '../data/enums';
import {
  CountryPostalCodes,
  DefaultPostalCodeRegex,
  FieldRanges,
  FieldValidationTypes,
  InListValidationFields,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_TEXT_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
  RejectReason,
  defaultRequiredFieldError,
  maxLengthValidationError,
  requiredFieldError,
} from '../data/validations';
import { IApplication } from '../types/application';
import { IElement } from '../types/element';
import { IField } from '../types/field';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Trigger } from '../store/slices/triggersSlice';

const getRangeErrorMessage = (min: number, max: number, count: number | undefined) => {
  return `Minimum length: ${min}, Count: ${count}/${max}`;
};

export const isBetween = (field: IField) => {
  const { min, max } = field.input_validation;
  return z
    .string()
    .nullable()
    .superRefine((val, ctx) => {
      if (!val) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredFieldError(field.name),
        });
      }
      const value = Number(val);
      if (!value || value < min || value > max) {
        if (
          min === FieldRanges.OWNERSHIP_PERCENTAGE.MIN &&
          max === FieldRanges.OWNERSHIP_PERCENTAGE.MAX
        ) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: RejectReason.INVALID_RANGE_25_100,
          });
        }
        if (min === FieldRanges.MCC.MIN && max === FieldRanges.MCC.MAX) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: RejectReason.INVALID_RANGE_0_10000,
          });
        }
      }
    });
};

export const isValidRegex = (field: IField) => {
  return z
    .string()
    .nullable()
    .superRefine((val, ctx) => {
      if (!val) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredFieldError(field.name),
        });
      }
      const regexTemplate = field.input_validation?.value;
      const regex = new RegExp(regexTemplate);
      const invalidValue = !regex.test(val!);
      if (invalidValue) {
        switch (field.field_type) {
          case 'statement_descriptor':
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: RejectReason.STATEMENT_DESCRIPTOR_ERROR_MESSAGE,
            });
          case 'business_nature':
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: getRangeErrorMessage(
                field.input_validation?.min,
                field.input_validation?.max,
                val?.length,
              ),
            });
          default:
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: RejectReason.INVALID_VALUE,
            });
        }
      }
    });
};

export const isValidURL = () => {
  return z.string().superRefine((val, ctx) => {
    if (val.length > MAX_TEXT_LENGTH) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: maxLengthValidationError(MAX_TEXT_LENGTH),
      });
    }
    const validPrefixURL = val.startsWith('http://') || val.startsWith('https://');
    if (!validPrefixURL) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: RejectReason.INVALID_WEBSITE_PROTOCOL,
      });
    }
    const urlRegExp = new RegExp(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    );
    if (!urlRegExp.test(val)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: RejectReason.INVALID_WEBSITE_ADDRESS,
      });
    }
  });
};

export const isValidDate = (dateString: string) => {
  dayjs.extend(customParseFormat);
  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!dateFormatRegex.test(dateString)) {
    return false;
  }

  const parsedDate = dayjs(dateString, 'DD/MM/YYYY', true);
  return parsedDate.isValid();
};

export const isOver18 = (dateString: string) => {
  if (isValidDate(dateString)) {
    const parseDate = dayjs(dateString, 'DD/MM/YYYY', true);
    return parseDate.isBefore(dayjs().subtract(18, 'years'));
  } else return false;
};

export const isAfter1900 = (dateString: string) => {
  if (isValidDate(dateString)) {
    const parseDate = dayjs(dateString, 'DD/MM/YYYY', true);
    return parseDate.isAfter(dayjs('1900-01-01'));
  } else return false;
};

export const buildZodSchema = (application: IApplication, enabled_frontend_validation = false) => {
  if (!enabled_frontend_validation) {
    return z.object({});
  }
  let schema: any = {};
  let childSchema: any = {};
  Object.values(application.sections).forEach(section => {
    if (section[0].child_sections?.length) {
      section[0].child_sections.forEach(childSection => {
        childSchema[childSection.token] = buildElementsSchema(childSection.data_elements);
      });
    }
    schema[section[0].token] = buildElementsSchema(section[0].data_elements);
  });
  return z.object({ ...schema, ...childSchema });
};

const buildElementsSchema = (elements: IElement[]) => {
  let schema: any = {};

  elements.forEach(element => {
    switch (element.element_type) {
      case 'phone': {
        schema[element.element_type] = buildZodElementSchema(element).refine(
          data => {
            const { phone_country_code, phone_number } = data || {};
            if (
              !phone_country_code ||
              !phone_number ||
              phone_number?.length > MAX_PHONE_NUMBER_LENGTH
            ) {
              return false;
            }

            try {
              const phoneUtil = PhoneNumberUtil.getInstance();
              const fullPhone = phoneUtil?.parseAndKeepRawInput(
                phone_country_code?.label?.toString() + phone_number?.toString(),
              );
              if (
                phone_number?.length > MIN_PHONE_NUMBER_LENGTH &&
                !phoneUtil?.isPossibleNumber(fullPhone)
              ) {
                return false;
              }
            } catch (err) {
              return false;
            }
            return true;
          },
          {
            message: RejectReason.INVALID_PHONE_NUMBER,
            path: ['phone_number'],
          },
        );

        break;
      }
      case 'address': {
        schema[element.element_type] = buildZodElementSchema(element).refine(
          data => {
            const { country, postal_code } = data || {};
            const country_code = country?.value || '';
            const postalCodeRegex = CountryPostalCodes[country_code] || DefaultPostalCodeRegex;
            const regex = new RegExp(postalCodeRegex);
            if (!regex.test(postal_code)) {
              return false;
            } else {
              return true;
            }
          },
          {
            message: RejectReason.INVALID_POSTAL_CODE,
            path: ['postal_code'],
          },
        );
        break;
      }
      default: {
        schema[element.element_type] = buildZodElementSchema(element);
        break;
      }
    }
  });
  return z.object(schema);
};

const isValidFile = (field: IField) => {
  return z
    .array(
      z.object({
        is_deleted: z.boolean(),
        origin_name: z.string().min(1),
        token: z.string().min(1),
      }),
    )
    .refine(data => data !== null && data?.filter(file => !file.is_deleted).length > 0, {
      message: requiredFieldError(field.name),
    });
};

const isInList = (field: IField) => {
  if (field.input_type === FieldInputTypesEnum.dropdown) {
    return z
      .object(
        {
          label: z.string().min(1),
          value: z.string().min(1).or(z.number()),
        },
        {
          invalid_type_error:
            field.field_type === FieldInputTypesEnum.phone_country_code
              ? defaultRequiredFieldError
              : requiredFieldError(field.name),
        },
      )
      .or(z.string())

      .nullable()
      .refine(data => data !== null, {
        message: requiredFieldError(field.name),
      });
  }
  return isValidMultiSelect(field);
};

const isValidMultiSelect = (field: IField) => {
  return z
    .array(
      z.object(
        {
          label: z.string().min(1),
          value: z.string().min(1).or(z.number()),
        },
        { invalid_type_error: requiredFieldError(field.name) },
      ),
    )
    .or(z.string())
    .nullable()
    .refine(data => data !== null && data?.length > 0, {
      message: requiredFieldError(field.name),
    });
};

const buildZodElementSchema = (dataElement: IElement) => {
  let schema: any = {};

  dataElement?.fields?.forEach(field => {
    if (field.is_required && !field.is_read_only) {
      switch (field.input_validation?.validation_type) {
        case FieldValidationTypes.RANGE_INT: {
          schema[field.field_type] = isBetween(field);
          break;
        }
        case FieldValidationTypes.EMAIL: {
          schema[field.field_type] = z
            .string()
            .email({ message: RejectReason.INVALID_EMAIL })
            .nullable()
            .refine(data => data !== null, {
              message: RejectReason.INVALID_EMAIL,
            });
          break;
        }
        case FieldValidationTypes.IS_NUMBER: {
          schema[field.field_type] = z
            .string()
            .regex(/^[0-9]{0,}$/, { message: RejectReason.INVALID_NUMBER });
          break;
        }
        case FieldValidationTypes.REGEX: {
          schema[field.field_type] = isValidRegex(field);
          break;
        }
        case FieldValidationTypes.IS_URL: {
          schema[field.field_type] = isValidURL();
          break;
        }
        case FieldValidationTypes.IS_OVER_18: {
          schema[field.field_type] = z.string().superRefine((val, ctx) => {
            if (!val) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: requiredFieldError(field.name),
              });
            }

            if (!isValidDate(val)) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: RejectReason.INVALID_DATE,
              });
            }

            if (!isOver18(val)) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: RejectReason.NOT_OVER_18,
              });
            }

            if (!isAfter1900(val)) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: RejectReason.NOT_AFTER_1900,
              });
            }
          });
          break;
        }
        case FieldValidationTypes.IS_VALID_DOB: {
          schema[field.field_type] = z
            .string()
            .nullable()
            .superRefine((val, ctx) => {
              if (!val) {
                return ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: requiredFieldError(field.name),
                });
              }

              if (!isValidDate(val)) {
                return ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: RejectReason.INVALID_DATE,
                });
              }

              if (!isOver18(val)) {
                return ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: RejectReason.NOT_OVER_18,
                });
              }

              if (!isAfter1900(val)) {
                return ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: RejectReason.NOT_AFTER_1900,
                });
              }
            });
          break;
        }
        case FieldValidationTypes.IS_DATE: {
          schema[field.field_type] = z
            .string()
            .nullable()
            .refine(val => val !== null && isValidDate(val), {
              message: RejectReason.INVALID_DATE,
            });
          break;
        }
        case FieldValidationTypes.HAS_FILE: {
          schema[field.field_type] = isValidFile(field);
          break;
        }
        case FieldValidationTypes.IN_LIST: {
          schema[field.field_type] = isInList(field);
          break;
        }
        case FieldValidationTypes.CONDITIONALLY_REQUIRED: {
          schema[field.field_type] = z
            .any()
            .nullable()
            .refine(val => val !== null, {
              message: requiredFieldError(field.name),
            });
          break;
        }
        default:
          if (InListValidationFields.includes(field.input_type)) {
            schema[field.field_type] = isInList(field);
          } else {
            schema[field.field_type] = z
              .string()
              .or(z.number())
              .nullable()
              .superRefine((val, ctx) => {
                if (val === null || val?.toString()?.length === 0) {
                  return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: requiredFieldError(field.name),
                  });
                }

                //TODO: remove this IF in 1.26.0
                if (field?.field_type === 'business_desc' && val?.toString()?.length > 1500) {
                  return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: maxLengthValidationError(1500),
                  });
                }
                if (
                  field?.input_validation &&
                  field.input_validation?.length_limit &&
                  val?.toString()?.length > field.input_validation.length_limit
                ) {
                  return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: maxLengthValidationError(field.input_validation?.length_limit),
                  });
                }
                //TODO: remove field?.field_type !== 'business_desc' in 1.26.0
                if (
                  val?.toString()?.length > MAX_TEXT_LENGTH &&
                  field?.field_type !== 'business_desc'
                ) {
                  return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: maxLengthValidationError(MAX_TEXT_LENGTH),
                  });
                }
              });
          }
          break;
      }
    } else {
      schema[field.field_type] = z.any().optional();
    }
  });

  return z.object(schema);
};

export const compareTriggerValues = (trigger: Trigger, comparableValue: any) => {
  const isDifferent =
    trigger?.is_required !== comparableValue?.is_required ||
    trigger?.is_shown !== comparableValue?.is_shown ||
    trigger?.name !== comparableValue?.name ||
    JSON.stringify(trigger?.list_of_values) !== JSON.stringify(comparableValue?.list_of_values) ||
    trigger?.is_read_only !== comparableValue?.is_read_only ||
    trigger?.description !== comparableValue?.description;

  return isDifferent;
};
