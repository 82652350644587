import { useEffect } from 'react';

const WelcomePage: React.FC = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://dashboard.rapyd.net/login');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      Redirecting to Rapyd Dashboard...
    </div>
  );
};

export default WelcomePage;
