import { BaseElementProps } from '../../../types/element';
import { IField } from '../../../types/field';
import FieldHandler from '../../FieldHandler';
import { cn } from '../../../utils/helpers';

interface PhoneFieldProps extends BaseElementProps, React.HTMLAttributes<HTMLDivElement> {}

const PhoneField: React.FC<PhoneFieldProps> = ({
  elementData,
  register,
  setValue,
  control,
  triggersState,
  sectionData,
  isDisabledByStatus,
}) => {
  return (
    <div key={elementData.token} className="flex flex-col place-items-start gap-x-7 sm:flex-row">
      {elementData?.fields.map((field: IField) => (
        <div
          key={field.token}
          className={cn(
            'w-full',
            field.input_type === 'dropdown' && 'text-ellipsis whitespace-nowrap sm:w-[60%]',
          )}
        >
          <FieldHandler
            fieldData={field}
            {...{
              control,
              register,
              setValue,
              elementData,
              sectionData,
              triggersState,
              isDisabledByStatus,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default PhoneField;
