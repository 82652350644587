import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { EN_US, LANGS, LANGSSIMPLE, TRANSLATION_URL_PREFIX } from '../data/constants';
import { isInIframe } from './iframe';
import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from './config';

export const initTranslations = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    nsSeparator: '~',
    lng: setInitialLanguageValue()!,
    ns: ['translation'],
    debug: false,
    resources: {},
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b', 'Bold'],
    },
  });
};

export const setInitialLanguageValue = () => {
  const lang = getLocalStorageOrBrowserLanguage();
  changeLanguage(lang);
};

export const changeLanguage = async (language: any) => {
  try {
    const config = await axios({
      url: `/config.json`,
      method: 'get',
    }).then(data => data.data);
    const translationURLPrefix = config?.translations_url_prefix || TRANSLATION_URL_PREFIX;
    const supportedLanguage = getSupportedLanguage(language);
    const requestURLPrefix = `${translationURLPrefix}${supportedLanguage}`;
    const requestHeaders = {
      'Cache-Control': 'no-cache',
    };
    const translationsJSON = await axios
      .get(`${requestURLPrefix}/translations.json`, { headers: requestHeaders })
      .then(({ data }) => data);

    i18n.addResourceBundle(language, 'translation', translationsJSON.translation, true, true);
    localStorage.setItem('language', supportedLanguage);
    await i18n.changeLanguage(language);
    return true;
  } catch (error) {
    localStorage.setItem('language', EN_US);
    await i18n.changeLanguage('en');
    return false;
  }
};

export const useChangeLanguage = () => {
  const changeLanguageMutation = useMutation((language: any) => {
    return axios({
      url: `/config.json`,
      method: 'get',
    })
      .then(data => data.data)
      .then(config => {
        const translationURLPrefix = config?.translations_url_prefix || TRANSLATION_URL_PREFIX;
        const supportedLanguage = getSupportedLanguage(language);
        const requestURLPrefix = `${translationURLPrefix}${supportedLanguage}`;
        const requestHeaders = {
          'Cache-Control': 'no-cache',
        };
        return axios
          .get(`${requestURLPrefix}/translations.json`, { headers: requestHeaders })
          .then(({ data }) => {
            i18n.addResourceBundle(language, 'translation', data.translation, true, true);
            localStorage.setItem('language', supportedLanguage);
            return i18n.changeLanguage(language);
          });
      });
  });

  return {
    changeLanguage: changeLanguageMutation.mutateAsync,
    isLoading: changeLanguageMutation.isLoading,
    isError: changeLanguageMutation.isError,
    error: changeLanguageMutation.error,
  };
};

export const getBrowserLanguage = (() => {
  const language = window.navigator.language;
  return language;
})();

export const getLocalStorageLanguage = () => {
  return localStorage.getItem('language');
};

export const getLocalStorageOrBrowserLanguage = () => {
  const lang = getLocalStorageLanguage();
  if (lang) {
    return lang;
  }
  if (getBrowserLanguage) {
    return getSupportedLanguage(getBrowserLanguage);
  }

  return EN_US;
};

const getSupportedLanguage = (lang: string) => {
  if (LANGS[lang]) {
    return lang;
  }

  if (LANGS[serverLang2Client(lang)]) {
    return serverLang2Client(lang);
  }

  return EN_US;
};

const getServerLangClientMapping = (langs: any) => {
  return Object.keys(langs).reduce((acc, langKey) => {
    const { serverLang } = langs[langKey];
    if (!serverLang) return acc;

    return {
      ...acc,
      [serverLang]: langKey,
    };
  }, {});
};

export const serverLang2Client = (serverLang: string) => languagesMapping[serverLang];

const languagesMapping: any = getServerLangClientMapping(LANGS);

export const getLangObjectFromCode = (langCode: string = 'en-US') => {
  const upperCaseCode = langCode.toUpperCase();
  return LANGSSIMPLE[upperCaseCode];
};

export default i18n;
