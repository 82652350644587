import { useQuery } from 'react-query';
import axios from 'axios';

import { QueryKeysEnum } from '../../data/enums';

export interface GetConfigResponseData {
  role_name: string;
  language: string;
  backend_base_url_active_line: string;
  backend_base_url_passive_line: string;
  translations_url_prefix: string;
  fq_questionnaire_url_prefix: string;
  google_recaptcha_site_key: string;
  flags: {
    enable_google_recaptcha: boolean;
    enable_frontend_validation: boolean;
    enable_parallel_file_upload: boolean;
  };
}

async function getConfig(): Promise<GetConfigResponseData> {
  const response = await axios.get<GetConfigResponseData>(`/config.json`);
  return response.data;
}

export const useGetConfig = () => {
  return useQuery(QueryKeysEnum.config, () => {
    return getConfig();
  });
};
