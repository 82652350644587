import { create } from 'zustand';

interface useSubmitApplicationModalStore {
  isOpen: boolean;
  onOpen: (approveCallback: () => void) => void;
  onClose: () => void;
  approveCallback: () => void;
}

export const useSubmitApplicationModal = create<useSubmitApplicationModalStore>(set => ({
  isOpen: false,
  onOpen: approveCallback => {
    set({ isOpen: true, approveCallback });
    setTimeout(() => (document.body.style.pointerEvents = ''), 0); // override Dialog's pointerEvents (radix primitives) - allowing opening 3rd party modals
  },
  onClose: () => set({ isOpen: false }),
  approveCallback: () => {},
}));
