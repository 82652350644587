import { cn } from '../../utils/helpers';

interface CustomIconProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
}

const CustomIcon: React.FC<CustomIconProps> = ({ icon, className }) => {
  return <div className={cn('flex items-center justify-center', className)}>{icon}</div>;
};

export default CustomIcon;
