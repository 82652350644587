export const isInIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const sendParentMessage = (type: string, data: object = {}): void => {
  const message = { type, data };
  window.parent.postMessage(message, '*');
};

export const iframeMessages = {
  FORM_LOAD_FAIL: 'ERROR',
  SUBMIT_FORM_SUCCESS: 'SUCCESS',
};
