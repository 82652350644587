import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { isInIframe } from './lib/iframe';
import { useSendOTK } from './services/otk/sendOTK';
import { returnFirstSectionToken } from './utils/helpers';
import { QueryKeysEnum, RoutePaths } from './data/enums';
import { useObserveQuery, usePathParams } from './hooks';

function App() {
  const { happToken, otk } = usePathParams();
  const history = useHistory();
  const { mutateAsync: sendOTK } = useSendOTK();
  const [shouldUseOTK, setShouldUseOTK] = useState(false);
  const { data: hostedAppData } = useObserveQuery(QueryKeysEnum.hosted);

  const handleSendOTK = useCallback(async () => {
    try {
      const result = await sendOTK();
      const { data } = result;
      localStorage.setItem(happToken!, data?.otk_token);
      return result;
    } catch (error) {
      history.push(`/otk-error`);
    }
  }, [happToken, history, sendOTK]);

  useEffect(() => {
    if (!isInIframe()) {
      history.push(`${RoutePaths.CREATE_OTP}?token=${happToken}`);
    } else if (otk && isInIframe()) {
      setShouldUseOTK(true);
    }
  }, [happToken, history, otk]);

  useEffect(() => {
    if (shouldUseOTK && otk) {
      handleSendOTK();
    }
  }, [handleSendOTK, happToken, otk, sendOTK, shouldUseOTK]);

  useEffect(() => {
    if (hostedAppData) {
      if (
        hostedAppData?.data?.application?.token &&
        !hostedAppData?.data?.renew_result?.redirect_to_app_type_page
      ) {
        const firstSectionToken = returnFirstSectionToken(hostedAppData?.data?.application);
        history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
      } else {
        history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
      }
    }
  }, [happToken, history, hostedAppData]);

  return <></>;
}

export default App;
