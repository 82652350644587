import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../store/store';
import { CustomIcon } from '../core';
import { SecDoneIcon } from '../../assets';

interface SingleSectionNavItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  handleChangeSection: (newSectionToken: string) => void;
  sectionToken: string;
  navToken: string;
  isCurrentSection: boolean;
  sectionIcon: React.ReactNode;
  sectionDisplayName: string;
  sectionPercentage: number;
  sectionPercentageText: string;
  isDisabledByStatus: boolean;
}

const SingleSectionNavItem: React.FC<SingleSectionNavItemProps> = ({
  sectionToken,
  navToken,
  handleChangeSection,
  isCurrentSection,
  sectionIcon,
  sectionDisplayName,
  sectionPercentage,
  sectionPercentageText,
  isDisabledByStatus,
}) => {
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const { t } = useTranslation();

  return (
    <button
      data-testid={navToken || sectionToken}
      key={sectionToken}
      disabled={isPreventSaving}
      onClick={() => handleChangeSection(navToken)}
      className={`group flex items-center py-2 font-light hover:text-primary-hover ${isCurrentSection && !isPreventSaving ? 'fill-primary-base text-primary-base' : 'fill-zinc-400 text-zinc-400'} disabled:pointer-events-none`}
    >
      <div
        className={`flex items-center gap-x-2 text-sm group-hover:fill-primary-hover group-hover:text-primary-hover group-disabled:fill-gray-300 group-disabled:text-gray-300`}
      >
        {sectionIcon}
        <span className="text-start">{t(sectionDisplayName)}</span>
      </div>
      <div className="ml-auto flex items-center">
        <div className="flex items-center">
          {sectionPercentage === 100 || isDisabledByStatus ? (
            <CustomIcon
              data-testid="checkmark"
              className="w-5 "
              icon={<SecDoneIcon className={`h-5 group-hover:fill-primary-hover`} />}
            />
          ) : (
            <span
              className={`text-sm group-hover:text-primary-hover ${isCurrentSection && !isPreventSaving ? 'text-primary-base' : 'text-zinc-400'} group-disabled:text-gray-300`}
            >
              {sectionPercentageText}
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export default SingleSectionNavItem;
