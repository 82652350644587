import ApplicatioNotCompletedModal from '../Components/modals/ApplicatioNotCompletedModal';
import CloseWindowModal from '../Components/modals/CloseWindowModal';
import GenericModal from '../Components/modals/GenericModal';
import NoBeneficialOwnersModal from '../Components/modals/NoBeneficialOwnersModal';
import RenewApplicationModal from '../Components/modals/RenewApplicationModal';
import SessionExpiredModal from '../Components/modals/SessionExpiredModal';
import SessionTimerModal from '../Components/modals/SessionTimerModal';
import SubmitApplicationModal from '../Components/modals/SubmitApplicationModal';

export const ModalProvider = () => {
  return (
    <>
      <ApplicatioNotCompletedModal />
      <RenewApplicationModal />
      <NoBeneficialOwnersModal />
      <GenericModal />
      <SubmitApplicationModal />
      <CloseWindowModal />
      <SessionTimerModal />
      <SessionExpiredModal />
    </>
  );
};
