import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomIcon } from '.';

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  delay?: number;
  icon: React.ReactNode;
  side?: 'left' | 'right';
  trianglePosition?: 'top' | 'center';
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  delay,
  icon,
  side = 'right',
  trianglePosition = 'top',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    const id = setTimeout(() => setIsHovered(true), delay);
    setTimerId(id);
  };

  const handleMouseLeave = () => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
      setTimerId(undefined);
    }
    setIsHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsHovered(false);
      if (timerId !== undefined) {
        clearTimeout(timerId);
        setTimerId(undefined);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [timerId]);

  return (
    <div data-testid="tooltip" className="relative">
      <div
        className={`absolute ${isHovered ? 'opacity-100' : 'pointer-events-none opacity-0'} z-10 flex shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)] transition duration-300 
      ${
        side === 'left'
          ? '-left-52 before:-right-2 before:rotate-180'
          : '-right-3 translate-x-full before:right-[100%]'
      }
      ${
        trianglePosition === 'center'
          ? 'top-1/2 -translate-y-1/2 before:top-1/2 before:-translate-y-1/2'
          : '-top-1 before:top-3.5 before:-translate-y-1/2'
      }
       w-48 rounded-md bg-white px-3 py-1 text-center text-sm text-gray-600 before:absolute before:border-4 before:border-transparent before:border-l-transparent before:border-r-white before:content-['']`}
      >
        {t(text)}
      </div>
      <CustomIcon
        icon={React.cloneElement(icon as React.ReactElement, {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        })}
      />
    </div>
  );
};

export default Tooltip;
