interface Config {
  role_name: string;
  language: string;
  backend_base_url_active_line: string;
  backend_base_url_passive_line: string;
  translations_url_prefix: string;
  google_recaptcha_site_key: string;
  flags: {
    enable_google_recaptcha: boolean;
    enable_frontend_validation: boolean;
    enable_parallel_file_upload: boolean;
  };
}

export const config: Config = (window as any)?.config || {};
