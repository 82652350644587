import { PropsWithChildren } from 'react';

import { isInIframe } from '../../lib/iframe';
import Header from './Header';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const inIFrame = isInIframe();

  return (
    <>
      {!inIFrame && <Header />}
      <div className={`${inIFrame ? '' : 'mt-[70px]'}`}>{children}</div>
    </>
  );
};

export default Layout;
