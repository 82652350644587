import FieldsHandler from './FieldHandler';
import { BaseElementProps } from '../types/element';
import { PhoneField } from './core';
import { sortItemsBySortOrder } from '../utils/helpers';

interface ElementsHandlerProps extends BaseElementProps, React.HTMLAttributes<HTMLDivElement> {}

const ElementsHandler: React.FC<ElementsHandlerProps> = ({
  elementData,
  register,
  setValue,
  control,
  triggersState,
  sectionData,
  applicationData,
  handleChangeAppType,
  isDisabledByStatus,
}) => {
  switch (elementData.element_type) {
    case 'phone':
      return (
        <PhoneField
          {...{
            register,
            setValue,
            control,
            triggersState,
            elementData,
            sectionData,
            isDisabledByStatus,
          }}
        />
      );
  }

  return (
    <div>
      {sortItemsBySortOrder(elementData?.fields)?.map(field => (
        <FieldsHandler
          handleChangeAppType={handleChangeAppType}
          fieldData={field}
          key={field.token}
          {...{
            control,
            register,
            setValue,
            triggersState,
            elementData,
            sectionData,
            applicationData,
            isDisabledByStatus,
          }}
        />
      ))}
    </div>
  );
};

export default ElementsHandler;
