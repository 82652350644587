import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { IApplication } from '../../types/application';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { useGenericModal } from '../../hooks/modals';

interface SaveApplicationParams {
  application: IApplication;
}

async function saveApplication({
  application,
}: SaveApplicationParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(
    `verify/applications/${application.token}/save`,
    application,
  );
  return response.data;
}

export const useSaveApplication = () => {
  const queryClient = useQueryClient();
  const genericModal = useGenericModal();

  return useMutation(saveApplication, {
    mutationKey: MutationKeysEnum.saveApplication,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      const hostedAppData = queryClient.getQueryData(QueryKeysEnum.hosted) as any;
      if (hostedAppData) {
        const updatedHostedAppData = {
          ...hostedAppData,
          data: { ...hostedAppData.data, application: data.data },
        };
        queryClient.setQueryData(QueryKeysEnum.hosted, updatedHostedAppData);
      }
    },
    onError: (error: any) => {
      const err =
        error?.response?.data?.status?.message || 'Something went wrong, please try again later.';
      genericModal.onOpen(() => {}, 'Oops...', err, 'Close');
    },
  });
};
