import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckBoxProps } from '@rapyd/react-ui-library/dist/components/CheckBox';
import { BaseFieldProps } from '../../../types/field';
import { useClearSection, useCopySection } from '../../../services';
import { usePathParams, useClearSection as useClearSectionHook } from '../../../hooks';
import { CheckBox as RapydCheckbox } from '@rapyd/react-ui-library';
import { Typography } from '..';

interface CheckboxFieldProps extends BaseFieldProps, CheckBoxProps {
  label?: string;
  shouldMaskCharacters?: boolean;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  fieldData,
  fieldRegisterValue,
  isDisabledByStatus,
  control,
  sectionData,
  applicationData,
  fieldTriggers,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: mutateCopySection } = useCopySection();
  const { mutateAsync: mutateClearSection } = useClearSection();
  const { clearSection } = useClearSectionHook(sectionData!, applicationData);
  const { sectionToken } = usePathParams();

  const handleSetChecked = async (val: string | null) => {
    if (val !== null) {
      await mutateCopySection({
        applicationToken: applicationData.token,
        sectionToken: sectionToken!,
        copy_from: val,
      });
    } else {
      await mutateClearSection({
        applicationToken: applicationData.token,
        sectionToken: sectionData!.token,
      });
      await clearSection();
    }
  };

  const lov = fieldTriggers?.list_of_values || fieldData.list_of_values;

  return (
    <Controller
      control={control}
      name={fieldRegisterValue!}
      render={({ field }) => (
        <div className="flex w-full flex-row items-center gap-x-5">
          <Typography.BODY1 className="text-zinc-600">
            {t(fieldTriggers?.name || fieldData.name)}
          </Typography.BODY1>
          {lov.map(item => {
            return (
              <div key={item.id} data-testid={`same_as_${item.id}`}>
                <RapydCheckbox
                  disabled={
                    fieldTriggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus
                  }
                  checked={field.value === item.id}
                  onChange={() => {
                    field.onChange(field.value === item.id ? null : item.id);
                    handleSetChecked(field.value === item.id ? null : String(item.id));
                  }}
                  name={fieldData?.token || fieldData?.object_type}
                >
                  <Typography.BODY1 className="text-gray-700">{t(item?.value)}</Typography.BODY1>
                </RapydCheckbox>
              </div>
            );
          })}
        </div>
      )}
    />
  );
};

export default CheckboxField;
