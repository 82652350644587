import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldProps as RapydTextFieldProps } from '@rapyd/react-ui-library/dist/components/Form/TextField';
import { BaseFieldProps } from '../../../types/field';
import { TextField as RapydTextField } from '@rapyd/react-ui-library';
import { NUMBER_FIELDS_INPUT_TYPES, SECTION_IGNORED_ERROR_MESSAGE } from '../../../data/constants';
import { FieldBoxVariant } from '@rapyd/react-ui-library/dist/components/Form/FieldBox';
import { FieldInputTypesEnum } from '../../../data/enums';
import { requiredFieldError } from '../../../data/validations';

interface TextFieldProps extends BaseFieldProps, RapydTextFieldProps {
  label?: string;
  shouldMaskCharacters?: boolean;
  isInvalidField?: boolean;
  isDirty?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  fieldData,
  fieldRegisterValue,
  isDisabledByStatus,
  control,
  fieldTriggers,
  sectionData,
  fieldErrorMessage,
  isInvalidField,
  isDirty,
}) => {
  const { t } = useTranslation();
  const handleInputChange = (value: string, onChange: any) => {
    // allow only numbers
    if (NUMBER_FIELDS_INPUT_TYPES.includes(fieldData?.input_type)) {
      if (value.match(/^\d*$/)) {
        onChange(value);
      } else {
        return;
      }
    } else {
      onChange(value);
    }
  };

  const showErrorMessage = useCallback(() => {
    if (sectionData?.error_message === SECTION_IGNORED_ERROR_MESSAGE || isDisabledByStatus) {
      return false;
    } else {
      return true;
    }
  }, [fieldErrorMessage, isDisabledByStatus]);
  const errorMessage = fieldErrorMessage || (isInvalidField && fieldData?.error_message);
  const backendError = !isDirty && fieldData?.error_message ? fieldData?.error_message : '';
  const shouldShowErrorMessage =
    showErrorMessage() &&
    (isInvalidField || fieldErrorMessage || (!isDirty && fieldData?.error_message));
  const getErrorMessage = () => {
    if (
      fieldData?.input_type === FieldInputTypesEnum.phone_number ||
      fieldData?.input_type === FieldInputTypesEnum.phone_country_code
    ) {
      return shouldShowErrorMessage
        ? errorMessage || fieldData?.error_message || requiredFieldError(fieldData.name)
        : undefined;
    }
    return shouldShowErrorMessage ? fieldErrorMessage || backendError : '';
  };

  return (
    <Controller
      control={control}
      name={fieldRegisterValue!}
      render={({ field }) => (
        <RapydTextField
          disabled={fieldTriggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus}
          value={field.value}
          onChange={val => {
            handleInputChange(val, field.onChange);
          }}
          fieldBoxProps={{
            label: t(fieldTriggers?.name || fieldData.name),
            required: fieldTriggers?.is_required || fieldData?.is_required,
            variant: FieldBoxVariant.Outer,
            isClearable: !(
              fieldTriggers?.is_read_only ||
              fieldData?.is_read_only ||
              isDisabledByStatus
            ),
            error: getErrorMessage(),
          }}
          placeholder={fieldData?.placeholder}
          name={fieldData?.token || fieldData?.object_type}
        />
      )}
    />
  );
};

export default TextField;
