import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  FIELDS_TYPES_WITH_COUNTRY_FLAG,
  FIELD_COMPONENT_MAP,
  MULTISELECT_DROPDOWN_FIELDS,
} from '../data/constants';
import { BaseFieldProps } from '../types/field';
import { appendTriggerToField, isFieldWithChangeAppTypeButton } from '../utils/helpers';
import { ClientStrings } from '../translations/ClientStrings';
import { CustomButton, Tooltip } from './core';
import { TooltipIcon } from '../assets';
import { RootState } from '../store/store';
import { SectionError } from '../types/section';

interface FieldHandlerProps extends BaseFieldProps, React.HTMLAttributes<HTMLDivElement> {
  isElementError?: boolean;
}

const FieldHandler: React.FC<FieldHandlerProps> = ({
  register,
  fieldData,
  setValue,
  control,
  triggersState,
  elementData,
  sectionData,
  applicationData,
  handleChangeAppType,
  isDisabledByStatus,
  isElementError,
}) => {
  const {
    formState: { errors },
    getFieldState,
  } = useFormContext();
  const isPreventSaving = useSelector((state: RootState) => state?.global?.isPreventSaving);
  const { t } = useTranslation();
  const fieldRegisterValue = `${sectionData?.token}.${elementData?.element_type}.${fieldData?.field_type}`;
  const { error, isDirty } = getFieldState(fieldRegisterValue);
  let field = fieldData;
  const fieldTrigger = triggersState?.find(trigger =>
    trigger.field_token === fieldRegisterValue ? trigger : undefined,
  );

  if (fieldTrigger) {
    field = appendTriggerToField(fieldData, fieldTrigger);
  }

  if (
    field?.triggers?.is_shown === false ||
    (!field?.triggers?.is_shown && (field?.is_shown === false || field?.shown === false))
  ) {
    return <></>;
  }

  const isChangeAppTypeButton = isFieldWithChangeAppTypeButton(fieldData);
  const sectionErrors: SectionError = errors?.[sectionData?.token!];
  const fieldErrorMessage = fieldData?.is_read_only
    ? ''
    : sectionErrors?.[elementData?.element_type]?.[fieldData?.field_type]?.message ?? '';

  const Field =
    FIELD_COMPONENT_MAP[fieldData.input_type] ??
    (() => <div>Unknown field type {fieldData.input_type}</div>);
  const fieldComponent = (
    <Field
      fieldTriggers={fieldData?.triggers}
      isMultiSelect={MULTISELECT_DROPDOWN_FIELDS.includes(fieldData.input_type)}
      key={fieldRegisterValue}
      {...{
        register,
        fieldData,
        setValue,
        control,
        fieldRegisterValue,
        fieldErrorMessage,
        elementData,
        sectionData,
        applicationData,
        isDisabledByStatus,
        isInvalidField: !!isElementError || !!error,
        isDirty,
      }}
      showCountryFlag={FIELDS_TYPES_WITH_COUNTRY_FLAG.includes(field.field_type)}
    />
  );
  return (
    <div
      key={fieldRegisterValue}
      className="relative py-6"
      data-testid={`${fieldData?.field_type}`}
    >
      {fieldData?.description && (
        <div className="absolute right-0 top-5 z-[1]">
          <Tooltip
            side={`${window.innerWidth <= 1180 ? 'left' : 'right'}`}
            text={t(fieldTrigger?.description || fieldData?.description || '')}
            icon={<TooltipIcon />}
          />
        </div>
      )}
      {fieldComponent}
      {isChangeAppTypeButton && (
        <div className="absolute right-0 text-right">
          <CustomButton
            variant={'link'}
            disabled={isDisabledByStatus || isPreventSaving}
            onClick={handleChangeAppType}
          >
            {t(ClientStrings.components.change_app_type)}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default FieldHandler;
