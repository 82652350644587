import { useQuery } from 'react-query';

import { api } from '../../lib/api';
import { useGetHostedApplication } from '../hostedApplication/getHostedApplication';
import { QueryKeysEnum } from '../../data/enums';
import { usePathParams } from '../../hooks';
import { iframeMessages, sendParentMessage } from '../../lib/iframe';
import { IApplication } from '../../types/application';
import { GenericApiResponse } from '../../types/services';

async function getApplication(appToken: string): Promise<GenericApiResponse<IApplication>> {
  const response = await api.get<GenericApiResponse<IApplication>>(
    `verify/applications/${appToken}`,
  );
  return response.data;
}

export const useGetApplication = (applicationToken: string) => {
  return useQuery(
    QueryKeysEnum.application,
    () => {
      if (!applicationToken) return;
      return getApplication(applicationToken);
    },
    {
      enabled: !!applicationToken,
      onError: (error: any) => {
        sendParentMessage(iframeMessages.FORM_LOAD_FAIL);
      },
    },
  );
};

export const useGetApplicationByContext = () => {
  const { happToken } = usePathParams();
  const { data: hostedAppData } = useGetHostedApplication(happToken!);
  const applicationToken = hostedAppData?.data?.application_token;
  return {
    ...useGetApplication(applicationToken!),
    happToken,
  };
};
