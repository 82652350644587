import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { motion, AnimatePresence } from 'framer-motion';
import { useIsFetching, useIsMutating, useQueryClient } from 'react-query';
import { MUTATION_KEYS_WITHOUT_SPINNER } from '../data/constants';

interface SpinnerProps {
  forceShow?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ forceShow = false }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const queryClient = useQueryClient();
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();

  useEffect(() => {
    if (isMutating > 0) {
      const mutations = queryClient.getMutationCache().getAll();
      const currentMutations =
        mutations?.filter(
          mutation => mutation.options.mutationKey && mutation.state.status === 'loading',
        ) || [];
      const preventSpinner = currentMutations?.some(mutation =>
        MUTATION_KEYS_WITHOUT_SPINNER.includes(mutation.options.mutationKey as any),
      );
      if (!preventSpinner) {
        setShowSpinner(true);
      }
    } else {
      setShowSpinner(false);
    }
  }, [isMutating, queryClient]);

  useEffect(() => {
    if (isFetching > 0) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (forceShow) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [forceShow]);

  return (
    <AnimatePresence>
      {(showSpinner || forceShow) && (
        <>
          <motion.div
            data-testid="loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 z-[70] flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
          >
            <div className="max-w-xl">
              <Oval
                height={80}
                width={80}
                color="#4400ff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#724da9"
                strokeWidth={3}
                strokeWidthSecondary={3}
              />
            </div>
          </motion.div>
          <motion.div
            data-testid="loader-backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 z-[62] bg-black bg-opacity-20 backdrop-blur-sm backdrop-filter"
          ></motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Spinner;
