import { useQuery } from 'react-query';

import { api } from '../../lib/api';
import { QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

export interface GetCountryListResponseData {
  id: string;
  value: string;
}

async function getCountryList(): Promise<GenericApiResponse<GetCountryListResponseData[]>> {
  const response = await api.get<GenericApiResponse<GetCountryListResponseData[]>>(
    `verify/applications/list/countries`,
  );
  return response.data;
}

export const useGetCountryList = () => {
  return useQuery(QueryKeysEnum.countryList, () => getCountryList());
};
