import { ISection } from '../types/section';
import { useGetApplicationByContext } from '../services';
import usePathParams from './usePathParams';

export default function useGetSection(sectionToken?: string) {
  const { sectionToken: currentSectionToken } = usePathParams();
  const { data: applicationData } = useGetApplicationByContext();
  let sections: ISection[] = [];
  const sectionsArr = Object.values(applicationData!.data?.sections || {});
  sectionsArr.forEach((section: any) => {
    if (section[0]?.child_sections) {
      section[0]?.child_sections?.forEach((childSection: ISection) => {
        if (childSection?.child_sections) {
          childSection.child_sections.forEach((grandChildSection: ISection) => {
            sections.push(grandChildSection);
          });
        }
        sections.push(childSection);
      });
    }
    sections.push(section[0]);
  });
  if (sectionToken) {
    const currentSection = sections.find(
      (section: ISection) => section.token === sectionToken,
    ) as ISection;
    return currentSection;
  } else {
    const currentSection = sections.find(
      (section: ISection) => section.token === currentSectionToken,
    ) as ISection;
    return currentSection;
  }
}
