import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ApplicationStatusEnum, FormActionsEnum, RoutePaths } from '../data/enums';
import { useGetSection, usePathParams, useSchemaValidation } from '../hooks';
import {
  useApplicationNotCompletedModal,
  useSessionExpiredModal,
  useSubmitApplicationModal,
} from '../hooks/modals';
import { isInIframe } from '../lib/iframe';
import { useGetConfig, useSaveApplication, useSubmitApplication } from '../services';
import { RootState } from '../store/store';
import { ApplicationTriggers, IApplication } from '../types/application';
import {
  addMetaToApplicationSections,
  changeSectionInPath,
  findInvalidSectionInApp,
  flattenApplicationFieldValues,
  mergeSectionFormDataWithApplicationGeneric,
  returnFirstSectionToken,
} from '../utils/helpers';
import Section from './Section';
import Footer from './layout/Footer';
import SectionsNav from './navigation/SectionsNav';

interface FormProps extends React.HTMLAttributes<HTMLDivElement> {
  applicationData: IApplication;
  applicationTriggers: ApplicationTriggers;
}

const Form: React.FC<FormProps> = ({ applicationData, applicationTriggers }) => {
  const currentSection = useGetSection();
  const { sectionToken, happToken } = usePathParams();
  const { mutateAsync: mutateUseSaveApplication } = useSaveApplication();
  const { mutateAsync: mutateSubmitApplication } = useSubmitApplication();
  const history = useHistory();
  const inIFrame = isInIframe();
  const allowShowAppAfterSubmitted = useSelector(
    (state: RootState) => state.global.allowShowingAppAfterSubmitted,
  );
  const isDisabledByStatus =
    (applicationData?.status as ApplicationStatusEnum) !== ApplicationStatusEnum.new;
  const sessionExpiredModal = useSessionExpiredModal();
  const { data: configData } = useGetConfig();

  const applicationNotCompletedModal = useApplicationNotCompletedModal();
  const submitApplicationModal = useSubmitApplicationModal();
  const { schema: schemaValidation } = useSchemaValidation(
    applicationData,
    configData?.flags?.enable_frontend_validation,
  );
  const methods = useForm({
    defaultValues: useMemo(() => flattenApplicationFieldValues(applicationData), [applicationData]),
    resolver: zodResolver(schemaValidation),
    mode: 'onChange',
  });

  const { register, trigger, setValue, control, reset, getValues } = methods;

  useEffect(() => {
    if (currentSection?.error_message) {
      trigger();
    }
  }, [currentSection]);

  useEffect(() => {
    reset(flattenApplicationFieldValues(applicationData));
  }, [applicationData, reset]);

  const onSubmitHandler: SubmitHandler<any> = async e => {
    const updatedApp = await handleSaveApplication();
    const isAppComplete = findInvalidSectionInApp(updatedApp!);
    if (!isAppComplete) {
      submitApplicationModal.onOpen(() => onSubmit(e));
    } else {
      applicationNotCompletedModal.onOpen(() => navigateToInvalidSection(isAppComplete));
    }
  };

  const onSubmit: SubmitHandler<any> = async () => {
    const sectionValues = getValues(sectionToken!);
    const updatedApp = mergeSectionFormDataWithApplicationGeneric(
      applicationData,
      sectionValues,
      sectionToken!,
    );
    const updatedAppWithMeta = addMetaToApplicationSections(updatedApp, FormActionsEnum.SUBMIT);
    await mutateSubmitApplication({
      application: updatedAppWithMeta,
    });
    history.push(`${RoutePaths.THANK_YOU}?token=${happToken}`);
  };

  const navigateToInvalidSection = (sectionToken: string): void => {
    const newPath = changeSectionInPath(sectionToken);
    history.push(newPath);
  };

  const handleSaveApplication = useCallback(async () => {
    let updatedAppData;
    const sectionData = getValues(sectionToken!);
    const updatedAppObj = mergeSectionFormDataWithApplicationGeneric(
      applicationData,
      sectionData,
      sectionToken!,
    );
    await mutateUseSaveApplication({
      application: updatedAppObj,
    }).then(res => {
      updatedAppData = res?.data as IApplication;
    });
    return updatedAppData;
  }, [applicationData, mutateUseSaveApplication, sectionToken, getValues]);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    // prevent form submission on enter key press
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!currentSection) {
      const firstSectionToken = returnFirstSectionToken(applicationData);
      if (firstSectionToken) {
        history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
      }
    }
  }, [applicationData, currentSection, happToken, history]);

  useLayoutEffect(() => {
    if (applicationData?.status !== ApplicationStatusEnum.new && !allowShowAppAfterSubmitted) {
      history.push(`${RoutePaths.THANK_YOU}?token=${happToken}`);
    }
  }, [applicationData?.status, happToken, history, allowShowAppAfterSubmitted]);

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      const isDirty = methods?.getFieldState(sectionToken!)?.isDirty;
      if (isDirty && !inIFrame) {
        event.preventDefault();
        return (event.returnValue = 'You have unsaved changes. Are you sure you want to leave?');
      }
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [inIFrame, methods, sectionToken]);

  useEffect(() => {
    const isDirty = methods?.getFieldState(sectionToken!)?.isDirty;
    const shouldSaveApplication =
      sessionExpiredModal.forceSaveApplication &&
      isDirty &&
      !sessionExpiredModal.preventForceSaveApplication;

    if (shouldSaveApplication) {
      sessionExpiredModal.setForceSaveApplication(false);
      sessionExpiredModal.setPreventForceSaveApplication(true);
      const saveApplication = async () => {
        await handleSaveApplication();
      };
      saveApplication();
    }
  }, [
    sessionExpiredModal.forceSaveApplication,
    methods,
    sectionToken,
    handleSaveApplication,
    sessionExpiredModal,
  ]);

  return (
    <FormProvider {...methods}>
      <div className="relative mx-auto flex w-[1050px] justify-between lg:w-[1100px] xl:w-[1175px]">
        <div className="fixed z-40 ml-8 w-[300px]">
          <SectionsNav />
        </div>
        <form
          onKeyDown={e => handleOnKeyDown(e)}
          onSubmit={e => e.preventDefault()}
          className="absolute right-44 ml-44 pb-20 lg:ml-56"
        >
          <Section
            applicationTriggers={applicationTriggers}
            isDisabledByStatus={isDisabledByStatus}
            control={control}
            setValue={setValue}
            register={register}
            sectionData={currentSection!}
            applicationData={applicationData}
          />
        </form>
        {(!isDisabledByStatus || (isDisabledByStatus && inIFrame)) && (
          <Footer
            isDisabledByStatus={isDisabledByStatus}
            handleSubmit={onSubmitHandler}
            handleSave={handleSaveApplication}
          />
        )}
      </div>
    </FormProvider>
  );
};

export default Form;
