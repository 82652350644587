import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useGetHostedApplicationByContext, useSendOTP } from '../../services';
import { returnFirstSectionToken } from '../../utils/helpers';
import PasscodeInput from './PasscodeInput';
import PasscodeResend from './PasscodeResend';
import { Button, ErrorMessage } from '../core';
import { ClientStrings } from '../../translations/ClientStrings';
import { OTPErrorMessageEnum, RoutePaths, SessionStatusEnum } from '../../data/enums';
import { usePathParams } from '../../hooks';
import { PASSCODE_LENGTH } from '../../data/constants';

const PasscodeForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm();
  const { happToken } = usePathParams();
  const { mutateAsync: mutateSendOTP, isLoading: isSendOTPLoading } = useSendOTP();
  const { data: hostedAppData } = useGetHostedApplicationByContext();
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        if (
          !data ||
          !Object?.values(data)?.length ||
          Object?.values(data)?.length !== PASSCODE_LENGTH
        )
          return;
        setError(null);
        const passcode = Object.values(data).join('');
        if (!happToken) return history.push(`/500`);
        const result = await mutateSendOTP({ happToken, passcode });
        if (result.data?.session_response?.error === OTPErrorMessageEnum.ERROR_SMS_CODE_NOT_MATCH) {
          setError(ClientStrings.session.error_message.invalid_code);
        } else if (
          result.data?.session_response?.error === OTPErrorMessageEnum.ERROR_MAX_ATTEMPT_NUMBER
        ) {
          history.push(`${RoutePaths.SESSION_ERROR}?token=${happToken}`);
        }
        if (result.data?.session_response?.session_status === SessionStatusEnum.APPROVE) {
          if (hostedAppData?.data?.application?.token) {
            const firstSectionToken = returnFirstSectionToken(hostedAppData?.data?.application);
            history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
          } else {
            history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
          }
        }
      } catch (error) {
        setError(ClientStrings.session.error_message.general_error);
      }
    },
    [history, mutateSendOTP, happToken, hostedAppData],
  );

  useEffect(() => {
    if (isValid) {
      handleSubmit(onSubmit)();
    }
  }, [isValid, handleSubmit, onSubmit]);

  return (
    <>
      <form
        autoComplete="new-password"
        className="flex flex-col items-center justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasscodeInput setValue={setValue} register={register} />
        <PasscodeResend />
      </form>
      <div className="md:w-content w-screen">
        <Button
          isLoading={isSendOTPLoading}
          disabled={isSendOTPLoading || !isValid}
          type="submit"
          className="mx-4 w-full md:w-fit"
        >
          {t(ClientStrings.session.submit)}
        </Button>
        {error && <ErrorMessage message={error} className="flex justify-center pt-2" />}
      </div>
    </>
  );
};

export default PasscodeForm;
