import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { IApplication } from '../../types/application';
import { ISection } from '../../types/section';
import { MutationKeysEnum, QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

interface SaveAndDeleteSectionParams {
  application: IApplication;
  section: ISection;
}

async function deleteSection({
  application,
  section,
}: SaveAndDeleteSectionParams): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(
    `verify/applications/${application.token}/save/delete/section/${section.token}`,
    application,
  );
  return response.data;
}

export const useSaveAndDeleteSection = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSection, {
    mutationKey: MutationKeysEnum.saveAndDeleteSection,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.application, data);
      const hostedAppData = queryClient.getQueryData(QueryKeysEnum.hosted) as any;
      if (hostedAppData) {
        const updatedHostedAppData = {
          ...hostedAppData,
          data: { ...hostedAppData.data, application: data.data },
        };
        queryClient.setQueryData(QueryKeysEnum.hosted, updatedHostedAppData);
      }
    },
  });
};
