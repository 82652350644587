import { configureStore } from '@reduxjs/toolkit';
import triggersSlice from './slices/triggersSlice';
import globalSlice from './slices/globalSlice';
import trackingSlice from './slices/trackingSlice';

export const store = configureStore({
  reducer: {
    triggers: triggersSlice,
    global: globalSlice,
    tracking: trackingSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
