import { useMutation } from 'react-query';
import { MutationKeysEnum } from '../../data/enums';
import { api } from '../../lib/api';

interface ValidateRecaptchaProps {
  token: string;
}

async function validateRecaptcha({ token }: ValidateRecaptchaProps): Promise<any> {
  const response = await api.post(`/verify/applications/hosted/recaptcha/verify`, {
    recaptcha_token: token,
  });
  return response.data;
}

export const useValidateRecaptcha = () => {
  return useMutation(validateRecaptcha, {
    mutationKey: MutationKeysEnum.validateRecaptcha,
  });
};
