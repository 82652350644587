import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea as RapydTextArea } from '@rapyd/react-ui-library';
import { FieldBoxVariant } from '@rapyd/react-ui-library/dist/components/Form/FieldBox';
import { TextAreaProps as RapydTextAreaProps } from '@rapyd/react-ui-library/dist/components/Form/TextArea';
import { SECTION_IGNORED_ERROR_MESSAGE } from '../../../data/constants';
import { BaseFieldProps } from '../../../types/field';

interface TextAreaFieldProps extends BaseFieldProps, RapydTextAreaProps {
  label?: string;
  shouldMaskCharacters?: boolean;
  isInvalidField?: boolean;
  isDirty?: boolean;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  fieldData,
  fieldRegisterValue,
  isDisabledByStatus,
  control,
  fieldTriggers,
  sectionData,
  fieldErrorMessage,
  isInvalidField,
  isDirty,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  const showErrorMessage = useCallback(() => {
    if (sectionData?.error_message === SECTION_IGNORED_ERROR_MESSAGE || isDisabledByStatus) {
      return false;
    } else {
      return true;
    }
  }, [sectionData?.error_message, isDisabledByStatus]);
  const shouldShowErrorMessage =
    showErrorMessage() && (fieldErrorMessage || fieldData?.error_message);
  const errorMessage = (fieldErrorMessage ||
    (isInvalidField && !isDirty && fieldData?.error_message)) as string;
  const getErrorMessage = () =>
    shouldShowErrorMessage
      ? errorMessage || (!isDirty && fieldData?.error_message ? fieldData?.error_message : '')
      : '';

  return (
    <Controller
      control={control}
      name={fieldRegisterValue!}
      render={({ field }) => (
        <RapydTextArea
          onKeyDown={handleKeyDown}
          disabled={fieldTriggers?.is_read_only || fieldData?.is_read_only || isDisabledByStatus}
          value={field.value}
          onChange={val => {
            field.onChange(val);
          }}
          fieldBoxProps={{
            label: t(fieldTriggers?.name || fieldData.name),
            variant: FieldBoxVariant.Outer,
            required: fieldTriggers?.is_required || fieldData?.is_required,
            maxLength: fieldData?.limit,
            showLength: !!fieldData?.limit,
            isClearable: !(
              fieldTriggers?.is_read_only ||
              fieldData?.is_read_only ||
              isDisabledByStatus
            ),
            error: getErrorMessage(),
          }}
          placeholder={fieldData?.placeholder}
          name={fieldData?.token || fieldData?.object_type}
        />
      )}
    />
  );
};

export default TextAreaField;
