import { useMutation } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

interface DeleteDocumentsProps {
  applicationToken: string;
  sectionToken: string;
  fieldToken: string;
  files: any[];
}

interface DeleteDocumentsResponseData {
  error: string;
  field_token: string;
  file: {
    token: string;
  };
}

async function deleteDocuments({
  applicationToken,
  fieldToken,
  files,
  sectionToken,
}: DeleteDocumentsProps): Promise<GenericApiResponse<DeleteDocumentsResponseData[]>> {
  const filesArray = files?.map((file: any) => {
    return {
      file: {
        token: file.token,
      },
      field_token: fieldToken,
    };
  });

  const response = await api.post<GenericApiResponse<DeleteDocumentsResponseData[]>>(
    `verify/applications/multiple_documents`,
    {
      application_token: applicationToken,
      section_token: sectionToken,
      file_details: filesArray,
    },
  );
  return response.data;
}

export const useDeleteDocuments = () => {
  return useMutation(deleteDocuments, {
    mutationKey: MutationKeysEnum.deleteDocuments,
  });
};
