import { useEffect } from 'react';

type ValidKeys = 'Escape';

export const useOnKeyPress = (targetKey: ValidKeys, callback: () => void): void => {
  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        callback();
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [targetKey, callback]);
};
