import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';
import { useTimer, usePathParams } from '../../hooks';
import { useGetOTPByContext, useResendOTP } from '../../services';
import { Button, ErrorMessage } from '../core';
import { ClientStrings } from '../../translations/ClientStrings';

interface PasscodeResendProps extends React.ButtonHTMLAttributes<HTMLDivElement> {}

const PasscodeResend: React.FC<PasscodeResendProps> = () => {
  const { isFetched: isOTPFetched } = useGetOTPByContext();
  const {
    minutes,
    seconds,
    isActive: isTimerActive,
    reset,
  } = useTimer({ duration: 120, startTimer: isOTPFetched });
  const { mutateAsync, isLoading } = useResendOTP();
  const { t } = useTranslation();
  const { happToken } = usePathParams();
  const [error, setError] = useState<string | null>(null);

  const handleResendCode = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setError(null);
    try {
      await mutateAsync({ happToken: happToken! });
      reset();
    } catch (error) {
      setError('Resend failed');
    }
  };

  return (
    <div className="my-5 flex flex-col items-center justify-center">
      {isTimerActive ? (
        <p className="text-sm font-medium text-gray-400">
          {t(ClientStrings.session.resend_in)}{' '}
          <span className="font-medium text-primary-base">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        </p>
      ) : (
        <Button
          variant={ButtonVariants.TEXT}
          disabled={isLoading}
          type="button"
          onClick={handleResendCode}
        >
          {t(ClientStrings.session.resend)}
        </Button>
      )}
      {error && <ErrorMessage message={t(error)} />}
    </div>
  );
};

export default PasscodeResend;
