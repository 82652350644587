import { create } from 'zustand';

interface useRenewApplicationModalStore {
  isOpen: boolean;
  onOpen: (approveCallback: () => void) => void;
  onClose: () => void;
  approveCallback: () => void;
}

export const useRenewApplicationModal = create<useRenewApplicationModalStore>(set => ({
  isOpen: false,
  onOpen: approveCallback => set({ isOpen: true, approveCallback }),
  onClose: () => set({ isOpen: false }),
  approveCallback: () => {},
}));
