import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import z from 'zod';

import { useSwitchApplicationType, useCreateApplication } from '../../services';
import { returnFirstSectionToken } from '../../utils/helpers';
import { zodResolver } from '@hookform/resolvers/zod';
import useWizardForm from '../../hooks/useWizardForm';
import { ClientStrings } from '../../translations/ClientStrings';
import FieldErrorMessage from '../core/ErrorMessage';
import { COUNTRIES_WITH_PROVIDER } from '../../data/constants';
import { useUpdateHostedApplication } from '../../services';
import Spinner from '../Spinner';
import { Button } from '@rapyd/react-ui-library';
import RapydDropdownField from '../core/form-fields/DropdownField';
import { Tooltip } from '../core';
import { TooltipIcon } from '../../assets';
import { RoutePaths } from '../../data/enums';
import { usePathParams } from '../../hooks';

const schema = z.object({
  wizard_application_type: z
    .object(
      {
        value: z.string().min(1),
        label: z.string().min(1),
      },
      { invalid_type_error: 'This field is required.' },
    )
    .nullable()
    .refine(data => data !== null, {
      message: 'This field is required.',
    }),
  wizard_country: z
    .object(
      {
        value: z.string().min(1),
        label: z.string().min(1),
      },
      { invalid_type_error: 'This field is required.' },
    )
    .nullable()
    .refine(data => data !== null, {
      message: 'This field is required.',
    }),
  wizard_mcc: z
    .object(
      {
        value: z.number().min(1),
        label: z.string().min(1),
      },
      { invalid_type_error: 'This field is required.' },
    )
    .nullable()
    .refine(data => data !== null, {
      message: 'This field is required.',
    }),
  wizard_sell_type: z
    .object(
      {
        value: z.string().min(1),
        label: z.string().min(1),
      },
      { invalid_type_error: 'This field is required.' },
    )
    .nullable()
    .refine(data => data !== null, {
      message: 'This field is required.',
    }),
});

const WizardForm: React.FC = () => {
  const {
    appTypes,
    countryList,
    hostedAppData,
    mccs,
    selectedAppType,
    selectedMCC,
    selectedSellType,
    sellTypes,
    isLoading,
    selectedCountry,
  } = useWizardForm();
  const history = useHistory();
  const { happToken } = usePathParams();
  const { t } = useTranslation();
  const { mutateAsync: mutateCreateApplication, isLoading: isCreateApplicationLoading } =
    useCreateApplication();
  const { mutateAsync: mutateSwitchApplicationType, isLoading: isSwitchApplicationLoading } =
    useSwitchApplicationType();
  const {
    mutateAsync: mutateUpdateHostedApplication,
    isLoading: isUpdateHostedApplicationLoading,
  } = useUpdateHostedApplication();

  const formMethods = useForm({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const { register, handleSubmit, setValue, control, formState } = formMethods;

  const onSubmit = async (data: any) => {
    let application = null;
    if (hostedAppData?.data?.application) {
      application = await mutateSwitchApplicationType({
        app_type: data.wizard_application_type.value,
        application_token: hostedAppData?.data?.application?.token,
        business_industry: data.wizard_mcc.value.toString(),
        sell_type: data.wizard_sell_type.value,
      });
    } else {
      await mutateUpdateHostedApplication({
        application_type: data.wizard_application_type.value,
        business_industry: data.wizard_mcc.value.toString(),
        country: data.wizard_country.value,
        hosted_app: hostedAppData!.data.token,
        sell_type: data.wizard_sell_type.value,
      });
      if (
        hostedAppData?.data?.country &&
        COUNTRIES_WITH_PROVIDER.includes(hostedAppData?.data?.country)
      ) {
        history.push(`${RoutePaths.PROVIDER}?token=${happToken}`);
        return;
      }
      application = await mutateCreateApplication({
        application_type: data.wizard_application_type.value,
        country: data.wizard_country.value,
        business_industry: data.wizard_mcc.value.toString(),
        sell_type: data.wizard_sell_type.value,
        rapyd_entity_token: hostedAppData!.data.rapyd_entity_token,
        authorized_user_email: hostedAppData!?.data?.authorized_user_email,
      });
    }
    if (application) {
      const firstSectionToken = returnFirstSectionToken(application.data);
      history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
    }
  };

  return (
    <>
      {isCreateApplicationLoading && <Spinner forceShow={isCreateApplicationLoading} />}
      <FormProvider {...formMethods}>
        <form className="flex w-[400px] flex-col gap-y-8" onSubmit={handleSubmit(onSubmit)}>
          {isLoading || !selectedCountry ? (
            <></>
          ) : (
            <>
              <div className="relative">
                <div className="absolute -right-8 top-[2.2rem]">
                  <Tooltip
                    text={t(ClientStrings.create_application.countries_dropdown.description)}
                    icon={<TooltipIcon />}
                  />
                </div>
                <RapydDropdownField
                  showCountryFlag={true}
                  control={control}
                  register={register}
                  setValue={setValue}
                  fieldRegisterValue={'wizard_country'}
                  fieldData={{
                    token: 'wizard_country',
                    value: {
                      value: selectedCountry?.value || '',
                      label: selectedCountry?.label || '',
                    },
                    name: 'Country',
                    input_type: 'dropdown',
                    is_valid: null,
                    is_read_only: true,
                    field_type: 'wizard_country',
                    is_required: true,
                    list_of_values: countryList || [],
                    object_type: 'wizard_country',
                    field_triggers_value: '',
                    description: t(
                      ClientStrings.create_application.countries_dropdown.description,
                    )!,
                    placeholder: t(ClientStrings.create_application.countries_dropdown.label)!,
                  }}
                />
                <FieldErrorMessage
                  message={formState.errors?.wizard_country?.message as string}
                  className="relative top-2"
                />
              </div>
              <div className="relative">
                <div className="absolute -right-8 top-[2.2rem]">
                  <Tooltip
                    text={t(ClientStrings.create_application.business_dropdown.description)}
                    icon={<TooltipIcon />}
                  />
                </div>
                <RapydDropdownField
                  control={control}
                  register={register}
                  setValue={setValue}
                  fieldRegisterValue={'wizard_application_type'}
                  fieldData={{
                    token: 'wizard_business_type',
                    value: selectedAppType || '',
                    name: 'Business Type',
                    input_type: 'dropdown',
                    is_valid: null,
                    is_read_only:
                      hostedAppData?.data?.application?.is_authorized_by_service || false,
                    field_type: 'wizard_business_type',
                    is_required: true,
                    list_of_values: appTypes || [],
                    object_type: 'wizard_business_type',
                    field_triggers_value: '',
                    description: t(ClientStrings.create_application.business_dropdown.description)!,
                    placeholder: t(ClientStrings.create_application.business_dropdown.label)!,
                  }}
                />
                <FieldErrorMessage
                  message={formState.errors?.wizard_application_type?.message as string}
                  className="relative top-2"
                />
              </div>
              <div className="relative">
                <RapydDropdownField
                  control={control}
                  register={register}
                  setValue={setValue}
                  fieldRegisterValue={'wizard_mcc'}
                  fieldData={{
                    token: 'wizard_mcc',
                    value: selectedMCC || '',
                    name: 'Industry',
                    input_type: 'dropdown',
                    is_valid: null,
                    is_read_only: !!hostedAppData?.data?.populated_from,
                    field_type: 'wizard_mcc',
                    is_required: true,
                    list_of_values: mccs || [],
                    object_type: 'wizard_mcc',
                    field_triggers_value: '',
                    description: t(ClientStrings.create_application.mcc_dropdown.description)!,
                    placeholder: t(ClientStrings.create_application.mcc_dropdown.label)!,
                  }}
                />
                <FieldErrorMessage
                  message={formState.errors?.wizard_mcc?.message as string}
                  className="relative top-2"
                />
              </div>
              <div className="relative">
                <RapydDropdownField
                  control={control}
                  register={register}
                  setValue={setValue}
                  fieldRegisterValue={'wizard_sell_type'}
                  fieldData={{
                    token: 'wizard_sell_type',
                    value: selectedSellType || '',
                    name: 'Sell Type',
                    input_type: 'dropdown',
                    is_valid: null,
                    is_read_only: false,
                    field_type: 'wizard_sell_type',
                    is_required: true,
                    list_of_values: sellTypes || [],
                    object_type: 'wizard_sell_type',
                    field_triggers_value: '',
                    description: t(
                      ClientStrings.create_application.sell_type_dropdown.description,
                    )!,
                    placeholder: t(ClientStrings.create_application.sell_type_dropdown.label)!,
                  }}
                />
                <FieldErrorMessage
                  message={formState.errors?.wizard_sell_type?.message as string}
                  className="relative top-2"
                />
              </div>
              <Button
                isLoading={
                  isUpdateHostedApplicationLoading ||
                  isCreateApplicationLoading ||
                  isSwitchApplicationLoading
                }
                disabled={
                  !formState.isValid ||
                  isCreateApplicationLoading ||
                  isSwitchApplicationLoading ||
                  isUpdateHostedApplicationLoading
                }
                type="submit"
                className="w-full"
              >
                {t(ClientStrings.create_application.button)}
              </Button>
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default WizardForm;
