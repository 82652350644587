import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { api } from '../../lib/api';
import { IApplication } from '../../types/application';
import { QueryKeysEnum } from '../../data/enums';
import { usePathParams } from '../../hooks';
import { RootState } from '../../store/store';
import { setUserEmail } from '../../store/slices/trackingSlice';
import { GenericApiResponse } from '../../types/services';

export interface GetHostedAppResponseData {
  token: string;
  application_token: string | null;
  country: string;
  rapyd_entity_token: string;
  client_reference_id: string;
  authorized_user_email: string;
  cancel_url: string;
  complete_url: string;
  phone_number: string;
  application_level: 1 | 2;
  sell_type: string;
  business_industry: string | null;
  application_type: ApplicationType;
  populated_from?: string;
  application?: IApplication;
  organization_details?: OrganizationDetails;
  renew_result?: RenewResult | null;
}

export interface MerchantCustomerSupport {
  url?: string;
  email?: string;
  phone_number?: string;
}

export interface OrganizationDetails {
  merchant_color?: string;
  merchant_website?: string;
  merchant_alias?: string;
  merchant_customer_support?: MerchantCustomerSupport;
  merchant_design?: any;
  merchant_language?: any;
  merchant_logo?: string;
  merchant_terms?: string;
  merchant_privacy_policy?: string;
}

export interface RenewResult {
  need_to_renew: boolean;
  redirect_to_app_type_page: boolean;
}

interface ApplicationType {
  application_type: string;
  entity_type: string;
  country: string;
}

export async function getHostedApplication(
  happToken: string,
): Promise<GenericApiResponse<GetHostedAppResponseData>> {
  const response = await api.get<GenericApiResponse<GetHostedAppResponseData>>(
    `/verify/applications/hosted/${happToken}`,
  );
  return response.data;
}

export function useGetHostedApplication(happToken: string) {
  const dispatch = useDispatch();
  const userEmail = useSelector((state: RootState) => state.tracking.userEmail);

  return useQuery(QueryKeysEnum.hosted, () => getHostedApplication(happToken), {
    onSuccess: data => {
      const authorizedUserEmail = data?.data?.application?.authorized_user_email;
      if (authorizedUserEmail && userEmail !== authorizedUserEmail) {
        dispatch(setUserEmail(authorizedUserEmail));
      }
    },
    onError: (error: any) => {
      if (error?.response?.status === 500) {
        window.location.href = '/500';
      }
    },
  });
}

export const useGetHostedApplicationByContext = () => {
  const { happToken } = usePathParams();
  return {
    ...useGetHostedApplication(happToken!),
    happToken,
  };
};
