import { useLayoutEffect, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';

import Form from '../Form';
import {
  useGetApplicationByContext,
  useGetHostedApplicationByContext,
  useSwitchApplicationType,
} from '../../services';
import { usePathParams } from '../../hooks';
import { returnFirstSectionToken } from '../../utils/helpers';
import { ApplicationStatusEnum, RoutePaths } from '../../data/enums';
import { useRenewApplicationModal } from '../../hooks/modals';

interface FormPageProps extends RouteComponentProps {}

const FormPage: React.FC<FormPageProps> = () => {
  const {
    data: applicationData,
    isLoading: isApplicationLoading,
    isFetching: isApplicationFetching,
  } = useGetApplicationByContext();
  const {
    data: hostedAppData,
    isLoading: isHostedAppLoading,
    isFetching: isHostedAppFetching,
  } = useGetHostedApplicationByContext();
  const { happToken, sectionToken } = usePathParams();
  const history = useHistory();
  const { mutateAsync: switchAppType, isLoading: isSwitchAppTypeLoading } =
    useSwitchApplicationType();
  const [showSwitchAppTypeModal, setShowSwitchAppTypeModal] = useState(false);

  const renewApplicationModal = useRenewApplicationModal();

  useEffect(() => {
    if (applicationData && !sectionToken) {
      const firstSectionToken = returnFirstSectionToken(applicationData.data);
      if (firstSectionToken) {
        history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
      }
    }
  }, [applicationData, happToken, history, sectionToken]);

  useLayoutEffect(() => {
    const isFetching =
      isApplicationFetching ||
      isHostedAppFetching ||
      isSwitchAppTypeLoading ||
      isApplicationLoading ||
      isHostedAppLoading;

    const applicationStatus =
      hostedAppData?.data?.application?.status || applicationData?.data?.status;

    const isActiveStatus = applicationStatus && applicationStatus === ApplicationStatusEnum.new;

    const isNeedToRenew =
      !isFetching &&
      hostedAppData &&
      (hostedAppData?.data?.renew_result?.need_to_renew ||
        (hostedAppData?.data?.application && !hostedAppData?.data?.application?.triggers));

    const isApplicationNotReady =
      !isFetching && applicationData && !applicationData?.data?.triggers;

    const appType = hostedAppData?.data?.application_type?.application_type;
    const appToken = hostedAppData?.data?.application_token || applicationData?.data?.token;
    const businessIndustry = hostedAppData?.data?.business_industry;
    const sellType = hostedAppData?.data?.sell_type;

    const missingInformation = !appType || !appToken || !businessIndustry || !sellType;
    const forceRedirectToWizard = hostedAppData?.data?.renew_result?.redirect_to_app_type_page;

    if (forceRedirectToWizard) {
      history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
      return;
    }

    if ((isNeedToRenew || isApplicationNotReady) && !showSwitchAppTypeModal && isActiveStatus) {
      if (missingInformation) {
        history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
        return;
      }
      setShowSwitchAppTypeModal(true);
    } else if (!isNeedToRenew && !isApplicationNotReady && showSwitchAppTypeModal) {
      setShowSwitchAppTypeModal(false);
    }
  }, [
    applicationData,
    hostedAppData,
    showSwitchAppTypeModal,
    isApplicationLoading,
    isSwitchAppTypeLoading,
    isHostedAppLoading,
    isHostedAppFetching,
    happToken,
    history,
    isApplicationFetching,
  ]);

  useLayoutEffect(() => {
    if (
      showSwitchAppTypeModal &&
      hostedAppData &&
      !isSwitchAppTypeLoading &&
      !isApplicationLoading &&
      !isHostedAppLoading
    ) {
      renewApplicationModal.onOpen(() => {
        const appType = hostedAppData?.data?.application_type?.application_type;
        const appToken = hostedAppData?.data?.application_token || applicationData?.data?.token;
        const businessIndustry = hostedAppData?.data?.business_industry;
        const sellType = hostedAppData?.data?.sell_type;
        setShowSwitchAppTypeModal(false);
        switchAppType({
          app_type: appType!,
          application_token: appToken!,
          business_industry: businessIndustry!,
          sell_type: sellType!,
        });
      });
    }
  }, [
    showSwitchAppTypeModal,
    hostedAppData,
    isApplicationLoading,
    isHostedAppLoading,
    isSwitchAppTypeLoading,
  ]);

  return (
    <>
      {showSwitchAppTypeModal ||
      isApplicationFetching ||
      isHostedAppFetching ||
      isSwitchAppTypeLoading ||
      isApplicationLoading ||
      !applicationData ? (
        <></>
      ) : (
        <Form
          applicationTriggers={
            hostedAppData?.data?.application?.triggers || applicationData?.data?.triggers
          }
          applicationData={applicationData?.data!}
        />
      )}
    </>
  );
};

export default FormPage;
