import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useSubmitApplicationModal } from '../../hooks/modals';
import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';
import ReCAPTCHA from '../ReCAPTCHA';
import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';
import { RootState } from '../../store/store';
import { useObserveQuery } from '../../hooks';
import { QueryKeysEnum } from '../../data/enums';
import { useGetConfig } from '../../services';

const SubmitApplicationModal = () => {
  const submitApplication = useSubmitApplicationModal();
  const { t } = useTranslation();
  const { data: configData } = useGetConfig();
  const isRecaptchaVerified = useSelector((state: RootState) => state.global.isRecaptchVerified);

  const { data: hostedAppData } = useObserveQuery(QueryKeysEnum.hosted);
  const merchant_privacy_policy =
    hostedAppData?.data?.organization_details?.merchant_privacy_policy;

  return (
    <Dialog open={submitApplication.isOpen} onOpenChange={submitApplication.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(ClientStrings.modals.submit.question)}</DialogTitle>
          <DialogDescription asChild>
            <div className="pb-6 pt-2 text-start text-sm text-slate-500">
              {merchant_privacy_policy && (
                <>
                  <span>{t(ClientStrings.modals.submit.terms.submit_text)}</span>
                  <a
                    data-testid="private_policy_link"
                    rel="noreferrer"
                    target="_blank"
                    href={merchant_privacy_policy}
                    className="link-button inline px-1 underline"
                  >
                    {t(ClientStrings.modals.submit.terms.privacy_policy)}
                  </a>
                </>
              )}
              {configData?.flags?.enable_google_recaptcha === true && <ReCAPTCHA />}
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            data-testid={`modal-${ClientStrings.modals.submit.save_btn || 'secondary'}`}
            className="w-44 min-w-fit"
            onClick={() => {
              submitApplication.onClose();
            }}
            variant={ButtonVariants.TEXT}
          >
            {t(ClientStrings.modals.submit.save_btn)}
          </Button>
          <Button
            data-testid={`modal-${ClientStrings.modals.submit.submit_btn || 'primary'}`}
            disabled={!isRecaptchaVerified && configData?.flags?.enable_google_recaptcha === true}
            className="w-44 min-w-fit"
            onClick={() => {
              submitApplication?.approveCallback?.();
              submitApplication.onClose();
            }}
          >
            {t(ClientStrings.modals.submit.submit_btn)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitApplicationModal;
