import { getPathParams } from '../utils/helpers';

const usePathParams = () => {
  const { happToken, sectionToken, otk } = getPathParams();
  return {
    happToken,
    sectionToken,
    otk,
  };
};

export default usePathParams;
