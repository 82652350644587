import { useTranslation } from 'react-i18next';

import { Button } from '../core';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../core/Dialog';
import { ClientStrings } from '../../translations/ClientStrings';
import { useNoBeneficialOwnersModal } from '../../hooks/modals';
import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';

const NoBeneficialOwnersModal = () => {
  const beneficialOwners = useNoBeneficialOwnersModal();
  const { t } = useTranslation();

  return (
    <Dialog open={beneficialOwners.isOpen} onOpenChange={beneficialOwners.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(ClientStrings.modals.choose_no_shareholder.title)}</DialogTitle>
          <DialogDescription>
            {t(ClientStrings.modals.choose_no_shareholder.description)}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            data-testid={`modal-${ClientStrings.modals.choose_no_shareholder.no_btn || 'secondary'}`}
            className="w-44 min-w-fit"
            onClick={() => {
              beneficialOwners.onClose();
            }}
            variant={ButtonVariants.TEXT}
          >
            {t(ClientStrings.modals.choose_no_shareholder.no_btn)}
          </Button>
          <Button
            data-testid={`modal-${ClientStrings.modals.choose_no_shareholder.yes_btn || 'primary'}`}
            className="w-44 min-w-fit"
            onClick={() => {
              beneficialOwners?.approveCallback?.();
              beneficialOwners.onClose();
            }}
          >
            {t(ClientStrings.modals.choose_no_shareholder.yes_btn)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NoBeneficialOwnersModal;
