import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCallback } from 'react';

import { BaseFormProps } from '../types/form';
import { useClearSection, usePathParams, useTriggers } from '../hooks';
import ElementsHandler from './ElementsHandler';
import Spinner from './Spinner';
import {
  addMetaToApplicationSections,
  changeSectionInPath,
  getSectionFieldsByFieldType,
  mergeSectionFormDataWithApplicationGeneric,
  returnSectionBySectionType,
  sortItemsBySortOrder,
} from '../utils/helpers';
import { useSaveApplication, useSaveSection } from '../services';
import { ClientStrings } from '../translations/ClientStrings';
import {
  FormActionsEnum,
  RoutePaths,
  SectionTypesEnum,
  ShareHolderExistsEnum,
  ShareHolderExistsType,
} from '../data/enums';
import { Typography, Button, Icon } from './core';
import { ButtonVariants } from '@rapyd/react-ui-library/dist/components/Button';
import { isInIframe } from '../lib/iframe';
import { ButtonGroup as RapydButtonGroup } from '@rapyd/react-ui-library';
import { ThemeBreakpoints } from '@rapyd/react-ui-library/dist/theme/breakpoints';
import { IconName } from '@rapyd/react-ui-library/dist/components/Icon';
import { RootState } from '../store/store';
import { ApplicationTriggers } from '../types/application';
import { useNoBeneficialOwnersModal } from '../hooks/modals';

interface SectionProps extends BaseFormProps, React.HTMLAttributes<HTMLDivElement> {
  applicationTriggers: ApplicationTriggers;
}

const Section: React.FC<SectionProps> = ({
  applicationTriggers,
  sectionData,
  register,
  control,
  setValue,
  applicationData,
  isDisabledByStatus,
}) => {
  const { getValues } = useFormContext();
  const { happToken, sectionToken } = usePathParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { clearSection, loading } = useClearSection(sectionData!, applicationData);
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const { mutateAsync: mutateSaveApplication } = useSaveApplication();
  const { mutateAsync: mutateSaveSection } = useSaveSection();
  const noBeneficialOwnersModal = useNoBeneficialOwnersModal();
  const inIFrame = isInIframe();

  const { triggersState } = useTriggers({
    sectionTriggers: applicationTriggers,
    sectionData: sectionData!,
  });

  const handleClearSection = async () => {
    await clearSection();
  };

  const handleChangeApplicationType = async () => {
    const sectionValues = getValues(sectionToken!);
    const updatedApp = mergeSectionFormDataWithApplicationGeneric(
      applicationData,
      sectionValues,
      sectionToken!,
    );
    const updatedAppWithMeta = addMetaToApplicationSections(
      updatedApp,
      FormActionsEnum.SAVE_and_SWITCH,
    );
    await mutateSaveApplication({
      application: updatedAppWithMeta,
    });
    history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
  };

  const hideClearAllButton = () => {
    const shouldHide = getSectionFieldsByFieldType(sectionData!, ['similar_to']);
    return !!shouldHide[0]?.value;
  };

  const handleChangeShareholderType = async (value: ShareHolderExistsType) => {
    const sectionValues = getValues(sectionToken!);
    const shareholdersControlSection = returnSectionBySectionType(
      applicationData,
      SectionTypesEnum.shareholders_control,
    );
    const shareholdersControlSectionValue = { has_owner: { shareholder_exists: value } };
    const updatedApp = mergeSectionFormDataWithApplicationGeneric(
      applicationData,
      shareholdersControlSectionValue,
      shareholdersControlSection?.token!,
    );
    const updatedAppLatest = mergeSectionFormDataWithApplicationGeneric(
      updatedApp,
      sectionValues,
      sectionToken!,
    );
    const mergedAppWithMeta = addMetaToApplicationSections(
      updatedAppLatest,
      `${FormActionsEnum.CHANGE_OWNER}:${value}`,
    );
    await mutateSaveSection({
      application: mergedAppWithMeta,
      section: sectionData!,
    });
    const navigateToSection =
      value === ShareHolderExistsEnum.yes
        ? returnSectionBySectionType(applicationData, SectionTypesEnum.shareholder)
        : returnSectionBySectionType(applicationData, SectionTypesEnum.shareholders_control);
    const newPath = changeSectionInPath(navigateToSection?.token!);
    history.push(newPath);
  };

  const shareholderType = useCallback(() => {
    try {
      const shareholderControlSection = returnSectionBySectionType(
        applicationData,
        SectionTypesEnum.shareholders_control,
      );
      const fieldValue = getValues(
        `${shareholderControlSection?.token}.has_owner.shareholder_exists`,
      );
      return fieldValue;
    } catch (error) {
      return null;
    }
  }, [applicationData, getValues]);

  const handleShareholderTypeOnChange = (value: ShareHolderExistsType) => {
    if (value === ShareHolderExistsEnum.no) {
      noBeneficialOwnersModal.onOpen(() => handleChangeShareholderType(value));
    } else if (value === ShareHolderExistsEnum.yes) {
      handleChangeShareholderType(value);
    }
  };

  return (
    <SectionContainer>
      {loading && <Spinner />}
      <HeaderContainer
        isInIframe={inIFrame}
        className={`-translate-x-12 ${inIFrame ? 'top-0' : 'top-[70px]'}`}
      >
        <Header>
          <SectionHeader>{t(sectionData?.name!)}</SectionHeader>
          {!hideClearAllButton() ? (
            <Button
              disabled={isPreventSaving || isDisabledByStatus}
              variant={ButtonVariants.TEXT}
              onClick={handleClearSection}
            >
              {t(ClientStrings.section_header.clear)}
            </Button>
          ) : (
            <div className="py-[22.5px]"></div>
          )}
        </Header>
        <Description>
          <SectionDescription>
            {(sectionData?.section_type === 'shareholder' ||
              sectionData?.section_type === 'shareholders_control') &&
            sectionData?.description
              ? t(sectionData?.description)?.split('<1>')[0]
              : t(sectionData?.description || '')}
            {(sectionData?.section_type === 'shareholder' ||
              sectionData?.section_type === 'shareholders_control') && (
              <div
                className={`mt-10 flex items-center gap-x-4 ${isDisabledByStatus || isPreventSaving ? 'pointer-events-none opacity-70' : ''}`}
              >
                {t(ClientStrings.section_description.no_beneficial_owners.question)}
                <RapydButtonGroup
                  disabled={isDisabledByStatus || isPreventSaving}
                  staticSize={ThemeBreakpoints.SMALL}
                  value={shareholderType()}
                  onChange={value => handleShareholderTypeOnChange(value as ShareHolderExistsType)}
                  options={[
                    {
                      value: ShareHolderExistsEnum.yes,
                      label: (
                        <div data-testid="Yes" className="flex items-center justify-center gap-x-1">
                          <Icon className="text-lg" name={IconName.USERS} />
                          {t(ClientStrings.section_description.no_beneficial_owners.yes)}
                        </div>
                      ),
                    },
                    {
                      value: ShareHolderExistsEnum.no,
                      label: (
                        <div data-testid="No" className="flex items-center justify-center gap-x-1">
                          <Icon className="text-lg" name={IconName.BLOCK} />
                          {t(ClientStrings.section_description.no_beneficial_owners.no)}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </SectionDescription>
        </Description>
      </HeaderContainer>
      <BodyContainer>
        {sectionData?.section_type !== 'shareholders_control' &&
          sortItemsBySortOrder(sectionData?.data_elements!)?.map(element => (
            <ElementsHandler
              isDisabledByStatus={isDisabledByStatus}
              handleChangeAppType={handleChangeApplicationType}
              key={element.token}
              elementData={element}
              {...{ register, setValue, control, triggersState, sectionData, applicationData }}
            />
          ))}
      </BodyContainer>
    </SectionContainer>
  );
};

const SectionHeader = styled(Typography.H5)`
  font-weight: 600;
  color: #4b4b4b;
`;

const SectionDescription = styled(Typography.BODY1)`
  font-weight: 400;
  color: #626262;
  padding-bottom: 4px;
  display: inline;
  line-height: 1.3;
`;

const SectionContainer = styled.div`
  width: 485px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div<{ isInIframe: boolean }>`
  z-index: 20;
  background-color: ${({ isInIframe }) => (isInIframe ? '#FBFBFB' : 'white')};
  width: 780px;
  padding-left: 45px;
  padding-right: 250px;
  position: sticky;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  align-items: center;
`;

const Description = styled.div`
  padding-top: 4px;
`;

const BodyContainer = styled.div`
  width: 485px;
  margin-top: 4px;
  margin-bottom: 130px;
`;

export default Section;
//TODO: migrate to Tailwind
