export const ClientStrings = {
  section_description: {
    no_beneficial_owners: {
      question: 'Are there any beneficial owners?',
      yes: 'Yes',
      no: 'No',
    },
  },
  errorPage: {
    error_title: 'Error code',
    oops: 'Oops!',
    error_msg_title: "Looks like something's wrong.",
    500: {
      error_code: '500',
      error_msg_line1:
        "An error has occurred and we’re trying to fix the problem. We'll be back soon.",
      error_cust_support: 'Please go back to the website and try again.',
      back_btn: 'Back to website',
    },
    404: {
      error_code: '404',
      error_cust_support: '',
      error_msg_line1: 'The page you requested could not be found.',
      back_btn: 'Back to website',
    },
    otk: {
      error_cust_support: '',
      error_msg_line1: 'We’re sorry, but there was an error accessing Rapyd Verify. ',
      error_msg_line2: 'Please refresh your browser.',
    },
    ErrorSession: {
      error_cust_support: '',
      error_msg_line1: 'Multiple unsuccessful attempts were made to enter the one-time password.',
      error_msg_line2:
        'For security reasons, this application will be locked for the next 24 hours.',
      close_btn: 'close',
    },
  },
  errorCodes: {
    ERROR_GOOGLE_RECAPTCHA_VALIDATION_FAILED: 'Error reverifying reCAPTCHA, please try again.',
  },
  header: {
    back_to_website: 'Back to website',
    time_remaining_in_session: 'Time remaining in session',
    session_expired: 'You Session Has Expired',
  },
  create_application: {
    title: 'Welcome! Let’s get started.',
    description:
      'Before you can fully activate your account, we need to know a bit more about you and your business.',
    countries_dropdown: {
      label: 'Select Country',
      description: 'Select the country where the principle office is registered',
    },
    business_dropdown: {
      label: 'Business Type',
      description: 'Select the type of business',
    },
    mcc_dropdown: {
      label: 'Industry',
      description: '',
    },
    sell_type_dropdown: {
      label: 'How Do You Currently Sell?',
      description: '',
    },
    button: 'Start Application',
  },
  country_auth: {
    fill_manually: 'Fill out the form manually',
    SG: 'Retrieve MyInfo business with ',
  },
  components: {
    url_no_protocol: 'Website must contain http(s).',
    ownership_percentage_error: 'A number between 25 and 100 is required.',
    dropdown_empty_placeholder: 'Please select...',
    dropdown_placeholder: 'Search',
    dropdown_country: 'country',
    expand_files_title: 'Accepted files:',
    file_upload: {
      message: 'Drop your file here',
      max_file_error: 'You cannot upload more than <1 /> <2 />.',
      total_file_size_error: 'Total file size should not exceed {{max_size}}.',
      file_exceed_max_size:
        'The file is too large and cannot be uploaded. Please reduce the size of the file and try again.',
      upload_failed_generic: 'An error occurred while uploading the file.',
      reason: 'Reason',
      single_file: 'file',
      multiple_files: 'files',
      upload_action: 'upload',
      delete_action: 'delete',
      action_failed_with_files: 'Failed to <1 /> the following files: <2 />',
      action_failed: 'Failed to <1 /> files. <2 />',
      upload_failed_with_files: 'Failed to upload the following files: <1 />',
      delete_failed_with_files: 'Failed to delete the following files: <1 />',
      upload_failed: 'Failed to upload files. <1 />',
      delete_failed: 'Failed to delete files. <1 />',
      bytes: 'bytes',
      show_less: 'show less',
      show_more: 'show more',
    },
    change_app_type: 'Change',
  },
  comment: 'Comment: ',
  status: 'Status: ',
  next: 'Next',
  menu: {
    save_changes: 'Save Draft',
    submit_form: 'Submit',
    submit_tooltip: 'Save changes before submitting your application',
    last_updated: 'Last Draft:',
    unsaved_changes: 'Unsaved Changes',
    go_back: 'Go Back',
  },
  sub_menu: {
    add_shareholder: 'Add Owner',
    optional: 'Optional',
  },
  section_header: {
    clear: 'Clear All',
    details: 'Details',
    prev: 'Previous',
    next: 'Next',
    completed: 'Completed',
  },
  shareholder_control: {
    breadcrumbs: {
      change_owner: 'Change Owner',
    },
    mandatory_tooltip: 'At least one owner must be completed',
    add_additional_tooltip: 'Add additional owners within the company owner',
    buttons: {
      yes: 'Add Owner Details',
      no: 'There Are No Owners',
    },
  },
  thankyou: {
    title: {
      submitted: 'Your application is pending approval.',
      approved: 'Congratulations!',
      not_approved: '',
      pending_information: '',
    },
    description: {
      submitted:
        "The application has been submitted and is being reviewed by Rapyd's onboarding team.",
      approved: 'The application is approved.',
      not_approved:
        'We regret to inform you that the application was declined. Rapyd is not able to support this merchant.',
      pending_information:
        'Additional information is required for processing the application. For details, please see the email from Rapyd on this subject.',
    },
    continue: 'Continue',
    view_form: 'View Application',
  },
  modals: {
    submit: {
      question: 'Are you ready to submit the application?',
      text: 'A submitted application can <1 /> no longer be modified.',
      submit_btn: 'Submit',
      save_btn: 'Save Changes',
      continue: 'Continue',
      title: 'Your application is not complete',
      note_message: 'Please complete your application before submitting it.',
      terms: {
        submit_text: 'For more information about the personal data we process, please see our',
        privacy_policy: 'Privacy Policy',
      },
    },
    session_soon_to_expire: {
      title: 'Session Ending Soon',
      description:
        'Your session is ending in {{time_left}}. Please save your progress and reenter your one time passcode once your session expires.',
      yes_btn: 'Okay',
    },
    session_expired: {
      title: 'You Session Has Expired',
      yes_btn: 'Reload Session',
    },
    leave: {
      question: 'Are you sure you want to leave?',
      explanation: 'Your changes might be lost if you leave this page now.',
      no_btn: 'No, stay',
      yes_btn: 'Yes, leave',
    },
    delete: {
      title: 'Delete Owner',
      question: 'Are you sure you want to delete <1 />{{sec_name}}?',
      no_btn: 'Cancel',
      yes_btn: 'Delete',
    },
    go_back: {
      title: 'Go Back?',
      description: 'Heads up! Changing owner will take you to the previous page.',
      no_btn: 'Go Back',
      yes_btn: 'Keep Editing',
    },
    change_shareholder_type: {
      title: 'Change The Owner Type?',
      description: 'Heads up! Changing the type of owner may remove the owners within it.',
      no_btn: 'Cancel',
      yes_btn: 'Change Type',
    },
    remove_shareholder: {
      title: 'Remove This owner?',
      description: 'Heads up! Removing this owner will also remove the owners within it.',
      no_btn: 'Cancel',
      yes_btn: 'Remove',
    },
    choose_no_shareholder: {
      title: 'No Beneficial Owners?',
      description:
        'I confirm there are no individuals who ultimately own or control the company, e.g. any individuals who hold 25% or more of the company shares. Otherwise, please press cancel.',
      no_btn: 'Cancel',
      yes_btn: 'Confirm',
    },
    error_support_product_type: {
      title: 'Sorry, we cannot support your company currently.',
      description:
        'Unfortunately, your industry type is not supported at this time. Thank you for considering Rapyd.',
      yes_btn: 'Ok, Got It',
    },
    error_support_country: {
      title: 'Sorry, we cannot support your company currently.',
      description:
        'Unfortunately, your country of residence/nationality, is not supported at this time. Thank you for considering Rapyd.',
      yes_btn: 'Ok, Got It',
    },
    error_country_auth: {
      title: 'Unable to Retrieve Info',
      description: 'Complete manually or try again to complete the form',
      no_btn: 'Complete Manually',
      yes_btn: 'Try Again',
    },
    error: {
      error: 'Error ',
      close: 'Close',
    },
    warn_mobile_page: {
      title: 'Mobile User?',
      description:
        'Our form is not mobile-friendly just yet. While we work on it, we recommend completing this form using a desktop computer.',
      yes_btn: 'Dismiss',
    },
    warn_mobile_page_iframe: {
      title: 'Mobile User?',
      description:
        'Our form is not mobile-friendly just yet. While we work on it, we recommend completing this form using a desktop computer.',
      no_btn: 'Back to Website',
      yes_btn: 'Dismiss',
    },
    renew_application: {
      title: 'Heads up!',
      description:
        'Due to inactivity in completing the application for several weeks, some fields may have changed.',
      yes_btn: 'Okay, I understand',
    },
    callback_page: {
      title: 'Your Application has been Updated Successfully',
      description: 'You may now close this window and proceed with your Rapyd application.',
      yes_btn: 'Ok',
    },
  },
  status_desc: {
    new: 'A new version of application was saved',
    submitted:
      "The application has been submitted and is being reviewed by Rapyd's onboarding team",
    approved: 'The application is approved.',
    not_approved:
      'We regret to inform you that the application was declined. Rapyd is not able to support this merchant.',
    pending_information:
      'Additional information is required for processing the application. For details, please see the email from Rapyd on this subject.',
  },
  session: {
    welcome_message: '2-Factor Authentication Code',
    text: 'We just sent an authentication code to your phone.\nEnter the code here:',
    resend_in: 'Resend Authentication Code In',
    resend: 'Resend Authentication Code',
    submit: 'Submit',
    error_message: {
      error_attempt_number: 'Invalid Authentication Code. Please resend.',
      invalid_code: 'Invalid Authentication Code. Please try again.',
      general_error: 'Something went wrong, please try again later.',
      error_max_attempt: 'Please Resend Authentication Code.',
    },
  },
  create_otp: {
    title: 'Welcome to Rapyd!',
    description:
      'We will send a code to the phone number listed below to confirm your multi factor authentication.',
    country_phone_number: '{{country}} - *** *** **{{last_4_digits}}',
    button_text: 'Send Code',
    sub_text_question: 'Need to update the phone number?',
    sub_text_action_link: 'Contact us',
    sub_text_action_rest: 'to open a request.',
  },
  required_file: 'This file is required.',
  status_plain: 'status',
  comment_plain: 'comment',
};
