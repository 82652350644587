import { useLayoutEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import PasscodeForm from '../passcode/PasscodeForm';
import { useGetHostedApplicationByContext, useGetOTPByContext } from '../../services';
import { returnFirstSectionToken } from '../../utils/helpers';
import { ClientStrings } from '../../translations/ClientStrings';
import { RoutePaths, SessionStatusEnum } from '../../data/enums';
import { usePathParams } from '../../hooks';
import { Typography } from '../core';
import OtpLayout from '../layout/OtpLayout';

const OTPPasscodePage: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { happToken } = usePathParams();

  const { data: otpData, isFetched: isOTPFetched } = useGetOTPByContext();
  const { data: hostedAppData, isFetched: isHostedAppFetched } = useGetHostedApplicationByContext();

  const { session_status, session_token } = otpData?.data?.session_response || {};
  const { token: AppToken } = hostedAppData?.data?.application || {};
  const { redirect_to_app_type_page } = hostedAppData?.data?.renew_result || {};

  useLayoutEffect(() => {
    if (isOTPFetched && otpData && isHostedAppFetched && hostedAppData && happToken) {
      if (happToken && session_token) {
        localStorage.setItem(happToken, session_token);
      }
      if (session_status === SessionStatusEnum.APPROVE) {
        if (AppToken && !redirect_to_app_type_page && hostedAppData?.data?.application) {
          const firstSectionToken = returnFirstSectionToken(hostedAppData.data.application);
          history.push(`${RoutePaths.FORM}?token=${happToken}&section=${firstSectionToken}`);
        } else {
          history.push(`${RoutePaths.WIZARD}?token=${happToken}`);
        }
      } else if (session_status === SessionStatusEnum.REJECT) {
        history.push(`${RoutePaths.SESSION_ERROR}?token=${happToken}`);
      }
    }
  }, [
    isOTPFetched,
    otpData,
    isHostedAppFetched,
    hostedAppData,
    history,
    happToken,
    session_token,
    session_status,
    AppToken,
    redirect_to_app_type_page,
  ]);

  return (
    <OtpLayout title={ClientStrings.session.welcome_message} subTitle={ClientStrings.session.text}>
      <PasscodeForm />
    </OtpLayout>
  );
};

export default OTPPasscodePage;
