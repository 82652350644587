import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DropzoneProps } from '@rapyd/react-ui-library/dist/components/Dropzone';
import { BaseFieldProps } from '../../../types/field';
import { Dropzone } from '@rapyd/react-ui-library';
import { ThemeBreakpoints } from '@rapyd/react-ui-library/dist/theme/breakpoints';
import { RootState } from '../../../store/store';
import {
  useDeleteDocuments,
  useSaveApplicationWithParallelFileUploads,
  useUploadDocumentInParallel,
  useUploadDocuments,
} from '../../../services';
import FieldErrorMessage from '../ErrorMessage';
import { setIsPreventSaving } from '../../../store/slices/globalSlice';
import Typography from '../Typography';
import UploadedFile from '../../UploadedFile';
import {
  DOWNLOAD_FILE_TYPE,
  MAX_FILE_SIZE_IN_BYTES,
  SECTION_IGNORED_ERROR_MESSAGE,
} from '../../../data/constants';
import {
  UploadDocumentsFailedResponseData,
  UploadDocumentsResponseData,
} from '../../../services/documents/uploadDocuments';
import { ClientStrings } from '../../../translations/ClientStrings';
import { formatBytes, getFieldTotalFilesSize } from '../../../utils/helpers';
import { useObserveQuery } from '../../../hooks';
import { QueryKeysEnum } from '../../../data/enums';

interface FileUploadFieldProps extends BaseFieldProps, DropzoneProps {
  label?: string;
  shouldMaskCharacters?: boolean;
  isDirty?: boolean;
}

const FileUploadField: React.FC<FileUploadFieldProps> = ({
  fieldData,
  setValue,
  fieldRegisterValue,
  fieldErrorMessage,
  isDisabledByStatus,
  control,
  applicationData,
  sectionData,
  fieldTriggers,
  isDirty,
}) => {
  const { setError, clearErrors, getFieldState, getValues, trigger } = useFormContext();
  const { data: configData } = useObserveQuery(QueryKeysEnum.config);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const { mutateAsync: mutateUploadDocuments, isLoading: isLoadingUploadDocuments } =
    useUploadDocuments();
  const {
    mutateAsync: mutateUploadDocumentInParallel,
    isLoading: isLoadingUploadDocumentInParallel,
  } = useUploadDocumentInParallel();
  const { mutateAsync: mutateSaveApplicationWithParallelFileUploads } =
    useSaveApplicationWithParallelFileUploads();

  const { mutateAsync: mutateDeleteDocuments, isLoading: isLoadingDeleteDocuments } =
    useDeleteDocuments();

  const onDrop = useCallback(
    async (droppedFiles: any[]) => {
      try {
        const fieldError = getFieldState(fieldRegisterValue!)?.error;
        const isStatic = fieldError?.type === 'static';

        if (isStatic) {
          clearErrors(fieldRegisterValue!);
        }

        const droppedFilesSize = droppedFiles?.reduce(
          (acc: number, file: any) => acc + file.size,
          0,
        );
        const isSingleFileAndOverMaxSize =
          droppedFiles.length === 1 && droppedFilesSize >= MAX_FILE_SIZE_IN_BYTES;
        if (isSingleFileAndOverMaxSize) {
          const fileName = droppedFiles[0].name;
          const message = `${fileName} - ${t(ClientStrings.components.file_upload.file_exceed_max_size)}`;
          setError(fieldRegisterValue!, { type: 'static', message: message });
          return;
        }

        if (droppedFiles.length > fieldData.max_files! ?? 1) {
          setError(fieldRegisterValue!, {
            type: 'static',
            message: `You can upload maximum ${fieldData.max_files! ?? 1} ${fieldData.max_files! > 1 ? 'files' : 'file'}.`,
          });
          return;
        }

        dispatch(setIsPreventSaving(true));

        await mutateUploadDocuments({
          applicationToken: applicationData.token,
          sectionToken: sectionData!.token,
          fieldToken: fieldData.token,
          files: droppedFiles,
        })
          .catch(error => {
            const errorMsg =
              error?.response?.data?.status?.message ||
              ClientStrings.components.file_upload.upload_failed_generic;
            setError(fieldRegisterValue!, { type: 'static', message: errorMsg });
          })
          .then(response => {
            if (!response) return;
            const files = response?.data;
            if (!files || files?.length === 0) return;

            const failedUploads = files.filter(
              file => file?.status?.toLowerCase() === 'error',
            ) as UploadDocumentsFailedResponseData[];
            if (failedUploads?.length) {
              const concatenatedErrors = failedUploads
                .map(error => `${error.origin_name} - ${error.message || 'general error'}`)
                .join('\n\n');
              setError(fieldRegisterValue!, { type: 'static', message: concatenatedErrors });
            }
            const successfulUploads = files.filter(
              file => file?.status?.toLowerCase() !== 'error',
            ) as UploadDocumentsResponseData[];
            const currentValue = getValues(fieldRegisterValue!) || [];
            const updatedValue = [...currentValue, ...successfulUploads];
            setValue(fieldRegisterValue, updatedValue, { shouldDirty: true });
          })
          .finally(() => {
            const hasFiles =
              getValues(fieldRegisterValue!)?.filter((file: any) => file.is_deleted === false)
                .length > 0;
            const isStatic = getFieldState(fieldRegisterValue!)?.error?.type === 'static';
            if (hasFiles && !isStatic) {
              trigger(fieldRegisterValue!);
            }
          });

        dispatch(setIsPreventSaving(false));
      } catch (error) {
        dispatch(setIsPreventSaving(false));
      }
    },
    [
      getFieldState,
      fieldRegisterValue,
      fieldData.max_files,
      fieldData.token,
      dispatch,
      mutateUploadDocuments,
      applicationData.token,
      sectionData,
      clearErrors,
      t,
      setError,
      getValues,
      setValue,
      trigger,
    ],
  );

  const onDropParallel = useCallback(
    async (droppedFiles: any[]) => {
      try {
        const fieldError = getFieldState(fieldRegisterValue!)?.error;
        const isStatic = fieldError?.type === 'static';

        if (isStatic) {
          clearErrors(fieldRegisterValue!);
        }

        const maxFiles = fieldData?.max_files ?? 1;
        if (droppedFiles.length > maxFiles) {
          setError(fieldRegisterValue!, {
            type: 'static',
            message: `You can upload maximum ${fieldData.max_files! ?? 1} ${fieldData.max_files! > 1 ? 'files' : 'file'}.`,
          });
          return;
        }

        const fieldTotalFilesMaxSize = getFieldTotalFilesSize(fieldData);
        const droppedFilesSize = droppedFiles?.reduce(
          (acc: number, file: any) => acc + file.size,
          0,
        );
        const isSingleFileAndOverMaxSize =
          droppedFiles.length === 1 && droppedFilesSize >= MAX_FILE_SIZE_IN_BYTES;
        if (isSingleFileAndOverMaxSize) {
          const fileName = droppedFiles[0].name;
          const message = `${fileName} - ${t(ClientStrings.components.file_upload.file_exceed_max_size)}`;
          setError(fieldRegisterValue!, { type: 'static', message: message });
          return;
        }
        if (droppedFilesSize > fieldTotalFilesMaxSize) {
          const maxSize = formatBytes(fieldTotalFilesMaxSize);
          const message = t(ClientStrings.components.file_upload.total_file_size_error, {
            max_size: maxSize,
          });
          setError(fieldRegisterValue!, { type: 'static', message: message });
          return;
        }

        dispatch(setIsPreventSaving(true));

        const uploadFilePromises: any[] = [];

        droppedFiles.forEach(async file => {
          uploadFilePromises.push(
            mutateUploadDocumentInParallel({
              applicationToken: applicationData.token,
              sectionToken: sectionData!.token,
              fieldToken: fieldData.token,
              file,
            }),
          );
        });

        const promiseResults = await Promise.allSettled(uploadFilePromises);

        const results = promiseResults.map(res => {
          if (res.status === 'fulfilled') {
            return res?.value?.data;
          } else {
            const isTooLarge = res?.reason?.response?.status === 413;
            const fileName = droppedFiles[promiseResults.indexOf(res)]?.name;
            const errorMsg = isTooLarge
              ? ClientStrings.components.file_upload.file_exceed_max_size
              : ClientStrings.components.file_upload.upload_failed_generic;
            return { status: 'error', message: errorMsg, origin_name: fileName };
          }
        });

        const failedUploads = results.filter(file => {
          return file?.status?.toLowerCase() === 'error';
        }) as UploadDocumentsFailedResponseData[];

        const successfulUploads = results.filter(
          file => file?.status?.toLowerCase() !== 'error',
        ) as UploadDocumentsResponseData[];

        if (successfulUploads?.length === 0) {
          const errorMsg = failedUploads
            ?.map(error => `${error.origin_name} - ${error.message || 'general error'}`)
            .join('\n\n');
          setError(fieldRegisterValue!, { type: 'static', message: errorMsg });
        } else {
          await mutateSaveApplicationWithParallelFileUploads({
            applicationToken: applicationData.token,
            sectionToken: sectionData!.token,
            fieldToken: fieldData.token,
            files: successfulUploads,
          })
            .then(() => {
              if (failedUploads?.length) {
                const concatenatedErrors = failedUploads
                  .map(error => `${error.origin_name} - ${error.message || 'general error'}`)
                  .join('\n\n');
                setError(fieldRegisterValue!, { type: 'static', message: concatenatedErrors });
              }

              const currentValue = getValues(fieldRegisterValue!) || [];
              const updatedValue = [...currentValue, ...successfulUploads];
              setValue(fieldRegisterValue, updatedValue, { shouldDirty: true });
            })
            .catch(error => {
              const errorMsg =
                error?.response?.data?.status?.message ||
                ClientStrings.components.file_upload.upload_failed_generic;
              setError(fieldRegisterValue!, { type: 'static', message: errorMsg });
            })
            .finally(() => {
              const hasFiles =
                getValues(fieldRegisterValue!)?.filter((file: any) => file.is_deleted === false)
                  .length > 0;
              const isStatic = getFieldState(fieldRegisterValue!)?.error?.type === 'static';
              if (hasFiles && !isStatic) {
                trigger(fieldRegisterValue!);
              }
            });
        }

        dispatch(setIsPreventSaving(false));
      } catch (error) {
        dispatch(setIsPreventSaving(false));
      }
    },
    [
      getFieldState,
      fieldRegisterValue,
      fieldData,
      dispatch,
      mutateSaveApplicationWithParallelFileUploads,
      applicationData.token,
      sectionData,
      clearErrors,
      setError,
      t,
      mutateUploadDocumentInParallel,
      getValues,
      setValue,
      trigger,
    ],
  );

  const handleDeleteFile = async (file: any) => {
    dispatch(setIsPreventSaving(true));
    await mutateDeleteDocuments({
      applicationToken: applicationData.token,
      fieldToken: fieldData.token,
      files: [file],
      sectionToken: sectionData!.token,
    })
      .then(response => {
        const updatedFile = { ...file, is_deleted: true };
        const currentValue = getValues(fieldRegisterValue!);
        const updatedValue = currentValue.filter(
          (fileInner: any) => fileInner.token !== updatedFile.token,
        );
        const updatedValueWithFile = [...updatedValue, updatedFile];
        setValue(fieldRegisterValue, updatedValueWithFile, { shouldDirty: true });
      })
      .finally(() => trigger(fieldRegisterValue!));
    dispatch(setIsPreventSaving(false));
  };

  const fieldError = useCallback(() => {
    if (
      fieldErrorMessage &&
      fieldErrorMessage === 'This file is required.' &&
      getValues(fieldRegisterValue!)?.filter((file: any) => file.is_deleted === false)?.length > 0
    )
      return '';
    return fieldErrorMessage || (!isDirty && fieldData?.error_message) || '';
  }, [fieldData?.error_message, fieldErrorMessage, fieldRegisterValue, getValues, isDirty]);

  const showErrorMessage = useCallback(() => {
    if (sectionData?.error_message === SECTION_IGNORED_ERROR_MESSAGE || isDisabledByStatus) {
      return false;
    } else {
      return true;
    }
  }, [sectionData?.error_message, isDisabledByStatus]);

  const handleOnDrop = (droppedFiles: any[]) => {
    if (!Array.isArray(droppedFiles) || !droppedFiles?.length) return;
    const totalSize = droppedFiles?.reduce((acc: number, file: any) => acc + file.size, 0);
    const isOver10MB = totalSize >= MAX_FILE_SIZE_IN_BYTES;
    const isParallelUpload = configData?.flags?.enable_parallel_file_upload;
    if (isOver10MB && isParallelUpload) {
      return onDropParallel(droppedFiles);
    } else {
      return onDrop(droppedFiles);
    }
  };
  const isRemovableFile = !(fieldData.type === DOWNLOAD_FILE_TYPE);

  return (
    <Controller
      control={control}
      name={fieldRegisterValue!}
      render={({ field }) => (
        <div data-testid="upload_placeholder">
          {(fieldTriggers?.is_required || fieldData?.is_required) && (
            <span className="pr-1 align-middle text-red-500">*</span>
          )}
          <Typography.BODY1 className="text-zinc-500">
            {t(fieldTriggers?.name || fieldData.name)}
          </Typography.BODY1>
          {(fieldTriggers?.value || getValues(fieldRegisterValue!))?.filter(
            (file: any) => file?.is_deleted === false,
          ).length >= fieldData.max_files! ?? 1 ? (
            <div>
              {(fieldTriggers?.value || getValues(fieldRegisterValue!))
                ?.filter((file: any) => file?.is_deleted === false)
                .map((file: any, index: number) => (
                  <UploadedFile
                    key={file?.token || file?.origin_name}
                    handleDeleteFile={handleDeleteFile}
                    file={file}
                    isLoading={
                      isLoadingDeleteDocuments ||
                      isLoadingUploadDocuments ||
                      isLoadingUploadDocumentInParallel
                    }
                    isDisabled={
                      fieldTriggers?.is_read_only ||
                      isDisabledByStatus ||
                      isPreventSaving ||
                      fieldData?.is_read_only
                    }
                    index={index}
                    isRemovableFile={isRemovableFile}
                  />
                ))}
            </div>
          ) : (
            <div>
              <StyledDropzone
                data-testid="upload_input"
                name={fieldData?.token || fieldData?.object_type}
                disabled={
                  fieldTriggers?.is_read_only ||
                  fieldData?.is_read_only ||
                  isDisabledByStatus ||
                  isPreventSaving
                }
                staticSize={ThemeBreakpoints.LARGE}
                text={t(fieldTriggers?.name || fieldData.name)}
                dropzoneOptions={{
                  onDropAccepted: files => {
                    handleOnDrop(files);
                  },
                  onDropRejected: () => {},
                  disabled:
                    fieldTriggers?.is_read_only ||
                    fieldData?.is_read_only ||
                    isDisabledByStatus ||
                    isPreventSaving,
                }}
              />
              {getValues(fieldRegisterValue!)
                ?.filter((file: any) => file?.is_deleted === false)
                .map((file: any, index: number) => (
                  <UploadedFile
                    key={file?.token || file?.origin_name}
                    handleDeleteFile={handleDeleteFile}
                    file={file}
                    isLoading={false}
                    isDisabled={
                      fieldTriggers?.is_read_only ||
                      isDisabledByStatus ||
                      isPreventSaving ||
                      fieldData?.is_read_only
                    }
                    index={index}
                  />
                ))}
            </div>
          )}
          <FieldErrorMessage
            className="ml-[15.2px] whitespace-pre-wrap"
            message={showErrorMessage() && fieldError() ? t(fieldError()) : ''}
          />
        </div>
      )}
    />
  );
};

const StyledDropzone = styled(Dropzone)<{ disabled: boolean }>`
  margin-top: 0.5rem;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export default FileUploadField;
