import { useQuery } from 'react-query';

import { api } from '../../lib/api';
import { QueryKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';

interface GetCountrtyAuthLinkParams {
  countryCode: string;
  hostedAppToken: string;
}

async function getCountryAuthLink({
  countryCode,
  hostedAppToken,
}: GetCountrtyAuthLinkParams): Promise<GenericApiResponse<string>> {
  const response = await api.get<GenericApiResponse<string>>(
    `verify/applications/hosted/country_auth/get_url/${countryCode}/${hostedAppToken}`,
  );
  return response.data;
}

export const useGetCountryAuthLink = ({
  countryCode,
  hostedAppToken,
}: GetCountrtyAuthLinkParams) => {
  return useQuery(
    QueryKeysEnum.countryAuth,
    () => getCountryAuthLink({ countryCode, hostedAppToken }),
    { enabled: !!countryCode && !!hostedAppToken },
  );
};
