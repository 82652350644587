import { PASSCODE_LENGTH } from '../../data/constants';

interface PasscodeInputProps extends React.InputHTMLAttributes<HTMLDivElement> {
  register: any;
  setValue: any;
}

const PasscodeInput: React.FC<PasscodeInputProps> = ({ register, setValue }) => {
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // allow focus on next input if the user pressed the right arrow key
    if (event.key === 'ArrowRight') {
      const nextInput = event.currentTarget.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }

    // if value is deleted, focus on previous input and delete the current input value
    if (event.key === 'Backspace') {
      const previousInput = event.currentTarget.previousElementSibling as HTMLInputElement;
      setValue(event.currentTarget.name, '');
      if (previousInput) {
        previousInput.focus();
      }
    }

    // if value exist and the user sends a number, focus on next input
    if (event.key.match(/^[0-9]{1}$/)) {
      // allow overwriting the value
      setValue(event.currentTarget.name, event.key);

      const nextInput = event.currentTarget.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }

    // allow focus on previous input if the user pressed the left arrow key
    if (event.key === 'ArrowLeft') {
      const previousInput = event.currentTarget.previousElementSibling as HTMLInputElement;
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // only allow numbers
    const value = event.target.value;
    if (/^[0-9]{1}$/.test(value)) {
      setValue(event.target.name, value);
    } else {
      setValue(event.target.name, '');
    }
  };

  return (
    <div>
      {Array.from(Array(PASSCODE_LENGTH).keys()).map(i => {
        return (
          <input
            data-testid="otp_digits"
            autoComplete="new-password"
            key={i}
            onKeyUp={handleKeyUp}
            maxLength={1}
            className={`passcode-input my-6 ${i === 0 ? 'rounded-l-full ' : ''}${i === PASSCODE_LENGTH - 1 ? 'rounded-r-full ' : ''}`}
            type={'text'}
            {...register(`${i}`, {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e);
              },
              required: true,
            })}
          />
        );
      })}
    </div>
  );
};

export default PasscodeInput;
