import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../utils/helpers';

const linkVariants = cva('', {
  variants: {
    variant: {
      primary: '',
      underline: 'text-gray-700 underline font-light hover:text-gray-500',
      'outlined-colored': 'outlined-colored-button',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

interface CustomLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariants> {
  href: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const CustomLink = React.forwardRef<HTMLAnchorElement, CustomLinkProps>(
  ({ children, className, isLoading, variant = 'primary', isDisabled, ...props }, ref) => {
    return (
      <a
        ref={ref}
        {...props}
        className={cn(linkVariants({ variant, className }), {
          'pointer-events-none bg-gray-200 text-gray-700 opacity-70': isDisabled,
        })}
      >
        {children}
      </a>
    );
  },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
