import React from 'react';

import { MenuConnector } from '../../assets';
import { ISection } from '../../types/section';
import SingleChildSectionNavItem from './SingleChildSectionNavItem';
import { CustomIcon } from '../core';
import { usePathParams } from '../../hooks';

interface ChildSectionsNavProps extends React.HTMLAttributes<HTMLDivElement> {
  childSections: ISection[];
  disabled?: boolean;
  handleChangeSection: (section: string) => void;
  isDisabledByStatus?: boolean;
}

const ChildSectionsNav: React.FC<ChildSectionsNavProps> = ({
  childSections,
  handleChangeSection,
  disabled,
  isDisabledByStatus,
}) => {
  const { sectionToken } = usePathParams();
  return (
    <div>
      {childSections?.map((childSection: ISection) => {
        const isCurrentSection =
          sectionToken === childSection.token ||
          childSection?.child_sections?.some(
            (grandChildSection: ISection) => grandChildSection.token === sectionToken,
          ) ||
          false;
        return (
          <React.Fragment key={childSection.token}>
            <SingleChildSectionNavItem
              isDisabledByStatus={isDisabledByStatus}
              disabled={disabled}
              key={childSection.token}
              sectionData={childSection}
              handleChangeSection={handleChangeSection}
              isCurrentSection={isCurrentSection}
              isGrandChild={false}
            />
            {childSection?.child_sections?.map((grandChildSection: ISection) => {
              const isCurrentSection = grandChildSection.token === sectionToken;
              return (
                <div key={grandChildSection.token} className="relative ml-8">
                  <CustomIcon
                    icon={<MenuConnector />}
                    className="absolute -left-4 -top-[1.9rem] -z-10"
                  />
                  <SingleChildSectionNavItem
                    disabled={disabled}
                    isDisabledByStatus={isDisabledByStatus}
                    key={grandChildSection.token}
                    sectionData={grandChildSection}
                    isCurrentSection={isCurrentSection}
                    handleChangeSection={handleChangeSection}
                    isGrandChild={true}
                    parentToken={childSection.token}
                  />
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ChildSectionsNav;
