import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum, QueryKeysEnum, SessionStatusEnum } from '../../data/enums';
import { GetHostedAppResponseData } from '../hostedApplication/getHostedApplication';
import { GenericApiResponse } from '../../types/services';

interface SendOTPParams {
  passcode: string;
  happToken: string;
}

interface SendOTPResponseData {
  session_response: {
    session_token: string;
    session_status: SessionStatusEnum;
    round_number: number;
    attempt_number: number;
    error: string;
  };
  hosted_app: GetHostedAppResponseData | null;
}

async function sendOTP({
  passcode,
  happToken,
}: SendOTPParams): Promise<GenericApiResponse<SendOTPResponseData>> {
  const response = await api.post<GenericApiResponse<SendOTPResponseData>>(
    '/verify/applications/hosted/otp',
    {
      sms_code: passcode,
      happ_token: happToken,
      action: 'submit',
    },
  );
  return response.data;
}

export const useSendOTP = () => {
  const queryClient = useQueryClient();
  return useMutation(sendOTP, {
    mutationKey: MutationKeysEnum.sendOTP,
    onSuccess: data => {
      queryClient.setQueryData(QueryKeysEnum.otp, data);
    },
  });
};
