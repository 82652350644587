import { useQuery } from 'react-query';
import { QueryKeysEnum } from '../data/enums';
import { GetHostedAppResponseData } from '../services/hostedApplication/getHostedApplication';
import { IApplication } from '../types/application';
import { GetApplicationTypesResponseData } from '../services/applicationType/getApplicationTypes';
import { GetOTPResponseData } from '../services/otp/getOTP';
import { GetSellTypeAndMCCResponseData } from '../services/sellTypeAndMCC/getSellTypeAndMCC';
import { GenericApiResponse } from '../types/services';
import { SendOTKResponse } from '../services/otk/sendOTK';
import { GetConfigResponseData } from '../services/config/getConfig';

type responseMap = {
  [QueryKeysEnum.hosted]: GenericApiResponse<GetHostedAppResponseData>;
  [QueryKeysEnum.application]: GenericApiResponse<IApplication>;
  [QueryKeysEnum.config]: GetConfigResponseData;
  [QueryKeysEnum.appTypes]: GenericApiResponse<GetApplicationTypesResponseData>;
  [QueryKeysEnum.countryAuth]: GenericApiResponse<string>;
  [QueryKeysEnum.otk]: GenericApiResponse<SendOTKResponse>;
  [QueryKeysEnum.otp]: GenericApiResponse<GetOTPResponseData>;
  [QueryKeysEnum.sellTypeMCC]: GenericApiResponse<GetSellTypeAndMCCResponseData>;
};

type response<T extends QueryKeysEnum> = T extends keyof responseMap
  ? responseMap[T]
  : { data: any; status: any };

/**
 * Observe a query data without fetching it.
 * @param queryKey - The query key to observe
 * @param additionalOptions - Additional options to pass to the useQuery hook
 * @returns The query data
 */
export default function useObserveQuery<T extends QueryKeysEnum>(
  queryKey: T,
  additionalOptions?: any,
) {
  return useQuery<response<T>>(queryKey, { enabled: false, ...additionalOptions });
}
