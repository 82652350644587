import { useMutation } from 'react-query';

import { api } from '../../lib/api';
import { MutationKeysEnum } from '../../data/enums';
import { GenericApiResponse } from '../../types/services';
import { UploadDocumentsResponseData } from './uploadDocuments';
import { IApplication } from '../../types/application';

interface SaveApplicationWithParallelFileUploadsProps {
  applicationToken: string;
  sectionToken: string;
  fieldToken: string;
  files: UploadDocumentsResponseData[];
}

async function saveApplicationWithParallelFileUploads({
  applicationToken,
  fieldToken,
  files,
  sectionToken,
}: SaveApplicationWithParallelFileUploadsProps): Promise<GenericApiResponse<IApplication>> {
  const response = await api.post<GenericApiResponse<IApplication>>(
    `verify/applications/documents_parallel/${applicationToken}/${sectionToken}/${fieldToken}`,
    {
      files,
    },
  );

  return response.data;
}

/**
 * Saves the application with the parallel file uploads
 * @param applicationToken
 * @param fieldToken
 * @param files
 * @param sectionToken
 * @returns IApplication
 */
export const useSaveApplicationWithParallelFileUploads = () => {
  return useMutation(saveApplicationWithParallelFileUploads, {
    mutationKey: MutationKeysEnum.saveApplicationWithParallelFileUploads,
  });
};
