import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IconName } from '@rapyd/react-ui-library/dist/components/Icon';
import { FALLBACK_LANGUAGE, SUPPORTED_LANGUAGES_MAPPING } from '../data/constants';
import { useOnClickOutside } from '../hooks';
import { getLangObjectFromCode, useChangeLanguage } from '../lib/i18n';
import { RootState } from '../store/store';
import { cn, getLocalStorageItem } from '../utils/helpers';
import { Icon } from './core';

interface LanguageButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  country?: string;
  className?: string;
}

const LanguageButton: React.FC<LanguageButtonProps> = ({ className, country }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const isPreventSaving = useSelector((state: RootState) => state.global.isPreventSaving);
  const [currentLang, setCurrentLang] = useState(
    getLangObjectFromCode(getLocalStorageItem('language') || FALLBACK_LANGUAGE.code!),
  );
  const { changeLanguage, isError } = useChangeLanguage();

  useOnClickOutside(menuRef, () => setIsOpen(false));

  const handleChangeLanguage = async (language: string) => {
    try {
      await changeLanguage(language);
      setIsOpen(false);
      if (isError) return;
      setCurrentLang(getLangObjectFromCode(language));
    } catch (error) {
      setIsOpen(false);
    }
  };

  if (!country || !SUPPORTED_LANGUAGES_MAPPING[country]) {
    return null;
  }

  return (
    <div ref={menuRef}>
      <button
        data-testid="language_button"
        disabled={isPreventSaving}
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          'group flex h-10 w-32 items-center justify-center gap-x-0.5 rounded-lg transition disabled:pointer-events-none disabled:text-gray-500',
          className,
        )}
      >
        <Icon
          name={IconName.WORLD}
          className="m mb-px ml-8 mr-px text-2xl group-hover:text-primary-hover group-disabled:text-gray-400"
        />
        <p className="font-normal group-hover:text-primary-hover">{currentLang.lang}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={`h-4 w-4 flex-grow group-hover:stroke-primary-hover ${isOpen ? 'rotate-180' : ''}`}
          style={{ transition: 'transform 0.3s' }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            data-testid="language_dropdown"
            initial={{ opacity: 0, translateY: -8 }}
            animate={{ opacity: 1, translateY: 15 }}
            exit={{ opacity: 0, translateY: -8 }}
            transition={{ duration: 0.2 }}
            className="fixed flex w-40 min-w-max flex-col gap-y-2 rounded-lg border bg-white px-2 py-2 shadow-lg"
          >
            {SUPPORTED_LANGUAGES_MAPPING[country].map((lang, i) => {
              return (
                <button
                  data-testid={`language_dropdown_item_${lang.lang}`}
                  key={lang.lang}
                  onClick={() => {
                    handleChangeLanguage(lang.code!);
                  }}
                  className="rounded-md px-2 py-3 text-start text-sm font-light transition hover:bg-violet-50 active:bg-violet-100"
                >
                  {lang.lang}
                </button>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LanguageButton;
