import { create } from 'zustand';

interface useApplicationNotCompletedModalStore {
  isOpen: boolean;
  onOpen: (approveCallback: () => void) => void;
  onClose: () => void;
  approveCallback: () => void;
}

export const useApplicationNotCompletedModal = create<useApplicationNotCompletedModalStore>(
  set => ({
    isOpen: false,
    onOpen: approveCallback => set({ isOpen: true, approveCallback }),
    onClose: () => set({ isOpen: false }),
    approveCallback: () => {},
  }),
);
