import { useHistory } from 'react-router';
import { useQuery } from 'react-query';

import { api } from '../../lib/api';
import { QueryKeysEnum, RoutePaths, SessionStatusEnum } from '../../data/enums';
import { usePathParams } from '../../hooks';
import { GetHostedAppResponseData, RenewResult } from '../hostedApplication/getHostedApplication';
import { GenericApiResponse } from '../../types/services';

interface GetOTPParams {
  happToken: string;
}

export interface GetOTPResponseData {
  session_response: {
    session_token: string;
    session_status: SessionStatusEnum;
    round_number: number;
    attempt_number: number;
    expiration_date: number;
  };
  hosted_app: GetHostedAppResponseData | null;
  renew_result: RenewResult | null;
}

async function getOTP({
  happToken,
}: GetOTPParams): Promise<GenericApiResponse<GetOTPResponseData>> {
  const response = await api.get<GenericApiResponse<GetOTPResponseData>>(
    `verify/applications/hosted/otp/${happToken}`,
  );
  return response.data;
}

export const useGetOTP = (happToken: string, enabled: boolean = true) => {
  const history = useHistory();
  return useQuery(
    QueryKeysEnum.otp,
    () => {
      if (!happToken) return;
      return getOTP({ happToken });
    },
    {
      enabled: !!happToken && enabled,
      onSuccess: data => {
        if (data && data?.data?.session_response?.session_status === SessionStatusEnum.PENDING) {
          history.push(`${RoutePaths.OTP}?token=${happToken}`);
        }
      },
    },
  );
};

export const useGetOTPByContext = () => {
  const { happToken } = usePathParams();
  return {
    ...useGetOTP(happToken!),
    happToken,
  };
};
